import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import thunk from 'redux-thunk';

import {
    FLUSH, PAUSE,
    PERSIST, persistReducer, persistStore, PURGE,
    REGISTER, REHYDRATE
} from 'redux-persist';

import appdataReducer from './reducers/appdata';
import appstateReducer from './reducers/appstate';
import formdataReducer from './reducers/formdata';
import userReducer from './reducers/user';

const persistConfig = {
    key: 'root',
    storage: storage
};
const rootReducer = combineReducers({
    appstate: persistReducer({ key: 'appstate', storage: storage, }, appstateReducer),
    appdata: persistReducer({ key: 'appdata', storage: storage, }, appdataReducer),
    formdata: persistReducer({ key: 'formdata', storage: storage, }, formdataReducer),
    user: persistReducer({ key: 'user', storage: storage, }, userReducer),
})


const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: [thunk],
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof rootReducer>
