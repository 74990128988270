import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';


export function generateTableToPDF(domSelector, fileName) {
  let table = document.querySelector(domSelector);
  let SPAN_SIZE = 60;
  // let width = table.clientWidth + SPAN_SIZE * 2;
  let width = 1920;
  let height = width * 0.7;

  console.log('W_H', width, height);
  // return;


  let doc = new jsPDF({
    orientation: "landscape",
    unit: "px",
    format: [ width, height ],
  })

  doc.autoTable({
    html: domSelector,
    startY: 90,
    margin: {
      top: 90,
      bottom: 80
    },
    // horizontalPageBreak: true,
    // horizontalPageBreakRepeat: 0,
    styles: {
      fontSize: 15,
      cellWidth: 'auto',
    },
    headStyles: {
      cellWidth: 'auto',
    },
    columnStyles: {
      0: {cellWidth: 'auto'}
    },
    didDrawPage: function(data) {
      doc.setFontSize(40);
      doc.text(fileName, SPAN_SIZE, SPAN_SIZE);
      doc.setFontSize(25);
      doc.setTextColor('gray');
      doc.text(`${ (new Date()).toLocaleString() }`, SPAN_SIZE, height - SPAN_SIZE);
    }
  });
  doc.save(`${fileName}_${(new Date()).toLocaleDateString()}.pdf`);
}