import { Card, Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from "yup";
import axios from "axios";
import toast from "react-hot-toast";

import {
  showLoadingIndicator, hideLoadingIndicator
} from 'redux/reducers/appstate'

export default function Profile () {

  const dispatch = useDispatch();

  const formSchema = Yup.object().shape({
    password: Yup.string()
      .required('Password is required')
      .min(4, 'Password length should be at least 6 characters'),
    passwordConfirm: Yup.string()
      .required('Confirm Password is required')
      .oneOf([Yup.ref('password')], 'Passwords must and should match'),
  })
  
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchema)
  });

  const onPasswordChange = (data) => {
    dispatch(showLoadingIndicator());
    axios.post('/api/auth/update-password', data)
    .then(() => {
      toast.success('Successfully updated password.')
    })
    .catch(() => {
      toast.error('Failure for update password')
    })
    .finally(() => {
      dispatch(hideLoadingIndicator());
    })
  }

  return (
    <div>
      <h3>Profile</h3>
      
      <Card>
        <Card.Title>Update Password</Card.Title>
        <Card.Body>
          <Form onSubmit={handleSubmit(onPasswordChange)}>
            <Form.Group className="mb-3" controlId="emailGroup">
              <Form.Label>New Password</Form.Label>
              <Form.Control type="password" placeholder="Please input new  password" {...register('password', { required: true }) } />
              <p className="form-field-error">{ errors.password?.message }</p>
            </Form.Group>
            <Form.Group className="mb-3" controlId="emailGroup">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control type="password" placeholder="Please input new  password" {...register('passwordConfirm', { required: true }) } />
              <p className="form-field-error">{ errors.passwordConfirm?.message }</p>
            </Form.Group>
            <Button variant="primary" type="submit">Update Password</Button>
          </Form>
        </Card.Body>
      </Card>


    </div>
  )

}