import React, { useState, useEffect } from 'react';
import { gapi, loadAuth2 } from 'gapi-script'
import { Alert, Box, Button, Grid, Typography } from '@mui/material';
import { GOOGLE_OAUTH_SCOPE } from 'utils/search-console-helper';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { GoogleButton } from './GoogleButton';
import { RootState } from 'redux/store';
import { useSelector } from 'react-redux'
import { EventEmitter } from 'utils/Event';

type GoogleLoginButtonPropType = {
}
export const GoogleLogin = (props: GoogleLoginButtonPropType) => {
    const [user, setUser] = useState(null);


    useEffect(() => {
        EventEmitter.subscribe('GOOGLE_USER_UPDATE', (googleUser) => {
            if (googleUser?.Ca) {
                const name = googleUser.getBasicProfile().getName();
                const profileImg = googleUser.getBasicProfile().getImageUrl();
                setUser({
                    name: name,
                    profileImg: profileImg,
                });
            } else {
                setUser(null)
            }
        })

    }, [])

    return (
        <Grid container>
            {user && <Grid item xs={12} md={6} >
                <Box flexDirection='row' display='flex' alignItems='center' p={1} justifyContent='flex-start'>
                    <CheckCircleOutlineIcon sx={{ color: '#12814C', fontSize: '30px' }} />
                    <Box pl={1} pr={2}>
                        <Typography color='#12814C' fontSize={12}>Connected Account:</Typography>
                        <Typography color='primary.dark' fontSize={18}>{user?.name}</Typography>
                    </Box>
                </Box>
            </Grid>}
            {!user && <Grid item xs={12} md={6} >
                <Box flexDirection='row' display='flex' alignItems='center' p={1} justifyContent='flex-start'>
                    <CancelOutlinedIcon sx={{ color: '#FF5B00' }} />
                    <Box pl={1} pr={2}>
                        <Typography color='primary.dark' fontSize={12}>Connected Account:</Typography>
                        <Typography color='primary.dark' fontSize={18}>No Account Selected</Typography>
                    </Box>
                </Box>
            </Grid>}
            <Grid item xs={12} md={6} alignItems='center' display='flex'>
                <GoogleButton />
            </Grid>
        </Grid>
    );

}