import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  IconButton,
  Box,
  TextField,
  Grid,
  InputAdornment,
  ButtonGroup,
  ListItemText,
  ListItem,
  List,
  Divider,
  Popover,
  Button,
} from "@mui/material";
import { green, orange, red } from "@mui/material/colors";
import Pagination from "@mui/material/Pagination";
import { useEffect, useMemo, useState } from "react";
import { slice, filter, includes } from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import BarIcon from "assets/images/bar-icon.svg";
import DownloadIcon from "assets/images/download-icon.svg";
import ArrowDownIcon from "assets/images/arrow-down-icon.svg";

const getScoreColorClass = (score) => {
  if (score > 50) return green[500];
  if (score > 20) return orange[500];
  return red[500];
};

const TableRowItem = ({ index, row, competitors }) => (
  <TableRow sx={{ backgroundColor: index % 2 === 0 ? "#F7F5FB" : "white" }}>
    <TableCell padding="checkbox">
      <Checkbox />
    </TableCell>
    <TableCell
      sx={{ fontSize: "16px", fontWeight: 700, color: "#014751" }}
      component="th"
      scope="row"
    >
      {row.keyword}
    </TableCell>
    {competitors.map((competitor) => {
      const siteData = row.positions?.find((data) => data.site === competitor);
      return (
        <TableCell
          sx={{ fontSize: "16px", fontWeight: 700, color: "#014751" }}
          align="center"
        >
          {siteData?.rank || "-"}
        </TableCell>
      );
    })}
    <TableCell
      sx={{ fontSize: "16px", fontWeight: 700, color: "#014751" }}
      align="center"
    >
      {row.keyword_difficulties}
      <IconButton
        size="small"
        sx={{
          width: "13px",
          height: "13px",
          backgroundColor: getScoreColorClass(row.score),
          ml: 1,
        }}
      ></IconButton>
    </TableCell>
    <TableCell
      sx={{ fontSize: "16px", fontWeight: 700, color: "#014751" }}
      align="center"
    >
      {row.monthly_searches}
    </TableCell>
  </TableRow>
);

const ResultTable = ({ tab, competitors, rows }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const [page, setPage] = useState<any>(1);
  const [searchVal, setSearchVal] = useState("");

  useEffect(() => {
    setPage(1);
  }, [tab]);

  const limitedData = useMemo(
    () =>
      searchVal
        ? filter(rows, (row) => includes(row.keyword, searchVal))
        : rows,
    [tab, searchVal]
  );

  const pageRows = useMemo(
    () => slice(limitedData, (page - 1) * 100, page * 100),
    [tab, page]
  );
  const totalCount = Math.ceil(limitedData.length / 100);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="customized table">
        <TableHead sx={{ backgroundColor: "#F7F5FB" }}>
          <TableRow>
            <TableCell colSpan={100}>
              <Grid container xs={12}>
                <Grid item xs={2}>
                  <Box sx={{ backgroundColor: "white" }}>
                    <TextField
                      value={searchVal || ""}
                      variant="outlined"
                      placeholder="Search"
                      onChange={(e) => {
                        setSearchVal(e.target.value);
                        setPage(1);
                      }}
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IconButton>
                              <SearchIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item container xs={10} justifyContent="flex-end">
                  <Box sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                    <Button
                      variant="outlined"
                      sx={{
                        textTransform: "none",
                        mr: 1,
                        borderColor: "#014751",
                        color: "#014751",
                        fontWeight: 700,
                      }}
                      startIcon={<img src={BarIcon} alt="Bar Icon" />}
                    >
                      Show Search Trend
                    </Button>
                    <ButtonGroup
                      variant="outlined"
                      aria-label="outlined primary button group"
                      sx={{ borderColor: "#014751", color: "#014751" }}
                    >
                      <Button
                        sx={{
                          textTransform: "none",
                          borderColor: "#014751",
                          color: "#014751",
                        }}
                        onClick={handleClick}
                        aria-describedby={id}
                      >
                        FILTERS
                      </Button>
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <Box sx={{ p: 2 }}>
                          <List component="nav" aria-label="Filter options">
                            <ListItem>
                              <ListItemText
                                primary="Number of words"
                                sx={{
                                  fontSize: "13px",
                                  fontWeight: 800,
                                  color: "#014751",
                                }}
                              />
                              <img src={ArrowDownIcon} alt="Arrow Down" />
                            </ListItem>
                            <Divider />
                            <ListItem>
                              <ListItemText
                                primary="Clicks"
                                sx={{
                                  fontSize: "13px",
                                  fontWeight: 800,
                                  color: "#014751",
                                }}
                              />
                              <img src={ArrowDownIcon} alt="Arrow Down" />
                            </ListItem>
                            <Divider />
                            <ListItem>
                              <ListItemText
                                primary="Impressions"
                                sx={{
                                  fontSize: "13px",
                                  fontWeight: 800,
                                  color: "#014751",
                                }}
                              />
                              <img src={ArrowDownIcon} alt="Arrow Down" />
                            </ListItem>
                            <Divider />
                            <ListItem>
                              <ListItemText
                                primary="Monthly searches"
                                sx={{
                                  fontSize: "13px",
                                  fontWeight: 800,
                                  color: "#014751",
                                }}
                              />
                              <img src={ArrowDownIcon} alt="Arrow Down" />
                            </ListItem>
                            <Divider />
                            <ListItem>
                              <ListItemText
                                primary="Competitors organic position"
                                sx={{
                                  fontSize: "13px",
                                  fontWeight: 800,
                                  color: "#014751",
                                }}
                              />
                              <img src={ArrowDownIcon} alt="Arrow Down" />
                            </ListItem>
                            <Divider />
                            <ListItem>
                              <ListItemText
                                primary="Include keywords"
                                sx={{
                                  fontSize: "13px",
                                  fontWeight: 800,
                                  color: "#014751",
                                }}
                              />
                              <img src={ArrowDownIcon} alt="Arrow Down" />
                            </ListItem>
                            <Divider />
                            <ListItem>
                              <ListItemText
                                primary="Exclude keywords"
                                sx={{
                                  fontSize: "13px",
                                  fontWeight: 800,
                                  color: "#014751",
                                }}
                              />
                              <img src={ArrowDownIcon} alt="Arrow Down" />
                            </ListItem>
                            <Divider />
                            <ListItem>
                              <ListItemText
                                primary="Number of words"
                                sx={{
                                  fontSize: "13px",
                                  fontWeight: 800,
                                  color: "#014751",
                                }}
                              />
                              <img src={ArrowDownIcon} alt="Arrow Down" />
                            </ListItem>
                          </List>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              mt: 2,
                            }}
                          >
                            <Button
                              variant="contained"
                              onClick={handleClose}
                              sx={{
                                height: "38px",
                                width: "126px",
                                borderRadius: "6px",
                                backgroundColor: "#FF5700",
                                mr: 1,
                                fontWeight: 700,
                              }}
                            >
                              Apply
                            </Button>
                            <Button
                              variant="outlined"
                              onClick={handleClose}
                              sx={{
                                border: "1px solid #014751",
                                color: "#014751",
                                fontWeight: 700,
                              }}
                            >
                              Reset All
                            </Button>
                          </Box>
                        </Box>
                      </Popover>
                      <Button
                        sx={{
                          textTransform: "none",
                          borderColor: "#014751",
                          color: "#014751",
                          fontWeight: 700,
                        }}
                      >
                        0 filters applied
                      </Button>
                    </ButtonGroup>
                    <Button
                      startIcon={<img src={DownloadIcon} alt="Download" />}
                      variant="outlined"
                      sx={{
                        textTransform: "none",
                        ml: 1,
                        borderColor: "#014751",
                        color: "#014751",
                        fontWeight: 700,
                      }}
                      endIcon={<img src={ArrowDownIcon} alt="Arow Down" />}
                    >
                      Download
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </TableHead>

        <TableHead>
          <TableRow>
            <TableCell
              sx={{ fontSize: "14px", fontWeight: 700, color: "#014751" }}
            >
              Track
            </TableCell>
            <TableCell
              sx={{ fontSize: "14px", fontWeight: 700, color: "#014751" }}
            >
              Keyword
            </TableCell>
            <TableCell
              align="center"
              colSpan={competitors.length}
              sx={{ fontSize: "14px", fontWeight: 700, color: "#014751" }}
            >
              Organic Position
            </TableCell>
            <TableCell
              sx={{ fontSize: "14px", fontWeight: 700, color: "#014751" }}
            >
              Keyword Difficulty
            </TableCell>
            <TableCell
              sx={{ fontSize: "14px", fontWeight: 700, color: "#014751" }}
            >
              Monthly Searches
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell padding="checkbox" />
            <TableCell />
            {competitors.map((competitor) => renderHeaderCell(competitor))}
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {pageRows.map((row, index) => (
            <TableRowItem
              key={index}
              index={index}
              row={row}
              competitors={competitors}
            />
          ))}
        </TableBody>
      </Table>
      <Pagination
        onChange={(_, val) => setPage(val)}
        sx={{ mb: 2, justifyContent: "center", display: "flex" }}
        count={totalCount}
        color="secondary"
      />
    </TableContainer>
  );
};

const renderHeaderCell = (label) => (
  <TableCell align="center">
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        fontSize: "14px",
        fontWeight: 700,
        color: "#014751",
      }}
    >
      {label}
    </Box>
  </TableCell>
);

export default ResultTable;
