import { useEffect, useState } from "react";
import { Button, Box } from "@mui/material";
import { EventEmitter } from "utils/Event";
import { updateGoogleUser } from "redux/reducers/user";
import { GOOGLE_OAUTH_SCOPE } from "utils/search-console-helper";
import { gapi, loadAuth2 } from "gapi-script";
import { useDispatch } from "react-redux";

const GoogleButton = () => {
  const dispatch = useDispatch();
  const [googleUser, setGoogleUser] = useState(null);

  useEffect(() => {
    const setAuth2 = async () => {
      const auth2 = await loadAuth2(
        gapi,
        process.env.REACT_APP_GOOGLE_CLIENT_ID,
        GOOGLE_OAUTH_SCOPE
      );
      if (auth2.isSignedIn.get()) {
        updateUser(auth2.currentUser.get());
      }
      attachSignin(document.getElementsByClassName("googleLoginButton"), auth2);
    };
    setAuth2();
  }, []);

  const updateUser = (currentUser) => {
    setGoogleUser(currentUser);
    dispatch(
      updateGoogleUser({
        name: currentUser.getBasicProfile().getName(),
        profileImg: currentUser.getBasicProfile().getImageUrl(),
        ca: currentUser.Ca,
      })
    );
    EventEmitter.dispatch("GOOGLE_USER_UPDATE", currentUser);
  };

  const attachSignin = (elements, auth2) => {
    for (const key in elements) {
      auth2.attachClickHandler(
        elements[key],
        {},
        (gu) => {
          updateUser(gu);
        },
        (error) => {
          console.log(JSON.stringify(error));
        }
      );
    }
  };

  const signOut = () => {
    setGoogleUser(null);
    const auth2 = gapi.auth2.getAuthInstance();
    dispatch(updateGoogleUser(null));

    auth2.signOut().then(() => {
      console.log("User signed out.");
    });
    EventEmitter.dispatch("GOOGLE_USER_UPDATE", null);
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
      <Button
        variant="contained"
        className="googleLoginButton"
        sx={{
          backgroundColor: "#FF5700",
          height: "38px",
          borderRadius: "85px",
          color: "white",
          fontSize: "14px",
          fontWeight: 700,
          display: googleUser ? "block" : "none",
        }}
      >
        Change Account
      </Button>
      <Button
        variant="contained"
        onClick={signOut}
        sx={{
          backgroundColor: "secondary",
          height: "38px",
          borderRadius: "85px",
          color: "white",
          ml: 1,
          fontSize: "14px",
          fontWeight: 700,
          display: googleUser ? "block" : "none",
        }}
      >
        Disconnect
      </Button>
      <Button
        variant="contained"
        className="googleLoginButton"
        sx={{
          backgroundColor: "#FF5700",
          height: "38px",
          borderRadius: "85px",
          color: "white",
          fontSize: "14px",
          fontWeight: 700,
          display: !googleUser ? "block" : "none",
        }}
      >
        Connect
      </Button>
    </Box>
  );
};

export default GoogleButton;
