import { useEffect, useMemo, useState } from "react"
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { EventEmitter } from "utils/Event";
import { getSitesList, GOOGLE_OAUTH_SCOPE, loadGAPIClient, searchAnalyticsForSearchConsole } from "utils/search-console-helper";
import { checkDomainMatch } from "utils";
import { RootState } from "redux/store";
import { useSelector } from 'react-redux'
import { gapi, loadAuth2 } from 'gapi-script'

export default function ({
    value,
    base_domain,
    onChangeDomain
}: {
    value: string,
    base_domain: string,
    onChangeDomain: (value: string) => void
}) {
    const [isLoadingSites, setIsLoadingSites] = useState(false)
    const [sites, setSites] = useState([])
    
    const googleUser_ = useSelector((state: RootState) => state.user.googleUser)
    const [googleUserCa, setGoogleUserCa] = useState(googleUser_?.Ca)

    useEffect(() => {
        const setAuth2 = async () => {
            const auth2 = await loadAuth2(gapi, process.env.REACT_APP_GOOGLE_CLIENT_ID, GOOGLE_OAUTH_SCOPE)
            if (auth2.isSignedIn.get()) {
                const gu = auth2.currentUser.get()
                setGoogleUserCa(gu.Ca)
            }
        }
        setAuth2();
    }, []);

    useEffect(() => {
        EventEmitter.subscribe('GOOGLE_USER_UPDATE', (gu) => {
            setGoogleUserCa(gu?.Ca)
        })
    }, [])
    const userDomains = useMemo(() => {
        const results = []
        if (sites) {

            sites.map((site: string) => {
                try {
                    const site$ = site.replace('sc-domain:', '')
                    const isValid = checkDomainMatch(site$, base_domain)
                    results.push({
                        url: site,
                        valid: isValid
                    })
                } catch (e) {

                    results.push({
                        url: site,
                        valid: false
                    })
                }

            })
            return results
        } else {
            return []
        }
    }, [sites, base_domain])
    useEffect(() => {
        if (googleUserCa) {
            setIsLoadingSites(true)
            loadGAPIClient().then(() => {
                setTimeout(async () => {
                    const res = await getSitesList()
                    if (res && res.status == 200) {
                        setSites((res.result.siteEntry || []).map(s => s.siteUrl))
                        setIsLoadingSites(false)
                    }
                }, 3000);
            }).catch((e) => {
                console.info('=== loadGAPIClient Error ===', JSON.stringify(e, null, 4))
                setIsLoadingSites(false)
            });

        }
    }, [googleUserCa])
    return <Box>
        <FormControl size="small" fullWidth>
            <InputLabel >Domain {isLoadingSites ? '(loading...)' : ''}</InputLabel>
            <Select
                disabled={sites.length < 1}
                labelId="demo-simple-select-label"
                value={value}
                label="Domain"
                onChange={(e) => onChangeDomain(e.target.value)}
            >
                {
                    userDomains.map((site, key) => {
                        return <MenuItem value={site.url} key={key} disabled={!site.valid}>{site.url}</MenuItem>
                    })
                }
            </Select>
        </FormControl>
    </Box>

}