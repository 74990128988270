import Axios from "axios"
import { useSelector } from "react-redux"
import {store, persistor, RootState} from 'redux/store';

export function useIsAuthenticated() {
  const user = useSelector((state: RootState) => state.user)
  const token = user.token
  if (!token || token.split('.').length < 3) {
    return false
  }
  const data = JSON.parse(atob(token.split('.')[1]))
  const exp = new Date(data.exp * 1000)
  const now = new Date()
  return now < exp
}

export const downloadCSV = (
  data: Array<any>,
  filename: string,
  contentType: string = "text/csv;charset=utf-8;"
) => {
  const blob = new Blob([jsonToCsv(data)], { type: contentType })
  const link = document.createElement("a")

  link.href = URL.createObjectURL(blob)
  link.setAttribute("download", filename)
  link.click()
}

export const jsonToCsv = (data: Array<any>) => {
  return [Object.keys(data[0]).map((str: string) => str.toUpperCase())]
    .concat(
      data.map((row: Object) =>
        Object.values(row)
          .map((v: any) => String(v).replaceAll('"', '""'))
          .map((v: string) => `"${v}"`)
          .join(",")
      )
    )
    .join("\r\n")
}

export const CallApi = (params, options = { showError: true }): Promise<any> => {
  const state = store.getState()
  const user = state.user
  let newparams = params
  const header = "Bearer " + state.user.token;
  newparams.headers = { Authorization: header }
  return new Promise((resolve, reject) => {
    Axios(newparams).then((res) => {
      resolve(res.data)
    }).catch((error) => {
      console.info('=== error endpoint ===', JSON.stringify(params, null, 4))
      reject(error)
    })
  })

}

export const getDomain = (url: string) => {
  let $url: string = url
  if (!$url.includes('http')) {
    $url = 'http://' + $url
  }
  try {
    let urlObj = (new URL($url))
    return urlObj.hostname
  } catch (e) {
    return ''
  }
}

export const checkDomainMatch = (url1: string, url2: string) => {
  const domain1 = getDomain(url1)
  const domain2 = getDomain(url2)
  if (domain1 && domain2) {
    if (domain1.includes(domain2) || domain2.includes(domain1)) // to check subdomain
    {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}
export const getHttpsUrl = (url: string) => {
  if (!url.startsWith('http')) {
    return 'https://' + url
  } else {
    return url
  }
}