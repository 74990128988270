import React, { useState, useCallback, useEffect, useRef, useImperativeHandle, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
// import AsyncSelect from "react-select/async";
import ReactSelect from "react-select";
import { Alert } from "react-bootstrap";
import axios from "axios";

import ProgressBar from 'components/ProgressBar/ProgressBar'

import toast from "react-hot-toast";

import _ from "lodash";

import KeywordsResearchResult from "views/Pages/Campaign/tabs/KeywordsResearch/KeywordsResearchResult";
import { useParams } from "react-router-dom";
import { checkDomainMatch } from "utils";
import { Box, Button, Container, Grid, TextField, Typography } from "@mui/material";
import CountrySelect from "components/CountrySelect";
import LanguageSelect from "components/LanguageSelect";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import langs from 'utils/languages.json'
import { getSplittedKeywords, getValidKeywords } from "utils/KeywordResearch";
import ShadowBox from "components/ShadowBox";
import {RootState} from "../../../../redux/store";

let intervalTaskId = null;
const TIME_INTERVAL = 3000
type PropTypes = {
  isActive: boolean
}
export default function ({ isActive }: PropTypes) {
  const dispatch = useDispatch();
  const { siteId, campaignId } = useParams()
  const formdata = useSelector((state: RootState) => state.formdata)
  const siteData = formdata.site

  const [research_id, setResearch_id] = useState(0)
  const [researchHistory, setResearchHistory] = useState([])

  const [location, setLocation] = useState(siteData?.location_code);
  const [keywords, setKeywords] = useState('');
  const [domain, setDomain] = useState("");

  const [language, setLanguage] = useState(null);

  const [taskId, setTaskId] = useState("");

  const [currentReport, setCurrentReport] = useState(null);

  const progressObjRef = useRef(null);
  const [progressObj, setProgressObj] = useState(null);
  const [calculating, setCalculating] = useState(false);

  useEffect(() => {
    if (campaignId) {
      loadData()
      updateLanguageLocation(null)
    }
  }, [campaignId, research_id])

  const loadData = () => {
    clear();
    loadResearchResult()
    loadResearchHistoryForCampaign()
  }
  useEffect(() => {
    if (!taskId) {
      return;
    }
    createTaskMonitor();
  }, [taskId]);
  const loadResearchHistoryForCampaign = () => {
    axios.get(`/api/keyword_research/history/campaign/${campaignId}`).then((res) => {
      setResearchHistory(res.data)
    })
  }
  const updateLanguageLocation = (report) => {
    if (siteData?.default_loc_lang_enabled) {
      setLocation(report?.location_code || siteData.location_code)
      const lang = langs.find(l => l.language_code == (report?.languageCode || siteData.language_code))
      if (lang) {
        setLanguage(lang)
      }
      setDomain(report?.domain || siteData?.base_url)

    } else {
      setDomain(report?.domain || siteData?.base_url)
      setLocation(report?.location_code)
      const lang = langs.find(l => l.language_code == report?.languageCode)
      if (lang) {
        setLanguage(lang)
      }
    }
  }
  const loadResearchResult = () => {
    axios
      .get(`/api/keyword_research/get_keyword_result/${campaignId}/${research_id}`)
      .then((response) => {
        if (response && response.status === 200) {
          let report = response.data?.report;
          setKeywords(report.keywords ? report.keywords.join('\n') : '');

          updateLanguageLocation(report)
          setCurrentReport(report)
        } else {
          updateLanguageLocation(null)
        }
        clearRankingTaskInterval();
      })
      .catch((error) => {
        console.log('failed to load', error)
      });
  }
  function clearRankingTaskInterval() {
    if (intervalTaskId) {
      clearInterval(intervalTaskId);
      intervalTaskId = null;
    }
  }

  function clear() {
    console.log('clearing task interval because task is finished with success/failure', intervalTaskId)
    if (intervalTaskId) {
      clearInterval(intervalTaskId);
      intervalTaskId = null;
    }
    setTaskId("");
    setCalculating(false);
    setProgressObj(null);
    progressObjRef.current = {};

  }
  const createTaskMonitor = () => {
    console.log('creating task monitor to get update')
    const splittedKeywords = getSplittedKeywords(keywords);

    intervalTaskId = setInterval(() => {
      axios.get(`/api/get-task-status/${taskId}`).then((response) => {

        if (response && response.status === 200) {
          if (response.data?.status === "PROGRESS") {
            let value = (progressObjRef.current?.value || 0) + 100 / (splittedKeywords.length * 10);
            if (value < response.data.value) value = response.data.value;
            if (value > 100) value = 100;
            let newVal = {
              value: value,
              msg: `${response.data.msg} While you wait, you can leave this screen.` // response.data.msg
            };
            progressObjRef.current = newVal;
            setProgressObj(newVal);
          } else if (response.data?.status === "SUCCESS") {
            loadData()
            toast.success("Completed keyword research!!");
          } else if (response.data?.status === "FAILURE") {
            toast.error("Failed!!");
            clear();
          }
        } else {
          clear();
        }
      });
      return () => {
        clear();
      };
    }, TIME_INTERVAL);
  }



  function handleSearchKeywords() {
    if (siteId && !checkDomainMatch(domain, siteData.base_url)) {
      toast.error("Domain not matched")
      return
    }
    // if (
    //   !user?.is_admin &&
    //   user?.dailyKeywordLimit <= user?.used_keywords_count_today
    // ) {
    //   toast.error("You exceed the today's usage.");
    //   return;
    // }
    const splittedKeywords = getSplittedKeywords(keywords);
    // let remainLimit =
    //   user?.dailyKeywordLimit - user?.used_keywords_count_today || 0;
    // if (!user.is_admin && splittedKeywords.length > remainLimit) {
    //   toast.error(
    //     "You have inputted too many keywords than your remained limit"
    //   );
    //   return;
    // }
    const validKeywords = getValidKeywords(splittedKeywords)

    console.info('=== validKeywords ===', JSON.stringify(validKeywords, null, 4))
    setProgressObj({ value: 0, msg: 'Calculating... While you wait, you can leave this screen.' })
    setCalculating(true);

    axios
      .post("/api/keyword_research/research", {
        campaignId: campaignId,
        keywords: validKeywords,
        location_code: location,
        language_code: language.language_code,
        domain: domain,
      })
      .then((response) => {
        if (response && response.status === 200) {
          if (response.data?.taskId) {
            setTaskId(response.data?.taskId);
          } else {
            setCalculating(false);
          }
        } else {
          setCalculating(false);
          toast.error(response.data.msg)
        }
      });
  }

  const canStartAnalysis = () => {
    if (!location) {
      return false;
    }
    if (!language) {
      return false;
    }
    if (!keywords.trim()) {
      return false;
    }
    if (calculating) {
      return false;
    }
    return true;
  };

  const handlePauseTask = () => {
    axios.get('/api/revoke_task/' + taskId).then((res) => {
      clear();
    })
  }
  const onDeleteAllKeywordData = () => {
    if (currentReport) {
      axios
        .delete(`/api/keyword_research/delete_all_keywords_data/${campaignId}`)
        .then((res) => {
          loadResearchResult()
        })
        .catch((error) => {
          console.log('failed to load', error)
        });
    }
  }
  const onChangeResearchId = (research_id: number) => {
    setResearch_id(research_id)
  }
  return (
    <Box>
      <Container maxWidth="xl">
        <ShadowBox>
          <Typography variant="h5" color='primary.dark'>Get Data for Your Keywords</Typography>
          <Typography>
            The Keyword Insight tool is a comprehensive SEO optimization instrument. Users input specific keywords, select their target geographic location, and specify a language to generate precise data on search volume, competition levels, current rankings, and other critical metrics. By leveraging these insights, users can fine-tune their SEO strategies, ensuring they are both targeted and efficient for their intended audience.
          </Typography>
          <Grid container spacing={2} my={2}>
            <Grid item xs={12} md={4}>
              <CountrySelect
                isMulti={false}
                value={location}
                onChange={(loc) => setLocation(loc)}
                placeholder="SERP Location"
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <LanguageSelect
                value={language}
                onChange={(item) => {
                  setLanguage(item);
                }}
                isMulti={false}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12} md={4}>

              <TextField
                value={domain}
                size='small'
                label="Domain"
                fullWidth={true}
                disabled={true}
                onChange={(e) => {
                  setDomain(e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Box>
            <TextField
              label="Keywords"
              placeholder="Enter Keywords separated by commas or one per line"
              rows={3}
              multiline
              value={keywords}
              onChange={(e) => setKeywords(e.target.value)}
              fullWidth
            ></TextField>
          </Box>
          <Box mt={4} >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={2}>

                <Button
                  size='large'
                  disabled={!canStartAnalysis()}
                  onClick={handleSearchKeywords}
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                  color='secondary'
                  sx={{
                    borderRadius: 10,
                  }}
                >
                  Get Data
                </Button>
              </Grid>
              <Grid item xs={12} md={10}>
                <ProgressBar
                  progressObj={progressObj}
                  onPause={handlePauseTask}
                />
              </Grid>
            </Grid>
          </Box>
        </ShadowBox>
      </Container>
      {currentReport && (
        <KeywordsResearchResult
          onChangeResearchId={onChangeResearchId}
          report={currentReport}
          campaignId={Number(campaignId)}
          researchHistory={researchHistory}
          calculating={calculating}
          id='keyword_research'
          onDeleteAllKeywordData={onDeleteAllKeywordData}
        />
      )}
    </Box>
  );
}
