import axios from "axios";
import FeatherIcon from 'feather-icons-react';
import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import validator from 'validator';

import LocationsData from "utils/locations.json";

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button, Checkbox, Container, FormControl, FormControlLabel, Grid, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Switch, TextField, Typography } from '@mui/material';
import CountrySelect from 'components/CountrySelect';
import LanguageSelect from 'components/LanguageSelect';
import ProgressBar from "components/ProgressBar/ProgressBar";
import ShadowBox from 'components/ShadowBox';
import { useParams } from "react-router-dom";
import { CallApi, checkDomainMatch } from 'utils';
import { getSplittedKeywords, getValidKeywords } from 'utils/KeywordResearch';
import langs from 'utils/languages.json';
import KeywordsResearchResult from 'views/Pages/Campaign/tabs/KeywordsResearch/KeywordsResearchResult';
import {RootState} from "../../../../redux/store";

let intervalTaskId = null;
const TIME_INTERVAL = 3000
const locations = LocationsData.locations

const SupportingKeywordResearchWrapper = styled.div`
  .sticky-section {
    position: sticky;
    top: 56px;
  }
`

const ClusterWrapper = styled.div`
  border: 1px solid #c5c5c5;
  padding: 10px 15px;
  border-radius: 10px;
  margin-bottom: 20px;
  background: white;
  .cluster-header {
    display: flex;
    margin-bottom: 15px;
  }
  .cluster-name {
    font-size: 1.3rem;
    font-weight: 700;
    flex-grow: 1;
  }
`


export const KeywordTypeOptionsFull = [
  "Related Keywords",
  "Keyword Suggestions",
  "Competitor's Keyword",
  "People Also Ask",
  "Google Suggest",
]

export const test_data = [
      {
        "clusterName": "how to do on and off page seo", 
        "keyword": "how to do on and off page seo", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "how to do on and off page seo", 
        "keyword": "how to do on page seo step by step", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "how to do on-page seo", 
        "keyword": "how to do on-page seo", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "how to do on-page seo", 
        "keyword": "what are some best on-page seo techniques", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "is on-page seo more important than off-page", 
        "keyword": "is on-page seo more important than off-page", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "is on-page seo more important than off-page", 
        "keyword": "what is the best seo practice off-page or on page", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "is on-page seo more important than off-page", 
        "keyword": "which is more important on-page or off-page seo", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "no_cluster", 
        "keyword": "facebook page seo", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "no_cluster", 
        "keyword": "how do i start on-page seo as i am beginner to this field", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "no_cluster", 
        "keyword": "how do you use keywords in on page seo", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "no_cluster", 
        "keyword": "how to do an on page seo analysis", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "no_cluster", 
        "keyword": "in site seo", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "no_cluster", 
        "keyword": "on page optimization", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "no_cluster", 
        "keyword": "on page s e o", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "no_cluster", 
        "keyword": "on page seo analyzer", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "no_cluster", 
        "keyword": "on page seo audit tools", 
        "source": "Keyword Suggestions"
      }, 
      {
        "clusterName": "no_cluster", 
        "keyword": "on page seo tools list", 
        "source": "Google Suggest"
      }, 
      {
        "clusterName": "no_cluster", 
        "keyword": "on-page seo moz", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "no_cluster", 
        "keyword": "on-page seo report", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "no_cluster", 
        "keyword": "onpage s e o", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "no_cluster", 
        "keyword": "semrush plugin for wordpress", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "no_cluster", 
        "keyword": "seo manager software", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "no_cluster", 
        "keyword": "seo measurement tool", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "no_cluster", 
        "keyword": "seo on page seo", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "no_cluster", 
        "keyword": "seo on page tools", 
        "source": "Keyword Suggestions"
      }, 
      {
        "clusterName": "no_cluster", 
        "keyword": "seo on-page analysis", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "no_cluster", 
        "keyword": "seo review tools", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "no_cluster", 
        "keyword": "seo suggestions tool", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "no_cluster", 
        "keyword": "seo tools open source", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "no_cluster", 
        "keyword": "web page seo", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "no_cluster", 
        "keyword": "what is an seo platform", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "no_cluster", 
        "keyword": "what is google seo tools", 
        "source": "Google Suggest"
      }, 
      {
        "clusterName": "no_cluster", 
        "keyword": "what is off-page seo tool", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "no_cluster", 
        "keyword": "what is on page seo", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "no_cluster", 
        "keyword": "what is the meaning of on site seo", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "no_cluster", 
        "keyword": "what is the on-page seo answer", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "no_cluster", 
        "keyword": "what off-page seo tools do you use for backlinks", 
        "source": "Google Suggest"
      }, 
      {
        "clusterName": "no_cluster", 
        "keyword": "which is the most important part of the page for seo", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "no_cluster", 
        "keyword": "which of the following is an aspect of on-page seo quiz", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "on page seo", 
        "keyword": "on page seo", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "on page seo", 
        "keyword": "on the page seo", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "on page seo", 
        "keyword": "seo on pages", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "on page seo", 
        "keyword": "what is on on page seo", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "on page seo analysis tools", 
        "keyword": "on page seo analysis tools", 
        "source": "Keyword Suggestions"
      }, 
      {
        "clusterName": "on page seo analysis tools", 
        "keyword": "on page seo tools and techniques", 
        "source": "Google Suggest"
      }, 
      {
        "clusterName": "on page seo tools free", 
        "keyword": "free off page seo tools", 
        "source": "Google Suggest"
      }, 
      {
        "clusterName": "on page seo tools free", 
        "keyword": "on page seo tools free", 
        "source": "Google Suggest"
      }, 
      {
        "clusterName": "on page tool", 
        "keyword": "on page tool", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "on page tool", 
        "keyword": "on page tools", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "on-page in seo", 
        "keyword": "on page in seo", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "on-page in seo", 
        "keyword": "on-page in seo", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "on-page seo checker", 
        "keyword": "on page seo checker tools", 
        "source": "Keyword Suggestions"
      }, 
      {
        "clusterName": "on-page seo checker", 
        "keyword": "on-page seo checker", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "on-page seo checklist", 
        "keyword": "how do i use an on page seo checklist", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "on-page seo checklist", 
        "keyword": "on-page seo checklist", 
        "source": "Related Keywords"
      }, 
      {
        "clusterName": "on-page seo checklist", 
        "keyword": "what is a checklist for on page seo", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "on-page seo examples", 
        "keyword": "on-page seo examples", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "on-page seo examples", 
        "keyword": "what are examples of off-page seo", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "on-page seo examples", 
        "keyword": "what is an example of on page seo", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "on-page seo examples", 
        "keyword": "what is an example of on site seo", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "on-page seo examples", 
        "keyword": "which of the following is an example of on page seo", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "on-page seo examples", 
        "keyword": "which of the following is an example of on-page seo", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "on-page seo testing tools", 
        "keyword": "best on page seo analysis tools", 
        "source": "Keyword Suggestions"
      }, 
      {
        "clusterName": "on-page seo testing tools", 
        "keyword": "on-page seo testing tools", 
        "source": "Keyword Suggestions"
      }, 
      {
        "clusterName": "on-page seo tool", 
        "keyword": "best on page seo tools", 
        "source": "Google Suggest"
      }, 
      {
        "clusterName": "on-page seo tool", 
        "keyword": "best on-page seo tools", 
        "source": "Keyword Suggestions"
      }, 
      {
        "clusterName": "on-page seo tool", 
        "keyword": "free on page seo tools", 
        "source": "Keyword Suggestions"
      }, 
      {
        "clusterName": "on-page seo tool", 
        "keyword": "free on-page seo tools", 
        "source": "Keyword Suggestions"
      }, 
      {
        "clusterName": "on-page seo tool", 
        "keyword": "off page seo tools", 
        "source": "Google Suggest"
      }, 
      {
        "clusterName": "on-page seo tool", 
        "keyword": "off-page seo tools", 
        "source": "Related Keywords"
      }, 
      {
        "clusterName": "on-page seo tool", 
        "keyword": "on page seo free tools", 
        "source": "Keyword Suggestions"
      }, 
      {
        "clusterName": "on-page seo tool", 
        "keyword": "on page seo tool", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "on-page seo tool", 
        "keyword": "on page seo tools", 
        "source": "Google Suggest"
      }, 
      {
        "clusterName": "on-page seo tool", 
        "keyword": "on-page seo analysis tools", 
        "source": "Keyword Suggestions"
      }, 
      {
        "clusterName": "on-page seo tool", 
        "keyword": "on-page seo free tools", 
        "source": "Keyword Suggestions"
      }, 
      {
        "clusterName": "on-page seo tool", 
        "keyword": "on-page seo tool", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "on-page seo tool", 
        "keyword": "on-page seo tools", 
        "source": "Keyword Suggestions"
      }, 
      {
        "clusterName": "on-page seo tool", 
        "keyword": "on-page seo tools and techniques", 
        "source": "Keyword Suggestions"
      }, 
      {
        "clusterName": "on-page seo tool", 
        "keyword": "on-page seo tools free", 
        "source": "Keyword Suggestions"
      }, 
      {
        "clusterName": "on-page seo tool", 
        "keyword": "seo on-page tools", 
        "source": "Keyword Suggestions"
      }, 
      {
        "clusterName": "on-page seo tool", 
        "keyword": "seo tools for on page", 
        "source": "Keyword Suggestions"
      }, 
      {
        "clusterName": "on-page seo tool", 
        "keyword": "tools for on page seo", 
        "source": "Google Suggest"
      }, 
      {
        "clusterName": "on-page seo tool", 
        "keyword": "what tools are used for on page seo", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "on-page seo tool", 
        "keyword": "which tool is perfect for on page seo", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "on-page seo tool", 
        "keyword": "which tool is perfect for on-page seo", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "on-page seo tools list", 
        "keyword": "on-page seo tools list", 
        "source": "Keyword Suggestions"
      }, 
      {
        "clusterName": "on-page seo tools list", 
        "keyword": "what is onpage seo tools", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "onpage seo ahrefs", 
        "keyword": "ahrefs on page seo", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "onpage seo ahrefs", 
        "keyword": "ahrefs on-page seo", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "onpage seo ahrefs", 
        "keyword": "onpage seo ahrefs", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "onpage seo check", 
        "keyword": "check on page seo", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "onpage seo check", 
        "keyword": "check seo on page", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "onpage seo check", 
        "keyword": "on page seo check", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "onpage seo check", 
        "keyword": "on page seo checker", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "onpage seo check", 
        "keyword": "on page seo checker semrush", 
        "source": "Related Keywords"
      }, 
      {
        "clusterName": "onpage seo check", 
        "keyword": "on page seo checker tool", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "onpage seo check", 
        "keyword": "on page seo test", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "onpage seo check", 
        "keyword": "on site seo check", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "onpage seo check", 
        "keyword": "onpage seo check", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "onpage seo check", 
        "keyword": "seo on page check", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "page analysis seo", 
        "keyword": "page analysis seo", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "page analysis seo", 
        "keyword": "seo on page analysis", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "page optimization tool", 
        "keyword": "on page optimization tool", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "page optimization tool", 
        "keyword": "on page optimization tools", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "page optimization tool", 
        "keyword": "on-page optimization tool", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "page optimization tool", 
        "keyword": "onpage optimization tool", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "page optimization tool", 
        "keyword": "onpage optimization tools", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "page optimization tool", 
        "keyword": "page optimization tool", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "page optimization tool", 
        "keyword": "page optimization tools", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "seo page analysis tool", 
        "keyword": "on page seo testing tools", 
        "source": "Keyword Suggestions"
      }, 
      {
        "clusterName": "seo page analysis tool", 
        "keyword": "seo on page analysis tool", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "seo page analysis tool", 
        "keyword": "seo page analysis tool", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "seo site grader", 
        "keyword": "seo page grader", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "seo site grader", 
        "keyword": "seo site grader", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "seo site tools", 
        "keyword": "advanced seo tools", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "seo site tools", 
        "keyword": "seo site tools", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "seo tool best", 
        "keyword": "best seo online tools", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "seo tool best", 
        "keyword": "best seo optimization tool", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "seo tool best", 
        "keyword": "best seo optimization tools", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "seo tool best", 
        "keyword": "best seo tool online", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "seo tool best", 
        "keyword": "best seo tools", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "seo tool best", 
        "keyword": "best seo tools for wordpress", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "seo tool best", 
        "keyword": "best tools for seo", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "seo tool best", 
        "keyword": "open source seo tools", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "seo tool best", 
        "keyword": "seo best tool", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "seo tool best", 
        "keyword": "seo best tools", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "seo tool best", 
        "keyword": "seo optimisation tool", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "seo tool best", 
        "keyword": "seo optimization tools", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "seo tool best", 
        "keyword": "seo optimization tools online", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "seo tool best", 
        "keyword": "seo tool best", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "seo tool best", 
        "keyword": "seo tools best", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "seo tool best", 
        "keyword": "seo tools for wordpress", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "seo tool best", 
        "keyword": "tools for seo optimization", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "seo tool best", 
        "keyword": "what are some seo tools", 
        "source": "Google Suggest"
      }, 
      {
        "clusterName": "seo tools examples", 
        "keyword": "seo tools examples", 
        "source": "Google Suggest"
      }, 
      {
        "clusterName": "seo tools examples", 
        "keyword": "what is an seo tool", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "seo tools pro", 
        "keyword": "pro seo tools", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "seo tools pro", 
        "keyword": "professional seo tools", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "seo tools pro", 
        "keyword": "seo tools pro", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "what are seo aspects", 
        "keyword": "what are seo aspects", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "what are seo aspects", 
        "keyword": "what are the main 3 aspects of why seo is important", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "what are seo aspects", 
        "keyword": "what are the main aspects of seo", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "what is an example of on-page content", 
        "keyword": "what is an example of on page content", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "what is an example of on-page content", 
        "keyword": "what is an example of on-page content", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "what is on-page seo", 
        "keyword": "what is on-page or off-page seo", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "what is on-page seo", 
        "keyword": "what is on-page seo", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "what is on-page seo", 
        "keyword": "what is onpage and offpage seo", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "what is on-page seo", 
        "keyword": "what is the difference between on-site and on-page seo", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "what is seo tool", 
        "keyword": "seo tools definition", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "what is seo tool", 
        "keyword": "what is seo tool", 
        "source": "Competitor's Keyword"
      }, 
      {
        "clusterName": "what is seo vs on-page seo", 
        "keyword": "are technical seo and on-page seo the same thing", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "what is seo vs on-page seo", 
        "keyword": "is technical seo a part of on-page seo", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "what is seo vs on-page seo", 
        "keyword": "what is on-page seo vs technical seo", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "what is seo vs on-page seo", 
        "keyword": "what is seo on-page and off-page and technical", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "what is seo vs on-page seo", 
        "keyword": "what is seo vs on-page seo", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "what is seo vs on-page seo", 
        "keyword": "what is technical on-page seo", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "what is technical in seo", 
        "keyword": "what is technical in seo", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "what is technical in seo", 
        "keyword": "what is the difference between seo and technical seo", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "what is the meaning of on-page", 
        "keyword": "what is on page and off-page in digital marketing", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "what is the meaning of on-page", 
        "keyword": "what is on-page and off-page in digital marketing", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "what is the meaning of on-page", 
        "keyword": "what is onpage in digital marketing", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "what is the meaning of on-page", 
        "keyword": "what is the meaning of on-page", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "what is the meaning of on-page", 
        "keyword": "what is the meaning of on-page in digital marketing", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "which of the following are included in on-page seo", 
        "keyword": "which of the following are included in on-page seo", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "which of the following are included in on-page seo", 
        "keyword": "which of the following is an aspect of on-page seo", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "which of the following is an on page seo tools", 
        "keyword": "which of the following is an on page seo tool *", 
        "source": "People Also Ask"
      }, 
      {
        "clusterName": "which of the following is an on page seo tools", 
        "keyword": "which of the following is an on page seo tools", 
        "source": "People Also Ask"
      }
    ]


// functions

function getCompetitorsList(competitorsStr) {
  return (competitorsStr.split('\n') || []).reduce((prevVal, c) => {
    if (validator.isURL(c.trim())) {
      prevVal.push(c.trim())
    }
    return prevVal
  }, [])
}

type STEP = null | 'LOADING_KEYWORD' | 'LOADING_RESULT_FOR_KEYWORD'
const ALLOWED_KEYWORD_COUNT = 10
export default function SupportingKeywordResearch(props) {

  const user = useSelector((state: RootState) => state.user.user)
  const dispatch = useDispatch();
  const params = useParams()
  const { siteId, campaignId } = useParams()

  const formdata = useSelector((state: RootState) => state.formdata)
  const siteData = formdata.site


  const progressObjRef = useRef(null);

  const [KeywordTypeOptions, setKeywordTypeOptions] = useState([])
  const [extractedKeywords, setExtractedKeywords] = useState([]);
  const [research_id, setResearch_id] = useState(0)

  const [currentReport, setCurrentReport] = useState(null);
  const [researchHistory, setResearchHistory] = useState([])

  const [progressObj, setProgressObj] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [domain, setDomain] = useState("");
  const [location, setLocation] = useState(siteData?.location_code);
  const [language, setLanguage] = useState(null);
  const [competitors, setCompetitors] = useState('')
  const [topics, setTopics] = useState('')
  const [checkOnlyCompetitors, setCheckOnlyCompetitors] = useState(false)

  const [keywordExtractTaskId, setKeywordExtractTaskId] = useState(null)
  const [resultTaskId, setResultTaskId] = useState(null)

  const [currentStep, setCurrentStep] = useState<STEP>(null)
  // keywords filter options
  const [includeFilterTermText, setIncludeFilterTermText] = useState('');
  const [includeAllFilter, setIncludeAllFilter] = useState(false)
  const [excludeFilterTermText, setExcludeFilterTermText] = useState('');
  const [excludeAllFilter, setExcludeAllFilter] = useState(false)
  const [includeQuestionKeyword, setIncludeQuestionKeyword] = useState(false)
  const question_prefixs = 'who|what|where|when|why|how|was|were|did|do|does|is|are'.split('|')
  const [keywordTypesFilter, setKeywordTypesFilter] = useState([])

  //


  const filteredKeywords = extractedKeywords.filter(item => { // Apply include filters
    const filterTerms = includeFilterTermText.split(',').map(term => term.trim()).filter(term => term !== '');
    if (filterTerms.length == 0) return true;

    if (!includeAllFilter) {
      for (const term of filterTerms) {
        if (item['keyword'].includes(term)) {
          return true;
        }
      }
      return false;
    } else {
      for (const term of filterTerms) {
        if (!item['keyword'].includes(term)) {
          return false;
        }
      }
      return true;
    }
    return true;
  })
    .filter(item => {  // Apply exclude filters
      const filterTerms = excludeFilterTermText.split(',').map(term => term.trim()).filter(term => term !== '');
      if (filterTerms.length == 0) return true;

      if (!excludeAllFilter) {
        for (const term of filterTerms) {
          if (item['keyword'].includes(term)) {
            return false;
          }
        }
        return true;
      } else {
        for (const term of filterTerms) {
          if (!item['keyword'].includes(term)) {
            return true;
          }
        }
        return false;
      }
      return true;
    })
    .filter(item => {
      if (includeQuestionKeyword) {
        for (let index = 0; index < question_prefixs.length; index++) {
          const element = question_prefixs[index];
          if (item['keyword'].startsWith(element)) {
            return true
          }
        }
        return false
      } else {
        return true
      }
    })
    .filter(item => { // Keyword Type filter
      if (keywordTypesFilter.length > 0) {
        for (let index = 0; index < keywordTypesFilter.length; index++) {
          const keywordType_ = keywordTypesFilter[index];

          if (item['source'].includes(keywordType_)) {
            return true
          }
        }
        return false
      }
      return true
    })
  useEffect(() => {
    if (campaignId) {
      loadData()
      updateLanguageLocation(null)
    }
  }, [campaignId, research_id])
  useEffect(() => {
    const competitorsCount = getCompetitorsList(competitors).length
    if (competitorsCount > 0 || checkOnlyCompetitors) {
      setKeywordTypeOptions(KeywordTypeOptionsFull)
    } else {
      setKeywordTypeOptions(KeywordTypeOptionsFull.filter(item => item != "Competitor's Keyword"))
    }
  }, [checkOnlyCompetitors, competitors])

  const topicsCount = topics.split(',').filter(item => item.trim() !== '').length;
  const competitorsCount = getCompetitorsList(competitors).length

  const loadData = () => {
    clear();
    loadSupportResearchHistoryForCampaign()
    loadSupportingKeywordResearchResult()
  }
  const loadSupportResearchHistoryForCampaign = () => {
    axios.get(`/api/support_keyword/history/campaign/${campaignId}`).then((res) => {
      setResearchHistory(res.data)
    })
  }
  const updateLanguageLocation = (report) => {
    if (siteData?.default_loc_lang_enabled) {
      setLocation(report?.location_code || siteData.location_code)
      const lang = langs.find(l => l.language_code == (report?.languageCode || siteData.language_code))
      if (lang) {
        setLanguage(lang)
      }
      setDomain(report?.domain || siteData?.base_url)

    } else {
      setDomain(report?.domain || siteData?.base_url)
      setLocation(report?.location_code)
      const lang = langs.find(l => l.language_code == report?.languageCode)
      if (lang) {
        setLanguage(lang)
      }
    }
  }
  const loadSupportingKeywordResearchResult = () => {
    CallApi({
      url: `/api/support_keyword/get_keyword_result/${campaignId}/${research_id}`,
      method: 'get'
    })
      .then((response) => {
        if (response) {
          setCurrentReport(response)
          setTopics(response.target_topics ? response.target_topics.join(',') : '');
          setCompetitors(response.competitor_urls ? response.competitor_urls.join('\n') : '');
          setCheckOnlyCompetitors(response.check_only_competitors == 1)

          updateLanguageLocation(response)

        } else {
          updateLanguageLocation(null)
        }
      })
      .catch((error) => {
        console.log('failed to load', error)
      });
  }



  const startSearchKeywords = async () => {

    if (topicsCount > ALLOWED_KEYWORD_COUNT) {
      toast.error(`${ALLOWED_KEYWORD_COUNT} keywords are allowed at max`)
      return
    }

    setExtractedKeywords([]);
    const topic_splitted_list = getSplittedKeywords(topics);

    let topics_arr = topic_splitted_list.map(item => item.trim()).filter((t) => !t.includes('.')).slice(0, 10)

    let competitors_arr = getCompetitorsList(competitors).slice(0, 20)

    const formdata = {
      campaignId: campaignId,
      topics: topics_arr,
      location_code: location,
      language_code: language.language_code,
      checkOnlyCompetitors: checkOnlyCompetitors,
      competitors: competitors_arr,
      minWordsLimit: props.minWordsLimit || 3,
      maxWordsLimit: props.maxWordsLimit || 100,
    }
    console.info('=== topics_arr ===', JSON.stringify(topics_arr, null, 4))
    setCurrentStep('LOADING_KEYWORD')

    try {
      setIsLoading(true);
      CallApi({
        url: '/api/support_keyword/get-keywords-from-topic',
        method: 'post',
        data: formdata
      }).then((response) => {
        setKeywordExtractTaskId(response.taskId);
      }).catch((e) => {
        toast.error('Failed to create task to get a keywords')
      }).finally(() => {
      })
    } catch (e) {
      console.log(e)
    }
  }

  function handleSearchKeywords(evt) {
    if (siteId && !checkDomainMatch(domain, siteData.base_url)) {
      toast.error("Domain not matched")
      return
    }
    if (evt) {
      evt.preventDefault();
    }

    // if (
    //   !user?.is_admin &&
    //   user?.dailyKeywordLimit <= user?.used_keywords_count_today
    // ) {
    //   toast.error("You exceed the today's usage.");
    //   return;
    // }
    const splittedKeywords = filteredKeywords.map(f => f.keyword);

    // let remainLimit =
    //   user?.dailyKeywordLimit - user?.used_keywords_count_today || 0;
    // if (!user.is_admin && splittedKeywords.length > remainLimit) {
    //   toast.error(
    //     "You have inputted too many keywords than your remained limit"
    //   );
    //   return;
    // }

    const validKeywords = getValidKeywords(splittedKeywords)

    let topics_arr = topics.split(',').map(item => item.trim()).slice(0, 10)

    let competitors_arr = getCompetitorsList(competitors).slice(0, 20)
    const formdata = {
      siteId: siteId,
      campaignId: campaignId,
      keywords: validKeywords,
      location_code: location,
      language_code: language.language_code,
      domain: domain,
      target_topics: topics_arr,
      competitor_urls: competitors_arr
    }
    setCurrentStep('LOADING_RESULT_FOR_KEYWORD')
    setIsLoading(true);

    CallApi({
      url: '/api/support_keyword/keyword-research-support',
      method: 'post',
      data: formdata
    })
      .then((response) => {
        setResultTaskId(response.taskId)
      })
      .catch((error) => {
        console.log('failed to load', error)
      })
      .finally(() => {
        setIsLoading(false)
      });
  }
  useEffect(() => {
    if (keywordExtractTaskId) {
      createKeywordExtractTaskMonitor();
    }
    if (resultTaskId) {
      createKeywordResultTaskMonitor();
    }
  }, [keywordExtractTaskId, resultTaskId]);
  function clear() {
    console.log('clearing task interval because task is finished with success/failure', intervalTaskId)
    if (intervalTaskId) {
      clearInterval(intervalTaskId);
      intervalTaskId = null;
    }
    setKeywordExtractTaskId(null)
    setResultTaskId(null)
    setProgressObj(null);
    setIsLoading(false)
    progressObjRef.current = {};

  }
  const createKeywordResultTaskMonitor = () => {
    console.log('creating task monitor to get update')
    const splittedKeywords = filteredKeywords.map(f => f.keyword);

    intervalTaskId = setInterval(() => {
      axios.get(`/api/get-task-status/${resultTaskId}`).then((response) => {

        if (response && response.status === 200) {
          console.info('=== response.data.status ===', JSON.stringify(response.data?.status, null, 4))
          if (response.data?.status === "PROGRESS") {
            let value = (progressObjRef.current?.value || 0) + ( 1 / splittedKeywords.length );
            if (value < response.data.value) value = response.data.value;
            if (value > 98) value = 98;
            let newVal = {
              value: value,
              msg: `${response.data.msg} While you wait, you can leave this screen.` // response.data.msg
            };
            progressObjRef.current = newVal;
            setProgressObj(newVal);
          } else if (response.data?.status === "SUCCESS") {
            loadData()
            toast.success("Completed keyword research!!");
          } else if (response.data?.status === "FAILURE") {
            toast.error("Failed!!");
            clear();
          }
        } else {
          clear();
        }
      })
      .catch((error) => {
        console.log('failed to load', error)
      });
      return () => {
        clear();
      };
    }, TIME_INTERVAL);
  }
  const createKeywordExtractTaskMonitor = () => {
    console.log('creating task monitor to get update')
    intervalTaskId = setInterval(() => {
      CallApi({
        method: 'get',
        url: `/api/get-task-status/${keywordExtractTaskId}`
      }).then((response) => {
        if (response?.status === "PROGRESS") {
          let value = (progressObjRef.current?.value || 0) + 1;
          if (value < response.value) value = response.value;
          if (value > 100) value = 100;
          let newVal = {
            value: value,
            msg: `${response.msg} While you wait, you can leave this screen.` // response.msg
          };
          progressObjRef.current = newVal;
          setProgressObj(newVal);
        } else if (response?.status === "SUCCESS") {
          clear();
          setExtractedKeywords(response.result.keywords)

          toast.success("Completed keyword research!!");
        } else if (response?.status === "FAILURE") {

          toast.error("Failed!!");
          clear();
        }
      }).catch(() => {
        clear();
      })
    }, TIME_INTERVAL);
  }

  const canStartAnalysis = () => {
    if (!location) {
      return false;
    }
    if (!language) {
      return false;
    }
    if (checkOnlyCompetitors && !competitors.trim()) {
      return false;
    }

    return true;
  };
  const renderKeywordsResult = () => {
    if (!extractedKeywords || extractedKeywords.length == 0) {
      return <></>
    }

    let clusterNames = filteredKeywords.map(item => item.clusterName)
    clusterNames = [...new Set(clusterNames)]

    return (
      <Box>
        <Container maxWidth="xl">
          <Box mb={2} mt={6}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Typography variant='h5' color='primary.dark'>{filteredKeywords.length} Keywords Found</Typography>
              </Grid>
              <Grid item xs={12} md={6} justifyContent='flex-end' display="flex">
                <Button variant='contained' sx={{
                  '& a': {
                    color: 'white'
                  }
                }}>
                  <CSVLink
                    data={keywordsCSVData}
                    filename={`Keywords_${(new Date()).toLocaleDateString()}.csv`}
                  >Export Keywords (CSV)</CSVLink>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>

        <Box sx={{
          backgroundColor: '#2B63490D'
        }}>
          <Container maxWidth="xl">
            <Box py={4}>
              <Typography variant="h5" color='primary.dark'>Select Keywords to Measure</Typography>
              <Typography color='primary.dark' my={2}>
                Select the keywords or topics below that you want to gather keyword score data about, and hit next to get the data.
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                  <TextField placeholder="Please input filter keywords separated by comma" variant="outlined"
                    label="Include Words"
                    value={includeFilterTermText}
                    onChange={(e) => { setIncludeFilterTermText(e.target.value) }}
                    fullWidth
                    sx={{
                      '& input': {
                        backgroundColor: 'white',
                        borderRadius: '4px 0 0 4px'
                      },
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position='end'>

                        <FormControlLabel control={<Switch defaultChecked />} sx={{ m: 0 }}
                          label={includeAllFilter ? "All" : "Any"}
                          checked={includeAllFilter}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setIncludeAllFilter(e.target.checked)
                          }}
                        />
                      </InputAdornment>
                    }}
                  />

                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField placeholder="Please input filter keywords separated by comma" variant="outlined"
                    label="Exclude Words"
                    value={excludeFilterTermText}
                    onChange={(e) => { setExcludeFilterTermText(e.target.value) }}
                    fullWidth
                    sx={{
                      '& input': {
                        backgroundColor: 'white',
                        borderRadius: '4px 0 0 4px'
                      },
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position='end'>

                        <FormControlLabel control={<Switch defaultChecked />} sx={{ m: 0 }}
                          label={excludeAllFilter ? "All" : "Any"}
                          checked={excludeAllFilter}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setExcludeAllFilter(e.target.checked)
                          }}
                        />
                      </InputAdornment>
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <FormControl fullWidth>
                    <InputLabel id="keyword_type_select">Keywords Type</InputLabel>
                    <Select
                      labelId="keyword_type_select"
                      id="keyword_type_select_id"
                      multiple
                      value={keywordTypesFilter}
                      label='Keywords Type'
                      onChange={(e) => {
                        const value = e.target.value
                        setKeywordTypesFilter(typeof value === 'string' ? value.split(',') : value)
                      }}
                      input={<OutlinedInput label="Keywords Type" sx={{ backgroundColor: 'white' }} />}
                      renderValue={(selected) => selected.join(', ')}
                    // MenuProps={MenuProps}
                    >
                      {KeywordTypeOptions.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={keywordTypesFilter.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControlLabel control={<Switch onChange={(e) => {
                    setIncludeQuestionKeyword(e.target.checked)
                  }}
                    checked={includeQuestionKeyword}
                  />} label="Question Keywords"
                  />
                </Grid>
              </Grid>
              <Button
                size='large'
                disabled={!canStartAnalysis()}
                onClick={handleSearchKeywords}
                variant="contained"
                endIcon={<ArrowForwardIcon />}
              >
                Calculation for Keywords
              </Button>
            </Box>
          </Container>
        </Box>
        <Container maxWidth="xl" >
          <Box py={2}>
            {
              clusterNames.map((clusterName, clusterIdx) => {
                return (
                  <ClusterWrapper key={'cluster_'+clusterIdx}>
                    <div className="cluster-header">
                      <div className="cluster-name">Cluster name: { clusterName }</div>
                      <FeatherIcon icon="x-circle" size={22}
                        onClick={() => {
                          setExtractedKeywords(extractedKeywords.filter(arg => arg.clusterName != clusterName))
                        }}
                      />
                    </div>

                    {
                    filteredKeywords.length > 0 && filteredKeywords.filter( item => item.clusterName == clusterName ).map((item, idx) => {
                      return <div key={idx}>
                        <Box
                          sx={{
                            backgroundColor: '#2B63490D',
                            margin: '5px 5px 5px 20px',
                            padding: '3px 7px',
                            borderRadius: 24,
                            border: '1px solid transparent',
                            '&:hover': {
                              border: '1px solid #2B63490D'
                            },
                            display: 'inline-flex',
                            '&> :nth-child(1)': {
                              flexGrow: 1
                            }
                          }}
                        >
                          <div className="icon-btn">
                            <FeatherIcon icon="x-circle" size={22}
                              onClick={() => {
                                setExtractedKeywords(extractedKeywords.filter(arg => arg.keyword != item.keyword))
                              }}
                            />
                          </div>
                          <span className="mx-3">
                            {item.keyword}
                          </span>
                          <span>
                            ({item.source})
                          </span>
                        </Box>
                      </div>
                    })
                  }
                  </ClusterWrapper>
                )
              })
            }
          </Box>
        </Container >
      </Box >
    )
  }

  const handlePauseTask = () => {
    setIsLoading(false);
    if (keywordExtractTaskId) {
      axios.get('/api/revoke_task/' + keywordExtractTaskId).then((res) => {
        clear();
      })
      .catch((error) => {
        console.log('failed to load', error)
      });
    }
    if (resultTaskId) {
      axios.get('/api/revoke_task/' + resultTaskId).then((res) => {
        clear();
      })
      .catch((error) => {
        console.log('failed to load', error)
      });
    }
  }

  const makeKeywordsCSVData = () => {
    let results = []
    results.push(['Keyword']);
    for (let data of filteredKeywords) {
      results.push([data['keyword']])
    }
    return results;
  }


  const onDeleteAllKeywordData = () => {
    if (currentReport) {
      axios
        .delete(`/api/support_keyword/delete_all_keywords_data/${campaignId}`)
        .then((res) => {
          loadSupportingKeywordResearchResult()
        })
    }
  }
  const onChangeResearchId = (research_id: number) => {
    setResearch_id(research_id)
  }
  const keywordsCSVData = makeKeywordsCSVData();

  return (
    <Box>
      <Container maxWidth="xl">
        <ShadowBox>
          <Typography variant="h5" color='primary.dark'>Search for Supporting Keywords</Typography>
          <Box my={2}>
            <TextField
              label={"Target Keyword/Topic" + `(${topicsCount} / ${ALLOWED_KEYWORD_COUNT} )`}
              placeholder="Please input the target topics, you can add multiple topics by comma"
              rows={3}
              multiline
              value={topics}
              onChange={(e) => setTopics(e.target.value)}
              fullWidth
            ></TextField>
          </Box>
          <Grid container spacing={2} my={2}>
            <Grid item xs={12} md={4}>
              <CountrySelect
                isMulti={false}
                value={location}
                onChange={(loc) => setLocation(loc)}
                placeholder="SERP Location"
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <LanguageSelect
                value={language}
                onChange={(item) => {
                  setLanguage(item);
                }}
                isMulti={false}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12} md={4}>

              <TextField
                size='small'
                value={domain}
                label="Domain"
                onChange={(e) => {
                  setDomain(e.target.value);
                }}
                fullWidth={true}
              />
            </Grid>
          </Grid>
          <Box>
            <TextField
              label={"Competitor Urls" + `(${competitorsCount} / 20)`}
              placeholder="You can add upto 20 competitors."
              rows={3}
              multiline
              value={competitors}
              onChange={(e) => setCompetitors(e.target.value)}
              fullWidth
            ></TextField>

            <FormControlLabel
              control={<Switch checked={checkOnlyCompetitors} onChange={(e) => setCheckOnlyCompetitors(e.target.checked)}
              />}
              label='Only Analyze Competitors' />

          </Box>
          <Box mt={4} >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={4}>

                <Button
                  size='large'
                  disabled={isLoading}
                  onClick={startSearchKeywords}
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                  color='secondary'
                  sx={{
                    borderRadius: 10,
                  }}
                >
                  Search Keywords
                </Button>
                {/* <Button onClick={() => {
                  setCurrentStep('LOADING_KEYWORD')
                  setExtractedKeywords(test_data)
                }}>test</Button> */}
              </Grid>
              <Grid item xs={12} md={8}>
                <ProgressBar
                  progressObj={progressObj}
                  onPause={handlePauseTask}
                />
              </Grid>
            </Grid>
          </Box>
          <Box>

          </Box>
        </ShadowBox>
      </Container>
      {
        (currentStep == 'LOADING_KEYWORD') && renderKeywordsResult()
      }
      {currentReport && currentStep != 'LOADING_KEYWORD' && (
        <KeywordsResearchResult
          onChangeResearchId={onChangeResearchId}
          campaignId={Number(campaignId)}
          report={currentReport}
          calculating={isLoading}
          researchHistory={researchHistory}
          id='supporting_keyword_research'
          onDeleteAllKeywordData={onDeleteAllKeywordData}
        />
      )}
    </Box>
  )
}
