import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import toast from 'react-hot-toast';
import SILOGraph from './SILOComponents/SILOGraph';
import moment from 'moment'
import { jsPDF } from "jspdf";
import validator from 'validator';
import { useParams } from 'react-router-dom';
import { Box, Button, Container, Grid, TextField, Typography } from '@mui/material';
import { CallApi, checkDomainMatch } from 'utils';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ShadowBox from 'components/ShadowBox';
import ProgressBar, { ProgressObjType } from "components/ProgressBar/ProgressBar";
import {RootState} from "../../../../redux/store";
let intervalTaskId = null;

export default function (props) {
  const dispatch = useDispatch()
  const { siteId, campaignId } = useParams();
  const formdata = useSelector((state : RootState) => state.formdata)
  const siteData = formdata.site

  const progressObjRef = useRef(0);
  const [progressObj, setProgressObj] = useState<ProgressObjType>();
  const [calculating, setCalculating] = useState(false);

  const [siloData, setSiloData] = useState(null)

  const [topPage, setTopPage] = useState('')
  const [supportingContentStr, setSupportingContentStr] = useState('')
  const [anchorsStr, setAnchorsStr] = useState('')

  const [nodesAndEdges, setNodesAndEdges] = useState(null)

  const chartRef = useRef(null);
  const chartContainerRef = useRef(null)

  useEffect(() => {
    if (calculating) {
      let supportingContentArr = supportingContentStr.split('\n').filter(item => item.trim() != '')
      intervalTaskId = setInterval(() => {
        progressObjRef.current = Math.min(progressObjRef.current + (100 / (supportingContentArr.length * 5)), 100)
        setProgressObj({
          value: progressObjRef.current,
          msg: 'Processing... While you wait, you can leave this screen'
        })
      }, 1000);
    } else {
      setProgressObj(undefined)
    }
  }, [calculating])

  useEffect(() => {
    if (campaignId) {
      CallApi({
        url: `/api/silo/get_research_silo/${campaignId}`
      }).then((res) => {
        setSiloData(res)
      })
      .catch((error) => {
        console.log(error)
      })
    }
  }, [campaignId])
  useEffect(() => {
    if (siloData) {
      const topPage = siloData.silo_top_page
      const supportingContentStr = (siloData.silo_supporting_content || []).join('\n')
      const anchorsStr = (siloData.silo_anchors || []).join('\n')
      const siloUrlTitles = siloData.silo_url_titles || {}

      setTopPage(topPage)
      setSupportingContentStr(supportingContentStr)
      setAnchorsStr(anchorsStr)
      startCalculate({ topPage, supportingContentStr, anchorsStr, siloUrlTitles, noSave: true });
    }
  }, [siloData])

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.resize()
    }
  }, [chartRef])

  const startCalculate = async (params) => {

    const p_topPage = params?.topPage ? params.topPage : topPage
    const p_supportingContentStr = params?.supportingContentStr ? params.supportingContentStr : supportingContentStr
    const p_anchorsStr = params?.anchorsStr ? params.anchorsStr : anchorsStr
    let urlTitles = params?.siloUrlTitles ? params.siloUrlTitles : {}
    let noSave = params?.noSave || false

    if (!p_topPage || !p_supportingContentStr || !p_anchorsStr) {
      return [[], []]
    }

    const SPACE = 100

    let anchors = p_anchorsStr.split('\n').filter((item) => {
      if (item.trim() != '') {
        return true;
      }
      return false;
    })
    let supportingContentArr = p_supportingContentStr.split('\n').filter(item => item.trim() != '')

    const urls = [p_topPage, ...supportingContentArr].filter(url => url.startsWith('http'))

    const colors = ['#12814C', '#2F80ED', '#9B51E0', '#F2994A']
    const colors_background = ['#E7F2ED', '#EAF2FD', '#F5EEFC', '#FEF5ED']
    if (!params) {
      try {
        const response = await CallApi({
          url: '/api/silo/get-title-of-urls',
          method: 'post',
          data: { urls }
        })
        urlTitles = response.result
      } catch (e) {
        console.log(e)
      }
    }

    const CHILD_COUNT = supportingContentArr.length

    let rootNode = {
      name: 'root',
      x: SPACE * (CHILD_COUNT - 1),
      y: SPACE * (CHILD_COUNT - 1) / 2,
      label: {
        show: true,
        position: 'bottom',
        formatter: function (data) {
          return 'Selected Top Level Page' + '\n' + p_topPage
        },
        backgroundColor: colors_background[0],
        borderColor: colors[0],
        color: colors[0],
        borderWidth: 1,
        shadowColor: 'transparent'
      },
      itemStyle: {
        color: colors[0]
      },
      nodeLabel: p_topPage,
    }
    let childNodes = supportingContentArr.map((item, idx) => {
      return {
        name: `Node-${idx}`,
        x: 0,
        y: SPACE * idx,
        label: {
          show: true,
          formatter: function (data) {
            return anchors[idx % anchors.length] + '\n' + item
          },
          backgroundColor: colors_background[idx % 3 + 1],
          borderColor: colors[idx % 3 + 1],
          color: colors[idx % 3 + 1],
          borderWidth: 1,
          shadowColor: 'transparent'
        },
        tooltip: {
          formatter: 'tooltip',
        },
        itemStyle: {
          color: colors[idx % 3 + 1],
        },
        nodeLabel: item, // my own use
      }
    })




    let edges = []

    for (let i = 0; i < CHILD_COUNT; i++) {
      edges.push({
        source: `Node-${i}`,
        target: rootNode.name,
        label: {
          show: true,
          formatter: function (data) {
            return anchors[i % anchors.length]
          },
          backgroundColor: colors_background[i % 3 + 1],

          shadowColor: '#00000088',
          shadowBlur: 2,
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          borderRadius: 10,
          padding: 10,
          verticalAlign: 'middle',
          rotate: '0deg',
          align: 'left'
        },
        lineStyle: {
          color: colors[i % 3 + 1]
        },

      })
      if (i == 0) {
        edges.push({
          source: rootNode.name,
          target: `Node-${i}`,
          label: {
            show: true,
            formatter: function (data) {
              return urlTitles[supportingContentArr[0]] || supportingContentArr[0]
            },
            backgroundColor: colors_background[0],
            shadowColor: '#00000088',
            shadowBlur: 2,
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            borderRadius: 10,
            padding: 10,
            verticalAlign: 'middle',
            align: 'right',
            rotate: '0deg'
          },
          lineStyle: {
            curveness: 0.1,
            color: colors[0]
          }
        })
      }
      if (i < CHILD_COUNT - 1) {
        edges.push({
          source: `Node-${i}`,
          target: `Node-${i + 1}`,
          label: {
            show: true,
            formatter: function (data) {
              const nodeLable = childNodes[i + 1].nodeLabel
              if (validator.isURL(nodeLable)) {
                return urlTitles[nodeLable] || 'Not Found'
              } else {
                return nodeLable
              }
            },
            shadowColor: '#00000088',
            shadowBlur: 2,
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            backgroundColor: '#eee',
            borderRadius: 10,
            padding: 10,
            verticalAlign: 'bottom',
            rotate: '0deg',
            align: 'right'
          },
          lineStyle: {
            curveness: 0.2
          }
        })
        edges.push({
          source: `Node-${i + 1}`,
          target: `Node-${i}`,
          label: {
            show: true,
            formatter: function (data) {
              const nodeLable = childNodes[i].nodeLabel
              if (validator.isURL(nodeLable)) {
                return urlTitles[nodeLable] || 'Not Found'
              } else {
                return nodeLable
              }
            },
            shadowColor: '#00000088',
            shadowBlur: 2,
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            backgroundColor: '#eee',
            borderRadius: 10,
            padding: 10,
            verticalAlign: 'top',
            align: 'left',
            rotate: '0deg'
          },
          lineStyle: {
            curveness: 0.2
          }
        })
      }
    }


    const nodes = [rootNode, ...childNodes]
    if (!noSave) {

      // persist silo data
      try {
        let response = await CallApi({
          url: '/api/silo/save-silo',
          method: 'post',
          data: {
            campaignId: campaignId,
            supportingContent: supportingContentArr,
            anchors: anchors,
            topPage: p_topPage,
            urlTitles: urlTitles,
            nodesAndLinks: {
              nodes, edges
            },
          }
        })
      } catch (e) {
        console.log(e)
      }
    }

    setNodesAndEdges({
      nodes,
      edges,
    })

  }


  const checkIfDataIsValid = async () => {
    if (!checkDomainMatch(topPage, siteData.base_url)) {
      toast.error("Domain not matched")
      return
    }
    if (!topPage) {
      return;
    }

    setCalculating(true)
    await startCalculate(null);
    handlePauseTask()
  }

  const nodes = nodesAndEdges?.nodes || [];
  const edges = nodesAndEdges?.edges || [];

  const saveAsPDF = () => {
    const name = moment().format('YYYY_M_D_h_mm_ss') + '.pdf'
    var doc = new jsPDF('p', 'mm', 'a3');
    const docwidth = doc.internal.pageSize.getWidth()
    const docheight = doc.internal.pageSize.getHeight()

    const containerWidth = chartContainerRef.current.offsetWidth
    const containerHeight = chartContainerRef.current.offsetHeight

    const renderWidth = docwidth
    const renderHeight = containerHeight * docwidth / containerWidth
    const top = (docheight - renderHeight) / 2

    doc.addImage(chartRef.current.getEchartsInstance().getDataURL({ backgroundColor: '#fff' }),
      'JPEG', 0, top, renderWidth, renderHeight);
    doc.save(name);
  };
  const handlePauseTask = () => {
    setCalculating(false);
    setProgressObj(undefined);
    if (intervalTaskId) {
      clearInterval(intervalTaskId);
      intervalTaskId = null;
    }
    progressObjRef.current = 0;
  }

  return (

    <Box>
      <Container maxWidth="xl">
        <ShadowBox>
          <Grid container>
            <Grid item xs={12} md={6}>
              <Typography variant="h5" color='primary.dark'>Generate Silo Structures</Typography>
            </Grid>
            <Grid item xs={12} md={6} textAlign='right'>
              <Button variant="contained" onClick={() => saveAsPDF()} color='secondary'
                sx={{
                  backgroundColor: 'secondary.light',
                  borderRadius: 10,
                }}>Download PDF</Button>
            </Grid>
          </Grid>

          <Grid container spacing={2} my={2}>
            <Grid item xs={12}>
              <TextField
                size='small'
                label="Top level Page"
                fullWidth={true}
                value={topPage}
                onChange={e => setTopPage(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Supporting Content Topics or URLs"
                size='small'
                rows={3}
                multiline
                fullWidth={true}
                placeholder='Enter up to 30 pages'
                value={supportingContentStr}
                onChange={e => setSupportingContentStr(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Anchors from supporting to top level page"
                size='small'
                rows={3}
                multiline
                fullWidth={true}
                placeholder='Enter up to 30 anchors'
                value={anchorsStr}
                onChange={e => setAnchorsStr(e.target.value)} />
            </Grid>
            <Grid item xs={12}>

            </Grid>
          </Grid>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} md={2}>
              <Button variant='contained' onClick={checkIfDataIsValid}
                endIcon={<ArrowForwardIcon />}
                color='secondary'
                sx={{
                  borderRadius: 10,
                }}
                disabled={calculating}
              >Create SILO</Button>
            </Grid>
            <Grid item xs={12} md={10}>
              <ProgressBar
                progressObj={progressObj}
                onPause={handlePauseTask}
              />
            </Grid>
          </Grid>
        </ShadowBox>
      </Container>
      <Box ref={chartContainerRef} mt={2}>
        <SILOGraph
          nodes={nodes}
          edges={edges}
          chartRef={chartRef}
        />
      </Box>
    </Box>
  )
}