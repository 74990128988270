import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import "react-input-range/lib/css/index.css";

import {
    Box, Button,
    Container,
    FormControlLabel, Grid, InputAdornment,
    Switch, TextField, Typography
} from "@mui/material";
import { CustomSlider } from "components/CustomSlider";
import './CASearchForm.scss';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { BOX_SHADOW } from "theme/colors";

export default ({
    onFilter
}: {
    onFilter?: (filterOptions) => void
}) => {

    const dispatch = useDispatch();
    const [scores, setScores] = useState([0, 100])
    const [crawlDateFrom, setCrawlDateFrom] = useState(null)
    const [crawlDateTo, setCrawlDateTo] = useState(null)

    return (
        <Box>
            <LocalizationProvider dateAdapter={AdapterMoment}>

                <Container maxWidth="xl">
                    <Box sx={{
                        backgroundColor: 'white',
                        boxShadow: BOX_SHADOW,
                        borderRadius: '8px',
                        marginTop: 6,
                        marginBottom: 6
                    }}>

                        <Box sx={{
                            padding: 2
                        }}>
                            <Grid container spacing={2}>
                                {/* <Grid item xs={12} md={6}>
                                    <Box sx={{ textAlign: 'left' }}>
                                        <Typography color='primary.dark'>Crawled Date Range</Typography>
                                        <DatePicker value={crawlDateFrom}
                                            label="Crawled From"
                                            onChange={v => setCrawlDateFrom(v)}
                                            sx={{
                                                mr: 1,
                                                mt: 1
                                            }} />
                                        <DatePicker value={crawlDateFrom}
                                            label="Crawled To"
                                            onChange={v => setCrawlDateTo(v)}
                                            sx={{
                                                mr: 1,
                                                mt: 1
                                            }} />
                                    </Box>
                                </Grid> */}
                                <Grid item xs={12} md={4}>
                                    <Typography color='primary.dark'>Scores</Typography>
                                    <CustomSlider
                                        getAriaLabel={() => 'Keyword Score Filter'}
                                        value={scores}
                                        onChange={(event: Event, newValue: number | number[]) => setScores(newValue as number[])}
                                        valueLabelDisplay="on"
                                        min={0}
                                        max={100}
                                    />
                                </Grid>
                            </Grid>

                            <Box display='flex' mt={4} mb={2}>
                                <Box mr={2}>
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            onFilter({
                                                scoreMin: scores[0],
                                                scoreMax: scores[1],
                                                crawlDateFrom: crawlDateFrom,
                                                crawlDateTo: crawlDateTo
                                            })
                                        }}
                                        color='secondary'
                                        sx={{
                                            borderRadius: 10,
                                        }}
                                    >
                                        Apply Filters
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Box >

                </Container>
            </LocalizationProvider>
        </Box >
    );
}