
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "font-awesome/css/font-awesome.css";
import "tempusdominus-bootstrap/build/css/tempusdominus-bootstrap.css";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';
import LoginPage from 'views/auth/LoginPage';
import PageNotRound from 'views/PageNotFound.js';
import { privateRoutes } from 'routes'
import { useIsAuthenticated } from 'utils';
import { PersistGate } from "redux-persist/es/integration/react";
import { store, persistor } from 'redux/store';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { theme } from 'theme/index';
import RegisterPage from 'views/auth/RegisterPage';
import ConfirmEmail from 'views/auth/ConfirmEmail';
import { logoutAction } from 'redux/reducers/user'

axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL;

console.info('=== REACT_APP_BASE_API_URL ===', JSON.stringify(process.env.REACT_APP_BASE_API_URL, null, 4))
axios.interceptors.response.use(
  response => response,
  error => {
    console.log('interectpr error', error)
    if (error.response && error.response.status === 401) {
      store.dispatch(logoutAction())
      Promise.resolve();
      window.location.href = '/login';
    }
    return Promise.reject(error.response);
  }
);


function RequireAuth({ children }: { children: JSX.Element }) {
  let location = useLocation();
  const isAuthenticated = useIsAuthenticated()

  if (!isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    // return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor} loading={null}>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="/" element={<App />}>
              {
                privateRoutes.map((item, idx) => (
                  <Route path={item.path}
                    element={
                      <RequireAuth>
                        <item.component />
                      </RequireAuth>
                    }
                    key={idx}
                  />
                ))
              }
              <Route element={<PageNotRound />} />

              <Route path='/login' element={<LoginPage />} />
              <Route path='/register' element={<RegisterPage />} />
              <Route path='/confirm/:token' element={<ConfirmEmail />} />
            </Route>
          </Routes>
        </Router>
      </ThemeProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
