import { Box, Button, Checkbox, Container, FormControlLabel, FormGroup, Grid, Switch, TextField, Tooltip, Typography } from '@mui/material';
import Axios from 'axios';
import { useMemo, useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CSVLink, CSVDownload } from "react-csv";

import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import toast from "react-hot-toast";
import isURL from 'validator/lib/isURL';
import {
  showLoadingIndicator, hideLoadingIndicator
} from 'redux/reducers/appstate'
import { CallApi, checkDomainMatch } from 'utils'
import ProgressBar, { ProgressObjType } from 'components/ProgressBar/ProgressBar'

import { BacklinksTable } from 'views/Pages/Campaign/tabs/BackLinksTabComponents/BacklinksTable';
import { BackLinkModel } from 'Models/BacklinkModel';

import moment from 'moment';
import TablePdfCSVDownload from 'components/TablePdfCSVDownload';
import ShadowBox from 'components/ShadowBox';
import { BOX_SHADOW } from 'theme/colors';
import {RootState} from "../../../../redux/store";

const BACKLINK_TABLE_ID = 'backlink-result-table'
let intervalTaskId = null;

export default (props) => {
  const dispatch = useDispatch();
  const { campaignId } = useParams()

  const formdata = useSelector((state: RootState) => state.formdata)
  const siteData = formdata.site
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [sendNotification, setSendNotification] = useState(false)
  const [newbacklinks, setNewbacklinks] = useState('')

  const [report, setReport] = useState(null)
  const [overviewTableLinks, setOverviewTableLinks] = useState([])
  const [monitorTableLinks, setMonitorTableLinks] = useState([])

  const [filter_url_from, setFilter_url_from] = useState('')
  const [filter_url_to, setFilter_url_to] = useState('')
  const [filter_anchor, setFilter_anchor] = useState('')

  const progressObjRef = useRef(0);
  const [progressObj, setProgressObj] = useState<ProgressObjType>();
  const [calculating, setCalculating] = useState(false);
  const [nextNotifyDate, setNextNotifyDate] = useState<string>('')
  const newlinks = newbacklinks.split('\n').map((row) => row.trim())


  useEffect(() => {
    if (campaignId) {
      loadBacklinks()
    }
  }, [campaignId])
  useEffect(() => {
    updateLinks(report)
  }, [report])

  useEffect(() => {
    if (calculating) {
      intervalTaskId = setInterval(() => {
        progressObjRef.current = Math.min(progressObjRef.current + 1, 100)
        setProgressObj({
          value: progressObjRef.current,
          msg: 'Adding new backlinks… While you wait, you can leave this screen'
        })
      }, 1000);
    } else {
      handlePauseTask()
    }
  }, [calculating])

  const loadBacklinks = () => {
    dispatch(showLoadingIndicator())
    CallApi({
      url: `/api/backlink/get_research_backlinks/${campaignId}`
    }).then((res) => {
      setSendNotification(res.notify_backlink_url_lost == 1)
      setNextNotifyDate(res.next_backlink_check_date)
      setReport(res)
    }).catch((error) => {
      console.log(error);
    })
    .finally(() => {
      dispatch(hideLoadingIndicator())
    })
  }
  const getFiltered = useCallback((links: BackLinkModel[]) => {
    const filtered = links.filter((link, key) => {
      const filter_from_lower = filter_url_from.toLowerCase()
      const filter_to_lower = filter_url_to.toLowerCase()
      const filter_anchor_lower = filter_anchor.toLowerCase()
      const from_ = link.back_link?.toLowerCase().includes(filter_from_lower)
      const to_ = link.anchor_url?.toLowerCase().includes(filter_to_lower)
      const anchor_ = link.anchor_txt?.toLowerCase().includes(filter_anchor_lower)
      return from_ && to_ && anchor_
    })
    return filtered
  }, [filter_url_from, filter_url_to, filter_anchor])
  const updateLinks = (report) => {
    if (!report) return
    let overLinks = []
    for (let index = 0; index < report.backLinks.length; index++) {
      const element = report.backLinks[index];
      // const isMonitored = report.back_links_monitor.filter(link => {
      //   if (link.back_link == element.back_link && link.anchor_url == element.anchor_url) {
      //     return true
      //   } else {
      //     return false
      //   }
      // })[0]
      // if (!isMonitored) {
      overLinks.push(element)
      // }
    }
    setOverviewTableLinks(overLinks)
    setMonitorTableLinks(report.back_links_monitor)
  }
  const onAddBacklinks = () => {
    for (let index = 0; index < newlinks.length; index++) {
      const element = newlinks[index];
      if (!isURL(element)) {
        toast.error('Invalid URL: ' + element)
        return
      }
    }

    setCalculating(true)
    Axios.post("/api/backlink/add_backlinks", {
      source_urls: newlinks,
      campaign_id: campaignId
    }).then((res) => {
      if (res?.status == 200) {
        toast.success("Added!")
        loadBacklinks()
        setNewbacklinks('')
      } else {
        toast.error("Failed!")
      }
    })
    .catch((error) => {
      console.log('failed to load', error)
    })
    .finally(() => {
      handlePauseTask()
    })
  }


  const onMonitorBackLink = (backlink_to_monitor: BackLinkModel) => {
    Axios.post('/api/backlink/add_backlink_to_monitor', {
      backlink_to_monitor: backlink_to_monitor,
      report_id: campaignId
    }).then((res) => {
      setOverviewTableLinks(overviewTableLinks.filter((link: BackLinkModel) => link.back_link != backlink_to_monitor.back_link || link.anchor_url != backlink_to_monitor.anchor_url))
      setMonitorTableLinks([...monitorTableLinks, backlink_to_monitor])
    })
    .catch((error) => {
      console.log('failed to load', error)
    });
  }
  const COLUMNS = [
    {
      label: 'Backlink URL',
      value: 'back_link',
    },
    {
      label: 'Link URL',
      value: 'anchor_url',
    },
    {
      label: 'Anchor Text',
      value: 'anchor_txt',
    },
    {
      label: 'NoFollow/DoFollow',
      value: 'do_follow',
    },
    {
      label: 'First Time Seen',
      value: 'first_seen_at',
    },
    {
      label: 'Is Lost',
      value: 'is_lost',
    },
  ]

  const updateNotifyLostUrl = (notifiy: boolean) => {

    const date_now = new Date()
    const next_7_days_later = moment(date_now).add(7, 'days').format('l')
    CallApi({
      method: 'post',
      url: '/api/backlink/notify_backlink_lost',
      data: {
        campaignId: campaignId,
        send_email_notification: notifiy ? 1 : 0
      }
    }).then(() => {
      setSendNotification(notifiy)
      if (notifiy) {
        toast.success(`You will receive first email on ${next_7_days_later}`, { duration: 4000 })
        setNextNotifyDate(next_7_days_later)
      }
    })
    .catch((error) => {
      console.log('failed to load', error)
    });
  }
  const handlePauseTask = () => {
    setCalculating(false);
    setProgressObj(undefined);
    if (intervalTaskId) {
      clearInterval(intervalTaskId);
      intervalTaskId = null;
    }
    progressObjRef.current = 0;
  }

  const openDownloadMenu = Boolean(anchorEl);
  const filteredBackLinks = getFiltered(overviewTableLinks)
  return (
    <Box>
      <Container maxWidth="xl">
        <ShadowBox>
          <Typography variant="h5" color='primary.dark'>Monitor Backlinks for Changes</Typography>
          <Typography >
            Monitor Backlinks for Changes is a tool that helps you to keep track of your backlinks and whether they have been lost or not. With this tool, you can receive weekly notifications if any of your backlinks that you added to the monitoring list are lost. It can help you take immediate action and reach out to webmasters to get the backlink restored.
          </Typography>
          <Grid container spacing={2} my={2}>
            <Grid item xs={12} md={10}>
              <TextField id="add_new_backlink" label={`Source URL`} size="small" variant='outlined'
                value={newbacklinks}
                onChange={(e) => setNewbacklinks(e.target.value)}
                multiline
                rows={3}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <Button
                variant='contained'
                onClick={onAddBacklinks}
                color='secondary'
                sx={{
                  marginLeft: '1rem',
                  borderRadius: 10,
                }}>Add</Button>
            </Grid>
          </Grid>

          <ProgressBar
            progressObj={progressObj}
            onPause={handlePauseTask}
          />
        </ShadowBox>
      </Container>
      {(filteredBackLinks.length > 0 && !calculating) && <Box>
        <Container maxWidth="xl" sx={{ mt: 4 }}>
          <Box sx={{
            backgroundColor: '#EAEFED',
            boxShadow: BOX_SHADOW,
            borderRadius: '8px',
            marginTop: 6,
            marginBottom: 6
          }}>
            <Box sx={{
              backgroundColor: '#fff',
              borderRadius: '8px 0',
              p: 2,
            }}>
              <Grid container>
                <Grid item xs={12} md={8}>
                  <Typography variant="h5" color='primary.dark'>Stored Backlinks</Typography>
                </Grid>
                <Grid item xs={12} md={4} textAlign='right' display='flex' alignItems='center' justifyContent={'flex-end'}>
                  <Tooltip title={`You will receive next email on ${nextNotifyDate}`} placement="top" disableHoverListener={!sendNotification}>
                    <FormGroup>
                      <FormControlLabel
                        control={<Switch
                          onChange={(e) => updateNotifyLostUrl(e.target.checked)}
                          checked={sendNotification}
                        />}
                        label="Notify me when URL is lost" />
                    </FormGroup>
                  </Tooltip>
                  <Box>
                    <TablePdfCSVDownload
                      table_id={BACKLINK_TABLE_ID}
                      file_name="Backlink_Monitor"
                      columns={COLUMNS}
                      rows={overviewTableLinks}
                    />
                  </Box>
                </Grid>
              </Grid>

            </Box>
            <Box sx={{ padding: 2 }}>

              <Grid container spacing={2}>
                <Grid item xs={12} md={4} >
                  <TextField placeholder="Enter full or partial URL..." variant="outlined"
                    label="Filter by URL From"
                    value={filter_url_from}
                    onChange={(e) => { setFilter_url_from(e.target.value) }}
                    fullWidth
                    sx={{
                      '& input': {
                        backgroundColor: 'white',
                        borderRadius: '4px 0 0 4px'
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4} >
                  <TextField placeholder="Enter full or partial URL..." variant="outlined"
                    label="Filter by URL To"
                    value={filter_url_to}
                    onChange={(e) => { setFilter_url_to(e.target.value) }}
                    fullWidth
                    sx={{
                      '& input': {
                        backgroundColor: 'white',
                        borderRadius: '4px 0 0 4px'
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4} >
                  <TextField placeholder="Enter anchor text..." variant="outlined"
                    label="Filter by Anchor"
                    value={filter_anchor}
                    onChange={(e) => { setFilter_anchor(e.target.value) }}
                    fullWidth
                    sx={{
                      '& input': {
                        backgroundColor: 'white',
                        borderRadius: '4px 0 0 4px'
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>

        </Container>

        <BacklinksTable
          backLinks={filteredBackLinks}
        />
      </Box>}
    </Box >
  )
}