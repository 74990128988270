
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import AsyncSelect from "react-select/async";
import axios from "axios";




import { Box } from "@mui/material";
import KeywordsResearchResult from "views/Pages/Campaign/tabs/KeywordsResearch/KeywordsResearchResult";
import {RootState} from "../../../redux/store";

let intervalTaskId = null;
const TIME_INTERVAL = 3000
type PropTypes = {
  isActive: boolean,
  campaignId: string
}
export default function ({ isActive, campaignId }: PropTypes) {
  const dispatch = useDispatch();
  const formdata = useSelector((state: RootState) => state.formdata)

  const [research_id, setResearch_id] = useState(0)
  const [researchHistory, setResearchHistory] = useState([])
  const [currentReport, setCurrentReport] = useState(null);

  const [calculating, setCalculating] = useState(false);

  useEffect(() => {
    if (campaignId) {
      loadData()
    }
  }, [campaignId, research_id])

  const loadData = () => {
    loadResearchResult()
    loadResearchHistoryForCampaign()
  }
  const loadResearchHistoryForCampaign = () => {
    axios.get(`/api/keyword_research/history/campaign/${campaignId}`).then((res) => {
      setResearchHistory(res.data)
    })
  }
  const loadResearchResult = () => {
    axios
      .get(`/api/keyword_research/get_keyword_result/${campaignId}/${research_id}`)
      .then((response) => {
        if (response && response.status === 200) {
          let report = response.data?.report;
          setCurrentReport(report)
        } else {
        }
      })
      .catch((error) => {
        console.log('failed to load', error)
      });
  }

  const onDeleteAllKeywordData = () => {
    if (currentReport) {
      axios
        .delete(`/api/keyword_research/delete_all_keywords_data/${campaignId}`)
        .then((res) => {
          loadResearchResult()
        })
    }
  }
  const onChangeResearchId = (research_id: number) => {
    setResearch_id(research_id)
  }
  return (
    <Box>
      {currentReport && (
        <KeywordsResearchResult
          onChangeResearchId={onChangeResearchId}
          report={currentReport}
          campaignId={Number(campaignId)}
          researchHistory={researchHistory}
          calculating={calculating}
          id='keyword_research'
          onDeleteAllKeywordData={onDeleteAllKeywordData}
        />
      )}
    </Box>
  );
}
