import React, { useState, useEffect } from 'react';
import { gapi, loadAuth2 } from 'gapi-script'
import { Alert, Box, Button, Container, FormControl, Grid, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { GOOGLE_OAUTH_SCOPE } from 'utils/search-console-helper';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { GoogleButton } from 'components/GoogleButton';
import { RootState } from 'redux/store';
import { useSelector } from 'react-redux'
import { EventEmitter } from 'utils/Event';
import { GoogleLogin } from 'components/GoogleLogin';

type GoogleLoginButtonPropType = {
    options: {
        value: string,
        description: string
    }[],
    onChangeOption: (option: string) => void,
}
export const TopLevelPageHeader = (props: GoogleLoginButtonPropType) => {
    const [user, setUser] = useState(null);
    const [selectedOption, setSelectedOption] = useState('')

    useEffect(() => {
        EventEmitter.subscribe('GOOGLE_USER_UPDATE', (googleUser) => {
            if (googleUser) {
                const name = googleUser.getBasicProfile().getName();
                const profileImg = googleUser.getBasicProfile().getImageUrl();
                setUser({
                    name: name,
                    profileImg: profileImg,
                });
            } else {
                setUser(null)
            }
        })
    }, [])

    const handleChangeOption = (event: SelectChangeEvent) => {
        setSelectedOption(event.target.value as string);
        props.onChangeOption(event.target.value as string)
    };
    return (
        <Container maxWidth="xl" >
            <Box
                sx={{
                    backgroundColor: 'white', p: 2,
                }}>
                <Typography variant="h5" color='primary.dark'>
                    Keyword Wizard
                </Typography>
                <Grid container>
                    <Grid item xs={12} md={8}>
                        <Typography variant="body1" color='primary.dark'>
                            The Keyword Wizard is an advanced tool tailored for top-level keyword research, catering to diverse user needs. It offers two distinct options to its users. First, for those who have a target keyword in mind but do not possess an established website, and second, for those who already have a target URL and a well-established website. This dual approach ensures that both budding online ventures and seasoned webmasters can effectively harness the power of keyword research to optimize their content and increase visibility.
                        </Typography>
                    </Grid>
                </Grid>

                <Box mt={3} sx={{
                    background: '#F2F4F7',
                    borderRadius: '4px',
                    padding: 1,
                }}>
                    <Grid container alignItems='center'>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Select your Scenario</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedOption}
                                    label="Select your Scenario"
                                    onChange={handleChangeOption}
                                >
                                    {
                                        props.options.map((option, key) => {
                                            return (
                                                <MenuItem
                                                    key={key}
                                                    value={option.value}>{option.description}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <GoogleLogin />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );

}