import SiteListPage from 'views/SiteListPage';
import KeywordsResearch from 'views/Pages/Campaign/tabs/KeywordsResearchTab';
import SupportingKeywordResearch from 'views/Pages/Campaign/tabs/SupportingKeywordResearchTab';
import AdminPage from 'views/admin/index';
import Profile from 'views/Profile';
import Pages from 'views/Pages';
import Campaign from 'views/Pages/Campaign/Campaign';
import OpenAITest from 'views/Pages/OpenAITest';

export const privateRoutes = [
  {
    path: '/',
    exact: true,
    component: SiteListPage
  },
  {
    path: '/kw-research',
    component: KeywordsResearch
  },
  {
    path: '/supporting-kw-research',
    component: SupportingKeywordResearch
  },
  {
    path: '/profile',
    component: Profile
  },
  {
    path: '/site/:siteId',
    exact: true,
    component: Pages
  },
  {
    path: '/site/:siteId/campaign/:campaignId',
    component: Campaign,
  },
  {
    path: '/admin',
    component: AdminPage
  },
  {
    path: '/openai_test',
    component: OpenAITest
  },
]