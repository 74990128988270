import { Box, Container } from "@mui/material";
import { useState, useEffect } from "react";
import VoltageSearchForm from "./SiteOnlyVoltage/VoltageSearchForm";
import VoltageTable from "./SiteOnlyVoltage/VoltageTable";
import { useDispatch, useSelector } from "react-redux";
import { LanguageModel } from "Models/LanguageModel";
import LanguageData from 'utils/languages.json'
import ShadowBox from "components/ShadowBox";
import {RootState} from "../../../../../redux/store";

export default function SiteOnlyVoltageComponent(props) {

    const formdata = useSelector((state: RootState) => state.formdata)
    const siteData = formdata.site

    const [resultData, setResultData] = useState(null)
    const [language, setLanguage] = useState<LanguageModel>(null)
    const [country, setCountry] = useState(2840)
    const [domain, setDomain] = useState('')

    useEffect(() => {
        if (siteData && siteData?.default_loc_lang_enabled) {
            if (siteData.location_code) setCountry(siteData.location_code)
            const lang = LanguageData.find(l => l.language_code == (siteData.language_code))
            if (lang) {
                setLanguage(lang)
            }
        }
    }, [siteData])

    return <>
        <Container maxWidth="xl">
            <ShadowBox>
                <VoltageSearchForm onData={(v) => setResultData(v)}
                    language={language}
                    onChangeLanguage={lang => {
                        setLanguage(lang)
                    }}
                    onChangeLocation={loc => {
                        setCountry(loc)
                    }}
                    onChangeDomain={value => setDomain(value)}
                />
            </ShadowBox>
        </Container>
        <VoltageTable data={resultData} language={language} country={country} domain={domain} />
    </>
}