import { Typography, Box } from "@mui/material";

const TabBadge = ({ label, count }) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 2,
      }}
    >
      <Typography sx={{ fontSize: "16px", color: "#014751" }}>
        {label}
      </Typography>
      <Box
        sx={{
          height: "24px",
          borderRadius: "12px",
          backgroundColor: "#FFEDDD",
          px: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{ color: "#FF5700", fontSize: "14px", fontWeight: 700 }}
        >
          {count}
        </Typography>
      </Box>
    </Box>
  );
};

export default TabBadge;
