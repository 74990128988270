import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, useNavigate, Navigate, BrowserRouter, Routes, Outlet } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import Header from './views/common/Header';

import FullScreenLoading from './views/FullScreenLoading';

import 'assets/scss/App.scss';
import { useIsAuthenticated } from 'utils';
import Axios from 'axios';
import { Box, Container } from '@mui/material';

import {
  showLoadingIndicator, hideLoadingIndicator
} from 'redux/reducers/appstate'
import { fetchUserThunk } from 'redux/reducers/user';
import { RootState } from 'redux/store';

function App(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.user);
  const appstate = useSelector((state: RootState) => state.appstate);
  const isAuthenticated = useIsAuthenticated()
  const [authHeaderSet, setAuthHeaderSet] = useState(false)

  useEffect(() => {

    dispatch(hideLoadingIndicator())
    if (isAuthenticated) {
      dispatch(fetchUserThunk())
    }
  }, [])
  useEffect(() => {
    if (isAuthenticated) {
      Axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
    } else {
      Axios.defaults.headers.common['Authorization'] = ``;
    }
    setAuthHeaderSet(true)
  }, [isAuthenticated])
  if (!authHeaderSet) {
    return null
  }
  return (
    <Box sx={{
      backgroundColor: '#E7E9E8'
    }}
      pb={6}>
      {appstate.loading && <FullScreenLoading />}
      <Header />
      <Box sx={{
        mt: {
          xs: 12,
          md: 12
        },
      }}>
        <Outlet />
      </Box>

      <Toaster
        position='top-right'
        toastOptions={{
          style: {
            maxWidth: 500
          }
        }}
      />
    </Box>
  );
}

export default App;
