import React, { useState, useEffect } from 'react';
import { gapi, loadAuth2 } from 'gapi-script'
import { Alert, Box, Button, Typography } from '@mui/material';
import { GOOGLE_OAUTH_SCOPE } from 'utils/search-console-helper';
import { RootState } from 'redux/store';
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux';
import { EventEmitter } from 'utils/Event';
import { updateGoogleUser } from 'redux/reducers/user';

type GoogleButtonPropType = {

}
export const GoogleButton = (props: GoogleButtonPropType) => {
    const dispatch = useDispatch()
    const [googleUser, setGoogleUser] = useState(null)

    useEffect(() => {
        const setAuth2 = async () => {
            const auth2 = await loadAuth2(gapi, process.env.REACT_APP_GOOGLE_CLIENT_ID, GOOGLE_OAUTH_SCOPE)
            if (auth2.isSignedIn.get()) {
                updateUser(auth2.currentUser.get())
            }
            attachSignin(document.getElementsByClassName('googleLoginButton'), auth2);
        }
        setAuth2();
    }, []);

    // useEffect(() => {
    //     if (!user) {
    //         const setAuth2 = async () => {
    //             const auth2 = await loadAuth2(gapi, process.env.REACT_APP_GOOGLE_CLIENT_ID, GOOGLE_OAUTH_SCOPE)
    //             attachSignin(document.getElementsByClassName('googleLoginButton'), auth2);
    //         }
    //         setAuth2();
    //     }
    // }, [user])

    const updateUser = (currentUser) => {
        setGoogleUser(currentUser)
        dispatch(updateGoogleUser({
            name: currentUser.getBasicProfile().getName(),
            profileImg: currentUser.getBasicProfile().getImageUrl(),
            ca: currentUser.Ca
        }))
        EventEmitter.dispatch('GOOGLE_USER_UPDATE', currentUser)
    };

    const attachSignin = (elements, auth2) => {
        for (const key in elements) {
            auth2.attachClickHandler(elements[key], {},
                (gu) => {
                    updateUser(gu);
                }, (error) => {
                    console.log(JSON.stringify(error))
                });
        }
    };

    const signOut = () => {
        setGoogleUser(null)
        const auth2 = gapi.auth2.getAuthInstance();
        dispatch(updateGoogleUser(null))

        auth2.signOut().then(() => {
            console.log('User signed out.');
        });
        EventEmitter.dispatch('GOOGLE_USER_UPDATE', null)
    }

    return (
        <Box>
            <Box flexDirection='row' display='flex'>
                <Button variant="contained" className="googleLoginButton" sx={{ marginRight: 2, display: googleUser ? 'block' : 'none' }} >Change Account</Button>
                <Button variant="contained" onClick={signOut} color="secondary" sx={{ color: 'white', display: googleUser ? 'block' : 'none' }}>Disconnect</Button>
                <Button variant="contained" className="googleLoginButton" sx={{ color: 'white', display: !googleUser ? 'block' : 'none' }}>Connect Google</Button>
            </Box>
        </Box>
    );

}