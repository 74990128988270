import * as React from 'react';
import Slider, { SliderThumb, SliderValueLabelProps } from '@mui/material/Slider';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

export const CustomSlider = styled(Slider)(({ theme }) => ({
    '& .MuiSlider-valueLabel': {
        fontWeight: 'normal',
        top: 50,
        backgroundColor: 'unset',
        color: theme.palette.primary,
        '&:before': {
            display: 'none',
        },
        '& *': {
            background: 'transparent',
            color: theme.palette.primary.dark,
        },
    },
}));

