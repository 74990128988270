import { useEffect, useMemo, useState } from "react";
import {
  Typography,
  MenuItem,
  Button,
  TextField,
  Select,
  FormControl,
  Grid,
  IconButton,
  Box,
} from "@mui/material";

import GoogleIcon from "assets/images/google-icon.svg";
import { EventEmitter } from "utils/Event";
import CountrySelect from "components/CountrySelect";
import PlusIcon from "assets/images/plus-icon.svg";
import CrossIcon from "assets/images/cross-icon.svg";
import LanguageSelect from "components/LanguageSelect";
import BrandedKeywords from "./BrandedKeywords";
import GoogleButton from "./GoogleButton";
import { toast } from "react-hot-toast";
import validator from "validator";

const parseContent = (content: string) => {
  const matches =
    content
      .slice(0)
      .match(/<li>(.*?)<\/li>/g)
      ?.map((match) => match.replace(/<\/?li>/g, "")) || [];
  return matches.filter((match) => match && match !== "<br>");
};

const MainInputs = ({ curScenario, onSubmit }) => {
  const [location, setLocation] = useState(2840);
  const [editorContent, setEditorContent] = useState("<ol><li></li></ol>");
  const [language, setLanguage] = useState({
    language_name: "English",
    language_code: "en",
  });
  const [domain, setDomain] = useState("");
  const [user, setUser] = useState(null);
  const [competitors, setCompetitors] = useState([{ type: "site", url: "" }]);

  const brandedKeywords = useMemo(
    () => parseContent(editorContent),
    [editorContent]
  );

  const handleDelete = (index) => {
    const newList = competitors.filter((_, i) => i !== index);
    if (!newList.length) newList.push({ type: "site", url: "" });

    setCompetitors(newList);
  };

  const handleAdd = () => {
    if (
      competitors.length >= 10 ||
      competitors.some((competitor) => !competitor.url)
    )
      return;
    setCompetitors((competitors) =>
      competitors.concat({ type: "site", url: "" })
    );
  };

  useEffect(() => {
    EventEmitter.subscribe("GOOGLE_USER_UPDATE", (googleUser) => {
      if (googleUser?.Ca) {
        const name = googleUser.getBasicProfile().getName();
        const profileImg = googleUser.getBasicProfile().getImageUrl();
        setUser({
          name: name,
          profileImg: profileImg,
        });
      } else {
        setUser(null);
      }
    });
  }, []);

  useEffect(() => {
    setLocation(2840);
    setLanguage({ language_name: "English", language_code: "en" });
    setDomain(null);
    setEditorContent("<ol><li></li></ol>");
    setCompetitors([{ type: "site", url: "" }]);
  }, [curScenario]);

  return (
    <Grid container spacing={2} sx={{ padding: 2 }}>
      {curScenario === 1 ? (
        <Grid item xs={12}>
          <Typography
            variant="h6"
            align="left"
            gutterBottom
            sx={{ fontSize: "20px", fontWeight: 800, color: "#014751" }}
          >
            Connect Google account
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              height: "72px",
              backgroundColor: "white",
              border: "1px solid #CCCCCC",
              borderRadius: "5px",
              paddingX: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <img src={GoogleIcon} alt="Google Icon" />
              <span style={{ marginLeft: "8px" }}>
                {user?.name || "No account selected"}
              </span>
            </Box>
            <GoogleButton />
          </Box>
        </Grid>
      ) : null}
      <Grid item container spacing={2} mt={curScenario === 1 ? 2 : 0}>
        <Grid item xs={curScenario === 1 ? 6 : 4}>
          <Typography
            variant="h6"
            align="left"
            gutterBottom
            sx={{ fontSize: "20px", fontWeight: 800, color: "#014751" }}
          >
            Select Location
          </Typography>
          <CountrySelect
            isMulti={false}
            value={location}
            placeholder="Target Location"
            onChange={(value) => setLocation(value)}
          />
        </Grid>
        <Grid item xs={curScenario === 1 ? 6 : 4}>
          <Typography
            variant="h6"
            align="left"
            gutterBottom
            sx={{ fontSize: "20px", fontWeight: 800, color: "#014751" }}
          >
            Select Language
          </Typography>
          <LanguageSelect
            isMulti={false}
            value={language}
            onChange={(value) => setLanguage(value)}
          />
        </Grid>
        {curScenario === 2 ? (
          <Grid item xs={4}>
            <Typography
              variant="h6"
              align="left"
              gutterBottom
              sx={{ fontSize: "20px", fontWeight: 800, color: "#014751" }}
            >
              Add Domain Name
            </Typography>
            <TextField
              id="term"
              label="Domain name"
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => setDomain(e.target.value)}
              value={domain}
            />
          </Grid>
        ) : null}
      </Grid>
      {curScenario === 1 ? (
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontSize: "20px", fontWeight: 800, color: "#014751" }}
              >
                Exclude branded keywords
              </Typography>
              <Box
                sx={{
                  height: "27px",
                  backgroundColor: "white",
                  border: "1px solid #CCCCCC",
                  borderRadius: "100px",
                  width: "56px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: "1rem",
                }}
              >
                <Typography
                  sx={{ fontSize: "16px", fontWeight: 500, color: "#014751" }}
                >
                  {` ${brandedKeywords.length || 0}/10`}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                border: "1px solid #014751",
                height: "42px",
                borderRadius: "5px",
                display: "flex",
                alignItems: "center",
                marginLeft: "auto", // aligns to the end of the outer box
              }}
            >
              <Typography
                sx={{
                  m: 2,
                  fontSize: "15px",
                  fontWeight: 700,
                  color: "#014751",
                  textAlign: "center",
                }}
              >
                Import from
              </Typography>
            </Box>
          </Box>
          <BrandedKeywords
            editorContent={editorContent}
            setEditorContent={setEditorContent}
          />
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{ fontSize: "20px", fontWeight: 800, color: "#014751" }}
            >
              Enter upto 10 competitors
            </Typography>
          </Box>
        </Box>
        <Box>
          {competitors.map((competitor, index) => {
            return (
              <Box
                key={index}
                sx={{ display: "flex", alignItems: "center", gap: 1, mb: 2 }}
              >
                <TextField
                  id={`_term_${index + 1}`}
                  label="Domain name"
                  variant="outlined"
                  size="medium"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={() => handleDelete(index)}>
                        <img src={CrossIcon} alt="Cross Icon" />
                      </IconButton>
                    ),
                  }}
                  onChange={(e) => {
                    const newList = [...competitors];
                    newList[index].url = e.target.value;
                    setCompetitors(newList);
                  }}
                  value={competitor.url}
                />
                <FormControl variant="outlined" sx={{ minWidth: 300 }}>
                  <Select
                    value={competitor.type}
                    sx={{
                      "& .MuiSelect-select": {
                        backgroundColor: "white !important",
                        border: "1px solid #9E9E9E",
                      },
                    }}
                    onChange={(e) => {
                      const newList = [...competitors];
                      newList[index].type = e.target.value;
                      setCompetitors(newList);
                    }}
                  >
                    <MenuItem value="site">Domain</MenuItem>
                    <MenuItem value="page">Page</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            );
          })}
          <Box
            sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={handleAdd}
          >
            <img
              src={PlusIcon}
              alt="Plus Icon"
              style={{ marginRight: "8px" }}
            />
            <Typography
              sx={{ fontSize: "16px", fontWeight: 500, color: "#014751" }}
            >
              Add a new site
            </Typography>
          </Box>
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#FF5700",
              height: "53px",
              borderRadius: "100px",
              mt: 3,
              py: 2,
              px: 3,
            }}
            onClick={() => {
              if (curScenario === 1 && !user) {
                return toast.error("Please connect google account");
              }
              if (curScenario === 2) {
                if (!domain) {
                  return toast.error("Please enter domain");
                }
                if (!validator.isFQDN(domain)) {
                  return toast.error("Please enter valid domain");
                }
              }

              if (!location) {
                return toast.error("Please select a location");
              }
              if (!language?.language_code) {
                return toast.error("Please select a language");
              }
              if (!competitors.filter((competitor) => competitor.url).length) {
                return toast.error(
                  "Please enter at least one competitor url/domain"
                );
              }
              if (
                competitors.some(
                  (competitor) =>
                    !validator.isFQDN(competitor.url) &&
                    !validator.isURL(competitor.url)
                )
              ) {
                return toast.error("Please enter valid competitor url/domain");
              }

              onSubmit({
                competitors_sites: competitors,
                branded_keywords: brandedKeywords,
                language_code: language.language_code,
                location_code: location,
                target_site: domain,
              });
            }}
          >
            <Typography
              sx={{ color: "white", fontSize: "20px", fontWeight: 700 }}
            >
              Find opportunities
            </Typography>
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MainInputs;
