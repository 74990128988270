import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, Switch, TextField } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import CountrySelect from "components/CountrySelect";
import { GoogleLogin } from "components/GoogleLogin";
import { getCountryCode_ISO3, getSitesList, loadGAPIClient, searchAnalyticsForSearchConsole } from "utils/search-console-helper";
import moment from "moment";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { gapi, loadAuth2, loadClientAuth2 } from 'gapi-script';
import toast from "react-hot-toast";
import Axios from "axios";
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'

import {
    showLoadingIndicator, hideLoadingIndicator
} from 'redux/reducers/appstate'
import DateRageSelect, { DateRangeType, getOptionDate } from "views/Pages/Campaign/tabs/TopLevelPageComponents/DateRageSelect";
import { checkDomainMatch, getDomain, getHttpsUrl } from "utils";
import LanguageSelect from "components/LanguageSelect";
import ProgressBar, { ProgressObjType } from "components/ProgressBar/ProgressBar";
import { RootState } from "redux/store";
import { EventEmitter } from "utils/Event";
import { LanguageModel } from "Models/LanguageModel";
import DomainSelector from "components/DomainSelector";

type SearchFormType = {
    onData: (data: any) => void,
    language: LanguageModel,
    onChangeLanguage: (lang: LanguageModel) => void,
    onChangeLocation: (loc: number) => void,
    onChangeDomain: (val: string) => void,
}
let intervalTaskId = null;

var google_console_data = []
var google_console_data_aggregated = []
export default function ({ onData, language, onChangeLanguage, onChangeLocation, onChangeDomain }: SearchFormType) {
    const dispatch = useDispatch();
    const formdata = useSelector((state: RootState) => state.formdata)
    const siteData = formdata.site


    const googleUser = useSelector((state: RootState) => state.user.googleUser)
    const [country, setCountry] = useState(2840)
    const [brandedTerms, setBrandedTerms] = useState('')

    const [loadedGoogleConsoleDataAggregated, setLoadedGoogleConsoleDataAggregated] = useState(false)
    // filtering

    const [enabledCountry, setEnabledCountry] = useState(true)
    const [enabledBrand, setEnabledBrand] = useState(false)
    const [dateFilterData, setDateFilterData] = useState<DateRangeType>({
        value: 'last_3_months'
    })
    const [googleSiteUrl, setGoogleSiteUrl] = useState('')

    // progressing

    const progressObjRef = useRef(0);
    const [progressObj, setProgressObj] = useState<ProgressObjType>();
    const [calculating, setCalculating] = useState(false);

    useEffect(() => {
        if (calculating) {
            intervalTaskId = setInterval(() => {
                progressObjRef.current = Math.min(progressObjRef.current + 1, 100)
                setProgressObj({
                    value: progressObjRef.current,
                    msg: 'Processing… While you wait, you can leave this screen'
                })
            }, 1000);
        } else {
            setProgressObj(undefined)
        }
    }, [calculating])
    const handlePause = () => {
        setCalculating(false);
        setProgressObj(undefined);
        if (intervalTaskId) {
            clearInterval(intervalTaskId);
            intervalTaskId = null;
        }
        progressObjRef.current = 0;
    }

    const calcForKeywordCannibalization = () => {

        // console.log('zhao', google_console_data_aggregated)
        let data = google_console_data_aggregated.map((d, index) => {
            return {
                ...d,
                keyword: d.keys[0],
                clicks: d.clicks,
                page: d.keys[1],
                ctr: Number(Number(d.ctr * 100).toFixed(2)),
                position: Number(Number(d.position).toFixed(2)),
                index: index
            }
        })

        data.sort((a, b) => {    
            if(a.keyword < b.keyword) return -1;
    
            if(a.keyword > b.keyword) return 1;
    
            return Number(b.impressions) - Number(a.impressions);
        })


        let uniqueKeywords = data.map(d => d.keyword)
        uniqueKeywords = [...new Set(uniqueKeywords)]

        uniqueKeywords.forEach((keyword) => {
            let records = data.filter(d => keyword == d.keyword)

            let count = records.length
            if (count == 1) {
                return
            }

            let sum = records.map(el=>el.impressions).reduce((acc,value)=>acc+value,0);

            let avg = sum / count

            records.forEach((el,ind2)=>{

                if(ind2 === 0) return;

                let { impressions, index } = el;

                let diff = (avg - impressions) / avg;
                data[index]['cannibalizedKeywords'] = records;

                /**highlight with red above avg */
                if(impressions > avg){
                    data[index]['keywordCannibalizationText'] = "high"
                    return;
                }
                /**highlight with orange diff <= 25% */
                if(diff <= 0.25) {
                    data[index]['keywordCannibalizationText'] = "medium"
                }else{

                /**highlight with green diff > 25% */
                    data[index]['keywordCannibalizationText'] = "low"
                }
            })

        })

        return data
    }


    const targetUrl = siteData.base_url
    useEffect(() => {
        if (loadedGoogleConsoleDataAggregated) {
            onData(calcForKeywordCannibalization())
        }
    }, [loadedGoogleConsoleDataAggregated])
    const getConsoleDataAggregated = (startRow = 0) => {

        const rowLimit = 25000
        const range = getOptionDate(dateFilterData)
        const endDate = range.endDate
        const startDate = range.startDate

        let filterList = []
        if (enabledCountry) {
            filterList.push({
                "dimension": "COUNTRY",
                "expression": getCountryCode_ISO3(country)
            })
        }
        if (enabledBrand) {
            brandedTerms.split(',').map((keyword) => {
                filterList.push({
                    "dimension": "QUERY",
                    "operator": "NOT_EQUALS",
                    "expression": keyword
                })
            })
        }

        const queryParams = {
            "siteUrl": googleSiteUrl,
            "resource": {
                "startDate": startDate,
                "endDate": endDate,
                "dimensionFilterGroups": [
                    {
                        "filters": filterList
                    }
                ],
                "dimensions": [
                    "QUERY",
                    "PAGE"
                ],
                "rowLimit": rowLimit,
                "startRow": startRow,
            }
        }

        gapi.client.webmasters.searchanalytics.query(queryParams)
            .then(function (response) {
                if (response.status == 200) {
                    if (response.result?.rows) {
                        google_console_data_aggregated = [...google_console_data_aggregated, ...response.result?.rows]
                        if (response.result?.rows.length == rowLimit) {
                            getConsoleDataAggregated(startRow + rowLimit + 1)
                        } else {
                            // console.info('=== google_console_data_aggregated ===', JSON.stringify(google_console_data_aggregated, null, 4))
                            setLoadedGoogleConsoleDataAggregated(true)
                            handlePause()
                        }
                    } else {
                        handlePause()
                    }
                    handlePause()

                } else {
                    handlePause()
                    toast.error('Not found history')
                }
            },
                function (err) {
                    handlePause()

                    console.error("Execute error", err);
                    toast.error('Failed to get analystic data')
                });
    }

    const onStart = () => {
        setCalculating(true)
        setLoadedGoogleConsoleDataAggregated(false)
        google_console_data_aggregated = []
        getConsoleDataAggregated()
    }
    const canStart = googleUser && googleSiteUrl


    const onApplyDateRange = (data: DateRangeType) => {
        setDateFilterData(data)
    }
    const onChangeGoogleSite = (event: SelectChangeEvent) => {
        setGoogleSiteUrl(event.target.value);
        onChangeDomain(event.target.value)
    };
    return <Box>
        <Grid container spacing={2}>
            <Grid item xs={12} md={6}>

                <Box mb={3}>
                    <DomainSelector
                        value={googleSiteUrl}
                        onChangeDomain={(new_domain) => {
                            setGoogleSiteUrl(new_domain);
                            onChangeDomain(new_domain)
                        }}
                        base_domain={siteData?.base_url}
                    />
                </Box>
                <Box mb={3}>

                    <CountrySelect isMulti={false} value={country} onChange={(value) => {
                        onChangeLocation(value)
                        setCountry(value)
                    }}
                        placeholder='Target Country'
                    />
                    <FormGroup>
                        <FormControlLabel control={<Switch checked={enabledCountry} onChange={(e) => setEnabledCountry(e.target.checked)} />}
                            label={enabledCountry ? "Disable" : "Enable"} />
                    </FormGroup>

                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box mb={3}>
                    <LanguageSelect isMulti={false} value={language} onChange={(value) => {
                        onChangeLanguage(value)
                    }} />
                </Box>
                <Box mb={3}>
                    <TextField id='term' label='Branded keywords' variant="outlined" size='small' fullWidth
                        onChange={(e) => setBrandedTerms(e.target.value)}
                        value={brandedTerms} />

                    <FormGroup>
                        <FormControlLabel control={<Switch checked={enabledBrand} onChange={(e) => setEnabledBrand(e.target.checked)} />}
                            label={enabledBrand ? "Disable" : "Enable"} />
                    </FormGroup>
                </Box>

            </Grid>
        </Grid>
        <Box mb={3}>
            <DateRageSelect onApplyDateRange={(data: DateRangeType) => onApplyDateRange(data)} dateFilterData={dateFilterData} />
        </Box>
        <Box mb={3}>
            <Grid container>
                <Grid item xs={12} md={4}>
                    <Button variant="contained" disabled={!canStart} onClick={onStart}
                        color='secondary'
                        sx={{
                            borderRadius: 10,
                        }}
                    >Start</Button>
                </Grid>
                <Grid item xs={12} md={8}>
                    <ProgressBar
                        progressObj={progressObj}
                        onPause={handlePause}
                    />
                </Grid>
            </Grid>
        </Box>
    </Box>
}