import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Modal,
  Form,
} from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import { toast } from "react-hot-toast";
import validator from 'validator'


import {
  showLoadingIndicator, hideLoadingIndicator
} from 'redux/reducers/appstate'
import CountrySelect from 'components/CountrySelect';
import { Box, Button, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Grid, Typography } from '@mui/material';
import { SiteModel } from 'Models/SiteModel';
import { CallApi } from 'utils';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { RootState } from 'redux/store';
import { KeywordResearchModel } from 'Models/KeywordResearchModel';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { addCampaignHistory } from 'redux/reducers/appdata';
import LanguageSelect from 'components/LanguageSelect';
import { saveSiteAction } from 'redux/reducers/formdata';
import { BOX_SHADOW } from 'theme/colors';

function SiteListPage() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [sites, setSites] = useState([])
  const [country, setCountry] = useState(null)
  const [language, setLanguage] = useState(null);
  const [defaultLocLangEnabled, setDefaultLocLangEnabled] = useState(true)
  const langs = useSelector((state : RootState) => state.appdata.languages)

  const campaignHistory = useSelector((state: RootState) => state.appdata.campaignHistory) || []
  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);
  const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLElement>(null);

  // delete 
  const [showDelDlg, setShowDelDlg] = useState(false)
  const [siteIdToDelete, setSiteIdToDelete] = useState()

  const [level, setLevel] = useState('domain')

  useEffect(() => {
    loadSites()
  }, [])
  const loadSites = () => {
    dispatch(showLoadingIndicator());

    CallApi({
      url: "/api/sites/all",
      method: 'get'
    }).then((response) => {
      setSites(response.sites)
    }).finally(() => {
      dispatch(hideLoadingIndicator())
    });
  }
  useEffect(() => {
    reset();
  }, [showCreateProjectModal])



  const createProjectFormSchema = Yup.object().shape({
    name: Yup.string().required('Please input the project name'),
    url: Yup.string().required('Please input the domain'),
  })
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      url: '',
    },
    resolver: yupResolver(createProjectFormSchema)
  })



  const toggleShowCreateModal = () => {
    if (showCreateProjectModal) {
      setShowCreateProjectModal(false);
    } else {
      setShowCreateProjectModal(true);
    }
  }

  const createProjectAction = async (data) => {
    const formdata = {
      url: data.url,
      name: data.name,
      level: level,
      location_code: country,
      language_code: language ? language.language_code : 'en',
      default_loc_lang_enabled: defaultLocLangEnabled ? 1 : 0
    }
    if (level == 'domain') {
      if (!validator.isFQDN(data.url)) {
        toast.error('Please enter valid url'); return
      }
    }
    if (level == 'page') {
      if (!validator.isURL(data.url)) {
        toast.error('Please enter valid domain'); return
      }
    }
    try {
      dispatch(showLoadingIndicator());
      let response: any = await axios.post('/api/sites/create', formdata)
      if (response && response.status == 200) {
        toast.success('Project created.')
        setShowCreateProjectModal(false);
        loadSites()
      } else {
        toast.error(response.data?.msg)
      }

    } catch (e) {
      toast.error(e.data?.msg)
      console.log(e)
    } finally {
      dispatch(hideLoadingIndicator());
    }
  }
  const deleteProjectAction = () => {
    CallApi({
      url: `/api/sites/delete/${siteIdToDelete}`,
      method: 'DELETE'
    }).then(() => {
      loadSites()
    }).finally(() => {
      setAnchorElMenu(null);
      setShowDelDlg(false)
    })
  }



  const createProjectModalRender = () => {
    return (
      <Modal
        show={showCreateProjectModal}
        onHide={toggleShowCreateModal}
        backdrop="static"
        keyboard={false}
        className="options-modal"
        size="lg"
      >
        <Form onSubmit={handleSubmit(createProjectAction)}>
          <Modal.Header closeButton>
            <Modal.Title>
              Create Project
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>

                <Form.Group>
                  <Form.Label>Project Name</Form.Label>
                  <Form.Control type="text" placeholder='Please input the name' {...register('name')} ></Form.Control>
                  {errors.name && <p className="form-field-error">{errors.name?.message}</p>}
                </Form.Group>
              </Grid>
              <Grid item xs={12} md={6}>

                <Form.Group>
                  <Form.Label>Domain</Form.Label>
                  <Form.Control type="text" placeholder='Please input the domain' {...register('url')} ></Form.Control>
                  {errors.url && <p className="form-field-error">{errors.url?.message}</p>}
                </Form.Group>
              </Grid>
              <Grid item xs={12} md={6}>

                <Form.Group>
                  <CountrySelect
                    isMulti={false}
                    value={country}
                    onChange={(v) => setCountry(v)}
                    placeholder="Choose Location"
                  />
                </Form.Group>
              </Grid>
              <Grid item xs={12} md={6}>

                <Form.Group>
                  <LanguageSelect
                    value={language}
                    onChange={(item) => {
                      setLanguage(item);
                    }}
                    isMulti={false}
                    fullWidth={true}
                    placeholder="Choose Language"
                  />
                </Form.Group>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormGroup>
                  <FormControlLabel control={<Checkbox
                    color="secondary"
                    checked={level == 'domain'}
                    onClick={() => setLevel('domain')}
                  />} label="Domain" />
                  <FormControlLabel control={<Checkbox
                    color="secondary"
                    checked={level == 'page'}
                    onClick={() => setLevel('page')}
                  />} label="Page" />
                </FormGroup>
              </Grid>
              <Grid item xs={12} md={6}>

                <FormGroup>
                  <FormControlLabel control={<Checkbox
                    color="secondary"
                    checked={defaultLocLangEnabled}
                    onClick={() => setDefaultLocLangEnabled(!defaultLocLangEnabled)}
                  />}
                    label="Default Location & Language"
                    labelPlacement="start"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Modal.Body>
          <Modal.Footer>
            <Button type="submit" className="main-button">Create Project</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }


  const enterSite = (site: SiteModel) => {
    dispatch(saveSiteAction(site))
    navigate(`/site/${site.id}`)
  }
  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElMenu(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorElMenu(null);
    setSiteIdToDelete(undefined)
  };
  const renderCampaignHistory = (site) => {
    const hist = campaignHistory.filter(ch => ch.project_id == site.id).slice(0, 3)
    return <Box pb={3}>
      {
        hist.map((h, key) => {
          const isOdd = key % 2 == 0
          return <Box sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: isOdd ? '#5148fb0d' : 'white',
            mb: 1,
            px: 2
          }} key={key}>
            <Typography sx={{
              flexGrow: 1,
            }}>
              {h.campaign_name}
            </Typography>
            <Box sx={{
              backgroundColor: 'primary.main',
              width: 32,
              height: 32,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer'
            }}
              onClick={() => {
                dispatch(addCampaignHistory(h))
                navigate(`/site/${h.project_id}/campaign/${h.campaign_id}`)
              }}
            >
              <ArrowForwardIcon sx={{ color: 'white' }} />
            </Box>
          </Box>
        })
      }

    </Box>
  }
  return (
    <Container maxWidth="xl">
      <Box sx={{
        display: {
          xs: 'block',
          md: 'flex'
        }
      }}>
        <Typography variant={'h4'} color='primary.dark' mr={4}>
          Your Projects
        </Typography>
        <Box textAlign={'right'}>
          <Button onClick={toggleShowCreateModal} variant="contained"
            color="secondary"
            sx={{ color: 'white' }}
          >New Project</Button>
        </Box>
      </Box>

      <Grid container mt={0} spacing={4}>
        {sites.map((site, index) => {
          const isDomainLevel = site.level == 'domain'
          return (
            <Grid item xs={12} md={3} key={index} >
              <Box sx={{
                borderRadius: '8px',
                backgroundColor: 'white',
                boxShadow: BOX_SHADOW
              }}>
                <Box sx={{
                  display: 'flex', p: 2,
                  pr: 0,
                  borderBottomColor: '#5148fb33', borderBottomColorOpacity: 0.2, borderBottomStyle: 'solid', borderWidth: 1
                }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant='h6' sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => enterSite(site)}
                    >{site.name}</Typography>
                    <Typography variant='caption' sx={{ color: 'gray' }}>{site.baseUrl}</Typography>
                  </Box>
                  <Box>
                    <IconButton aria-label="delete" onClick={(e) => {
                      setSiteIdToDelete(site.id)
                      handleOpenMenu(e)
                    }}>
                      <MoreVertIcon color='primary' />
                    </IconButton>
                  </Box>

                </Box>
                {!isDomainLevel && <Box >
                  <Box sx={{ px: 2, mt: 2 }}>
                    <Typography variant='caption' sx={{ color: 'gray', }}
                    >RECENT CAMPAIGNS</Typography>
                  </Box>
                  {renderCampaignHistory(site)}
                </Box>}
              </Box>
            </Grid>
          )
        })}

      </Grid>

      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElMenu)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={() => setShowDelDlg(true)}>
          <Typography textAlign="center">Delete</Typography>
        </MenuItem>
      </Menu>
      <Dialog open={showDelDlg} onClose={() => setShowDelDlg(false)}>
        <DialogTitle>
          Do you want to delete project?
        </DialogTitle>
        <DialogContent>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDelDlg(false)} >Cancel</Button>
          <Button onClick={() => deleteProjectAction()} variant='contained'>Confirm</Button>
        </DialogActions>
      </Dialog>
      {createProjectModalRender()}
    </Container>
  );
}

export default SiteListPage;
