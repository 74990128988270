import { Box, Container } from "@mui/material";

type HideBoxType = {
    hide: boolean,
    children: React.ReactNode
}
export const HideBox = (props: HideBoxType) => {
    const { hide, children } = props;
    return (<Box sx={{ display: hide ? 'none' : 'block' }}>
        {children}
    </Box>)
}