import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import HomeIcon from '@mui/icons-material/Home';
import { GREEEN_COLOR } from 'theme/colors';

type BreadCrumbItem = {
    label: string
    url?: string
}
type BreadCrumbProps = {
    breads: BreadCrumbItem[]
}
export default function (props: BreadCrumbProps) {
    const { breads } = props
    return <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
    >
        <Link underline="hover" color={GREEEN_COLOR} href="/">
            <HomeIcon />
        </Link>

        {
            breads.map((bread, key) => {
                if (bread.url) {
                    return (
                        <Link underline="hover" key={key} href={bread.url} color={GREEEN_COLOR} >
                            {bread.label}
                        </Link>
                    )
                } else {
                    return <Typography>{bread.label}</Typography>
                }
            })
        }

    </Breadcrumbs>
}