import { useState, useEffect, useMemo } from "react";
import { useDispatch } from 'react-redux';
import * as React from 'react';
import { Form, Modal, Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTable, usePagination, useSortBy } from "react-table";
import styled from "styled-components";

import {
  showLoadingIndicator, hideLoadingIndicator
} from 'redux/reducers/appstate'
import Axios from "axios";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { CallApi } from "utils";
import CustomReactTable from "components/ReactTable";
import { ColumnDef } from "@tanstack/react-table";
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import moment from "moment";
import CASearchForm from "./CASearchForm";
import FlagIcon from '@mui/icons-material/Flag';
import TablePdfCSVDownload from "components/TablePdfCSVDownload";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const loadUsers = async () => {
  try {
    return await Axios.get('/api/user/all-users')
  } catch (e) {
    throw e;
  }
}

interface ColType {
  url: string
  keyword_count: number,
  clicks: number,
  impressions: number,
  ctr: number,
  position: number,
  lastCrawlTime: string | null,
  internal_links: number,
  backlinks: number,
  total_score?: number
  key_relation_score?: number
}

type PropTypes = {
  data: ColType[],
  enabledNLP: boolean
}
const score_colors = [
  { min: 0, max: 10, color: 'red', label: 'Delete Pages' },
  { min: 11, max: 40, color: '#d58840', label: 'Improve Content' },
  { min: 41, max: 75, color: '#0512f2', label: 'Optimize Keywords/Pages' },
  { min: 76, max: 1000, color: '#29c609', label: 'Update Meta Tags to Improve CTR' },
]
const table_id = "track-prediction-component-result-table"
export default function ({ data = [], enabledNLP }: PropTypes) {

  const dispatch = useDispatch();
  const [filterParams, setFilterParams] = useState<{
    scoreMin: number,
    scoreMax: number,
    crawlDateFrom: string,
    crawlDateTo: string
  }>(null)
  let memoizedColumns = useMemo<ColumnDef<ColType>[]>(() => {
    let baseColumns = [
      {
        header: 'Url',
        accessorKey: 'url',
        enableColumnFilter: false
      },
      {
        header: 'Keywords',
        accessorKey: 'keyword_count',
        enableColumnFilter: false
      },
      {
        header: 'Clicks',
        accessorKey: 'clicks',
        enableColumnFilter: false
      },
      {
        header: 'Impressions',
        accessorKey: 'impressions',
        enableColumnFilter: false
      },
      {
        header: 'Ctr',
        cell: (row) => ((Number(row.getValue()) * 100).toFixed(2) + '%'),
        accessorKey: 'ctr',
        enableColumnFilter: false
      },
      {
        header: 'Position',
        accessorKey: 'position',
        cell: (row) => ((Number(row.getValue())).toFixed()),
        enableColumnFilter: false
      },
      {
        header: 'Internal links',
        accessorKey: 'internal_links',
        enableColumnFilter: false
      },
      {
        header: 'Backlinks',
        accessorKey: 'backlinks',
        enableColumnFilter: false
      },
      // {
      //   header: 'Last crawled at',
      //   accessorKey: 'lastCrawlTime',
      //   cell: (row) => (moment(row.getValue()).format('L')),
      //   enableColumnFilter: false
      // },
      {
        header: 'Score',
        accessorKey: 'total_score',
        cell: (row) => (getScoreCell(row.getValue() as number)),
        enableColumnFilter: false
      },

      // {
      //   header: 'Action',
      //   accessorKey: 'no',
      //   cell: ({ row: { original } }) => (<Box>
      //     <Button variant="contained" onClick={() => onClickReport(original)} sx={{ mr: 1 }}>Report</Button>
      //   </Box>),
      //   enableColumnFilter: false
      // },

    ]
    if (enabledNLP) {
      baseColumns.push(
        {
          header: 'Keyword Score',
          accessorKey: 'key_relation_score',
          cell: (row) => (getKeywordScoreCell(row.getValue() as number)),
          enableColumnFilter: false
        })
    }
    return baseColumns
  }, [enabledNLP])

  const getKeywordScoreCell = (score: number) => {
    if (!score) return null
    if (score == -1) {
      return <Box>Undefined</Box>
    }
    const scoreObj = score_colors.find((s) => {
      return s.min <= score && s.max > score
    })
    return <Box color={scoreObj?.color}>
      <FlagIcon />{Math.floor(score * 100) / 100}
    </Box>
  }
  const getScoreCell = (score: number) => {
    if (!score) return null
    const scoreObj = score_colors.find((s) => {
      return s.min <= score && s.max > score
    })
    return <Box color={scoreObj?.color}>
      {Math.floor(score * 100) / 100}
    </Box>
  }
  if (!data) {
    return null
  }
  const filtereData = useMemo(() => {
    if (filterParams && data) {
      let result = data.filter(d => {
        return d.total_score >= filterParams.scoreMin && d.total_score <= filterParams.scoreMax
      })

      if (filterParams.crawlDateFrom) {
        result = result.filter(d => {
          const timestamp = moment(d.lastCrawlTime).valueOf()
          const filter_timestamp = moment(filterParams.crawlDateFrom).valueOf()
          return timestamp >= filter_timestamp
        })
      }
      if (filterParams.crawlDateTo) {
        result = result.filter(d => {
          const timestamp = moment(d.lastCrawlTime).valueOf()
          const filter_timestamp = moment(filterParams.crawlDateTo).valueOf()
          return timestamp <= filter_timestamp
        })
      }
      return result
    } else {
      return data || []
    }
  }, [data, filterParams])
  const downloadColumns = [
    {
      label: 'Url',
      value: 'url',
    },
    {
      label: 'Keywords',
      value: 'keyword_count',
    },
    {
      label: 'Clicks',
      value: 'clicks',
    },
    {
      label: 'Impressions',
      value: 'impressions',
    },
    {
      label: 'Ctr',
      value: 'ctr',
    },
    {
      label: 'Position',
      value: 'position',
    },
    {
      label: 'Internal links',
      value: 'internal_links',
    },
    {
      label: 'Backlinks',
      value: 'backlinks',
    },
    {
      label: 'Last crawled at',
      value: 'lastCrawlTime',
    },
    {
      label: 'Score',
      value: 'total_score',
    },
    {
      label: 'Keyword Score',
      value: 'key_relation_score',
    }
  ]
  const downloadRows = filtereData.map((item, i) => {
    const keys = Object.keys(item)

    let newItem = {}
    keys.map((k) => {
      if (k == 'ctr') {
        newItem[k] = Number(item[k]) * 100 + '%'; return
      }
      if (k == 'lastCrawlTime') {
        newItem[k] = moment(item[k]).format('L'); return
      }
      if (k == 'total_score') {
        newItem[k] = String(Math.floor(item[k] * 100) / 100); return
      }
      if (k == 'key_relation_score' && item[k] == -1) {
        newItem[k] = 'Undefined'
      }

      newItem[k] = String(item[k])
    })
    return newItem
  })
  return (
    <Box>
      <CASearchForm onFilter={(p) => setFilterParams(p)} />
      <Container maxWidth="xl">
        <Box sx={{
          backgroundColor: 'white', p: 2,
          boxShadow: '0px 4px 0px rgba(81, 72, 251, 0.2);',
          borderRadius: '8px'
        }}>
          <TablePdfCSVDownload
            table_id={table_id}
            file_name="Content Audit"
            columns={downloadColumns}
            rows={downloadRows}
          />
          {/* <Grid container spacing={2} my={2}>
            <Grid item xs={12} md={8}>
              <Typography variant="h5" color='primary.dark'>Report History</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
            </Grid>
          </Grid> */}

          <CustomReactTable
            data={filtereData}
            columns={memoizedColumns}
            id={table_id}
          />
        </Box>
      </Container>

    </Box>
  )
}

