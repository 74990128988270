import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable'
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { CSVLink, CSVDownload } from "react-csv";
import ReactLoading from "react-loading";
import FeatherIcon from 'feather-icons-react';
import isURL from 'validator/lib/isURL';

import {
  Form,
  Card,
}
  from "react-bootstrap";

import InputRange, { Range } from 'react-input-range';

import "react-input-range/lib/css/index.css"

import './KeywordResearchResult.scss';
import axios from "axios";
import { CallApi } from "utils";
import { hideLoadingIndicator, showLoadingIndicator } from "redux/reducers/appstate";
import {
  Box, Button, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogTitle,
  FormControl, FormControlLabel, Grid, InputAdornment,
  InputLabel, ListItemText, OutlinedInput, Slider, Switch, TextField, Typography
} from "@mui/material";
import { CustomSlider } from "components/CustomSlider";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { getSearchVolumeUpdate } from "utils/KeywordResearch";
import TwoScrollbarDiv from "components/TwoScrollbarDiv";

import Select, { SelectChangeEvent } from '@mui/material/Select';
import TablePdfCSVDownload from "components/TablePdfCSVDownload";
import moment from "moment";
import ClearIcon from '@mui/icons-material/Clear';
import CheckOffImg from 'assets/images/check-off.png'
import CheckOnImg from 'assets/images/check-on.png'
import { BOX_SHADOW } from "theme/colors";

const SEARCH_COLUMN_VALUES = [
  "Search_Volumn_January",
  "Search_Volumn_February",
  "Search_Volumn_March",
  "Search_Volumn_April",
  "Search_Volumn_May",
  "Search_Volumn_June",
  "Search_Volumn_July",
  "Search_Volumn_August",
  "Search_Volumn_September",
  "Search_Volumn_October",
  "Search_Volumn_November",
  "Search_Volumn_December",
]
const COMMON_COLUMNS = [
  { label: "Keyword", value: "keyword", align: 'flex-start' },
  { label: "Keyword Score", value: "keywordScore" },
  { label: "AllinTitle", value: "allintitle" },
  { label: "Real Comp", value: "realCompMajesticTitleAndAnchorComp" },
  { label: "KGR Score", value: "KGRScore" },
  { label: "BMMS Est. Low", value: "broadMatchMonthlySearches_LowEstimate" },
  { label: "BMMS Est. High", value: "broadMatchMonthlySearches_HighEstimate" },
  { label: "Current Rank(Google)", value: "currentRankGoogle" },
  { label: "Current Ranking Url", value: "currentRankingUrl", align: 'flex-start' },
  { label: "Competition Strength", value: "competitionStrength", align: 'center' },
  { label: "Difficulty Score", value: "difficultyScore", align: 'center' },
  { label: "Est. Months to Win", value: "estimatedTimeToWin_in_Months", align: 'center' },
]
const MONTHLY_SEARCH_COLUMNS = [
  { label: "#1", value: "trafficEstimatePerMonthByRankPos_#1" },
  { label: "#3", value: "trafficEstimatePerMonthByRankPos_#3" },
  { label: "#5", value: "trafficEstimatePerMonthByRankPos_#5" },
  { label: "#7", value: "trafficEstimatePerMonthByRankPos_#7" },
  { label: "#10", value: "trafficEstimatePerMonthByRankPos_#10_#11" },
  { label: "#12+", value: "trafficEstimatePerMonthByRankPos_#12_#19" },
]
const SEARCH_TREND_COLUMNS = [
  // SEARCH COLUMN OPTIONS
  { label: "January", value: "Search_Volumn_January", align: 'center' },
  { label: "February", value: "Search_Volumn_February", align: 'center' },
  { label: "March", value: "Search_Volumn_March", align: 'center' },
  { label: "April", value: "Search_Volumn_April", align: 'center' },
  { label: "May", value: "Search_Volumn_May", align: 'center' },
  { label: "June", value: "Search_Volumn_June", align: 'center' },
  { label: "July", value: "Search_Volumn_July", align: 'center' },
  { label: "August", value: "Search_Volumn_August", align: 'center' },
  { label: "September", value: "Search_Volumn_September", align: 'center' },
  { label: "October", value: "Search_Volumn_October", align: 'center' },
  { label: "November", value: "Search_Volumn_November", align: 'center' },
  { label: "December", value: "Search_Volumn_December", align: 'center' },
]

export default ({
  id,
  report,
  campaignId,
  calculating,
  researchHistory,
  onDeleteAllKeywordData,
  onChangeResearchId
}: {
  id: string,
  report: any,
  campaignId: number,
  calculating: boolean,
  researchHistory: {
    id: number,
    created_at: string
  }[],
  onDeleteAllKeywordData: () => void,
  onChangeResearchId?: (history_id) => void
}) => {

  const dispatch = useDispatch();

  const table_id = id + '_table'

  const [showMonthlySearch, setShowMonthlySearch] = useState(false)
  const [columns, setColumns] = useState<{
    label: string,
    value: string,
    align?: string
  }[]>([])
  const [sortField, setSortField] = useState(
    "broadMatchMonthlySearches_HighEstimate"
  );
  const [sortBy, setSortBy] = useState("desc"); // asc/desc
  const [removedRows, setRemovedRows] = useState([]);

  // Metrics All Filters filter options
  const [includeFilterTermText, setIncludeFilterTermText] = useState('');
  const [includeAllFilter, setIncludeAllFilter] = useState(true);
  const [excludeFilterTermText, setExcludeFilterTermText] = useState('');
  const [excludeAllFilter, setExcludeAllFilter] = useState(true);

  const [monthlySearchFilter, setMonthlySearchFilter] = useState([0, 30000]);
  const [keywordScoreFilter, setKeywordScoreFilter] = useState([0, 10000]);
  const [keywordScoreFilterRange, setKeywordScoreFilterRange] = useState([0, 10000]);
  const [KGRScoreFilter, setKGRScoreFilter] = useState([0, 10000]);
  const [KGRScoreFilterRange, setKGRScoreFilterRange] = useState([0, 10000]);

  const result = report?.result || []

  const [allFilterOptions, setAllFilterOptions] = useState({
    includeFilterTermText,
    includeAllFilter,
    excludeFilterTermText,
    excludeAllFilter,

    monthlySearchFilter,
    keywordScoreFilter,
    KGRScoreFilter,
  })
  // delete confirm modal
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false)

  const [trackingKeywords, setTrackingKeywords] = useState([])

  useEffect(() => {
    if (showMonthlySearch) {
      setColumns([...COMMON_COLUMNS, ...MONTHLY_SEARCH_COLUMNS, ...SEARCH_TREND_COLUMNS])
    } else {
      setColumns([...COMMON_COLUMNS, ...MONTHLY_SEARCH_COLUMNS])
    }
  }, [showMonthlySearch])

  useEffect(() => {
    setRemovedRows([]);
    getTrackedKeywords()
    updateSearchRange()
  }, [report])

  const updateSearchRange = () => {
    if (result.length > 0) {
      const keywordScoreList = result.map(r => r['keywordScore']).filter(r => r != undefined)
      const KGRScoreList = result.map(r => r['KGRScore']).filter(r => r != undefined)
      const keywordScoreMin = Math.floor(Math.min(...keywordScoreList))
      const keywordScoreMax = Math.ceil(Math.max(...keywordScoreList))
      const KGRScoreMin = Math.floor(Math.min(...KGRScoreList))
      const KGRScoreMax = Math.ceil(Math.max(...KGRScoreList))
      setKeywordScoreFilterRange([keywordScoreMin, keywordScoreMax])
      setKeywordScoreFilter([keywordScoreMin, keywordScoreMax])
      setKGRScoreFilterRange([KGRScoreMin, KGRScoreMax])
      setKGRScoreFilter([KGRScoreMin, KGRScoreMax])
    }
  }
  const getTrackedKeywords = () => {
    CallApi({
      method: 'get',
      url: `/api/tracking_keyword/get_keyword_tracking/${campaignId}`
    }).then((response) => {
      if (response) {
        setTrackingKeywords(response.track_keywords || [])
      }
    })
    .catch((error) => {
      console.log('failed to load', error)
    });
  }



  const changeSortField = (field) => {
    if (field === sortField) {
      if (sortBy === "desc") {
        setSortBy("asc");
      } else {
        setSortBy("desc");
      }
    } else {
      setSortField(field);
    }
  };

  const styleForCell = (key, rowData) => {
    let bgcolor = 'transparent';
    let color = '#0D2218'
    const value = rowData[key]
    switch (key) {
      case 'keyword':
        if (rowData['monthlySearch'] <= 10) { // if we didn't find monthly search default value is 10;
          // bgcolor = '#FFEFE0'
          // color = '#EB5757'
        }
        break;
      case 'keywordScore':
        if (value > 90) {
          bgcolor = '#dbf3ee'
          color = '#12814C'
        } else if (value > 58) {
          bgcolor = '#dbf3e3'
          color = '#12814C'
        }
        break;
      case 'KGRScore':
        if (value <= 1.25) {
          bgcolor = '#dbf3ee'
          color = '#12814C'
        } else if (value <= 2) {
          bgcolor = '#fbf2ddab'
          color = '#f47602'
        } else {
          bgcolor = '#fbdddd'
          color = '#EB5757'
        }
        break;
      default:
        break;
    }

    if (SEARCH_TREND_COLUMNS.map(i => i.value).includes(key)) {
      console.info('=== value ===', JSON.stringify(value, null, 4))
      if (value > 0) {
        color = '#007bff'
      } else {
        color = '#dc3545'
      }
    }
    return {
      backgroundColor: bgcolor,
      color: color,
    };
  }
  const printValue = (key, value) => {
    let prefix = '';
    let suffix = '';

    switch (key) {
      case 'difficultyScore':
        suffix = '%';
        break;
      case 'cost':
        prefix = '$';
        break;
      default:
        break;
    }
    if (SEARCH_TREND_COLUMNS.map(i => i.value).includes(key) && value != '-') {
      suffix = '%';
    }
    const printValue = prefix + value + suffix;
    if (isURL(printValue)) {
      return <a href={printValue} target='_blank'>{printValue}</a>
    } else {
      return printValue
    }
  }

  const filteredAndSortedResult = () => {
    const {
      includeFilterTermText,
      includeAllFilter,
      excludeFilterTermText,
      excludeAllFilter,

      monthlySearchFilter,
      keywordScoreFilter,
      KGRScoreFilter,
    } = allFilterOptions

    if (!sortField) return result
    const removedRows_Keywords = removedRows.map(item => item['keyword'])
    return result.filter((r) => {
      return !removedRows_Keywords.includes(r['keyword'])
    })
      .filter(item => { // include filter apply
        const filterTerms = includeFilterTermText.split(',').map(term => term.trim()).filter(term => term !== '');
        if (filterTerms.length == 0) return true;

        if (!includeAllFilter) {
          for (const term of filterTerms) {
            if (item['keyword'].includes(term)) {
              return true;
            }
          }
          return false;
        } else {
          for (const term of filterTerms) {
            if (!item['keyword'].includes(term)) {
              return false;
            }
          }
          return true;
        }
        return true;
      })
      .filter(item => { // excldue filter apply
        const filterTerms = excludeFilterTermText.split(',').map(term => term.trim()).filter(term => term !== '');
        if (filterTerms.length == 0) return true;

        if (!excludeAllFilter) {
          for (const term of filterTerms) {
            if (item['keyword'].includes(term)) {
              return false;
            }
          }
          return true;
        } else {
          for (const term of filterTerms) {
            if (!item['keyword'].includes(term)) {
              return true;
            }
          }
          return false;
        }
        return true;
      })
      .filter(item => { // keyword score filter
        return item['keywordScore'] >= keywordScoreFilter[0] && item['keywordScore'] <= keywordScoreFilter[1]
      })
      .filter(item => { // KGR score filter
        return item['KGRScore'] >= KGRScoreFilter[0] && item['KGRScore'] <= KGRScoreFilter[1]
      })
      .sort((a, b) => {
        let aVal = a[sortField];
        let bVal = b[sortField];
        if (sortBy == 'asc') {
          if (aVal > bVal) return 1;
          if (aVal < bVal) return -1;
        } else {
          if (aVal > bVal) return -1;
          if (aVal < bVal) return 1;
        }
        return 0;
      }).map(r => {
        const updateRates = getSearchVolumeUpdate(r.searchVolumeFor24Month || [])
        let monthlyUpdate = {}
        updateRates.map(u => {
          monthlyUpdate[SEARCH_COLUMN_VALUES[u.month - 1]] = u.updateRate ? (u.updateRate.toFixed(2)) : '-'
        })
        return {
          ...r,
          ...monthlyUpdate
        }
      })
  }

  const trackThisKeyword = (item) => {
    dispatch(showLoadingIndicator())
    axios.post('/api/tracking_keyword/add-track-keyword', {
      campaignId: campaignId,
      track_keyword: item.keyword,
      track_data: item,
      language_code: report.language_code,
      location_code: report.location_code,
      domain: report.domain,
    })
      .then(response => {
        getTrackedKeywords()
      })
      .catch((error) => {
        console.log('failed to load', error)
      })
      .finally(() => {
        dispatch(hideLoadingIndicator())
      })
  }
  const unTrackThisKeyword = (item) => {
    dispatch(showLoadingIndicator())
    axios.post('/api/tracking_keyword/remove-track-keyword', {
      campaignId: campaignId,
      track_keyword: item.keyword,
    })
      .then(response => {
        getTrackedKeywords()
      })
      .catch((error) => {
        console.log('failed to load', error)
      })
      .finally(() => {
        dispatch(hideLoadingIndicator())
      })
  }

  const renderTrackUntrackButtons = (r) => {
    if (trackingKeywords.includes(r.keyword)) {
      return (
        <Box onClick={() => unTrackThisKeyword(r)} sx={{ cursor: 'pointer', textAlign: 'center' }}>
          <img src={CheckOnImg} width="20px" height="20px" />
        </Box>
      )

    } else {
      return (
        <Box onClick={() => trackThisKeyword(r)} sx={{ cursor: 'pointer', textAlign: 'center' }}>
          <img src={CheckOffImg} width="20px" height="20px" />
        </Box>
      )
    }
  }
  const results = filteredAndSortedResult();
  const hasData = results.length > 0
  return (
    <Box>
      <Container maxWidth="xl">
        <Box sx={{
          backgroundColor: '#EAEFED',
          boxShadow: BOX_SHADOW,
          borderRadius: '8px',
          marginTop: 6,
          marginBottom: 6
        }}>
          <Box sx={{
            backgroundColor: '#fff',
            borderRadius: '8px 8px 0 0',
            display: 'flex',
            p: 2,
          }}>
            <Grid container spacing={2}>
              <Grid item md={2} xs={12}>
                <Typography variant="h5" color='primary.dark'>Your Results</Typography>
              </Grid>
              <Grid item md={4} xs={12}>
                <Box sx={{
                  display: researchHistory.length > 0 ? 'block' : 'none',
                  minWidth: '20rem'
                }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Recent Searches</InputLabel>
                    <Select
                      value={report.id}
                      label="Recent Searches"
                      onChange={(e) => onChangeResearchId(e.target.value)}
                    >
                      {
                        researchHistory.map((r) => {
                          return <MenuItem value={r.id}>{moment(r.created_at).format('lll')}</MenuItem>
                        })
                      }
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item md={6} xs={12} textAlign='right'>
                <Box>
                  <TablePdfCSVDownload
                    table_id={table_id}
                    file_name="Keyword Research"
                    columns={[...COMMON_COLUMNS, ...MONTHLY_SEARCH_COLUMNS, ...SEARCH_TREND_COLUMNS]}
                    rows={results}
                  />
                </Box>
              </Grid>
            </Grid>

          </Box>

          <Box sx={{
            padding: 2
          }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField placeholder="Separated with comma..." variant="outlined"
                  label="Include Words"
                  value={includeFilterTermText}
                  onChange={(e) => { setIncludeFilterTermText(e.target.value) }}
                  fullWidth
                  sx={{
                    '& input': {
                      backgroundColor: 'white',
                      borderRadius: '4px 0 0 4px'
                    },
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position='end'>

                      <FormControlLabel control={<Switch defaultChecked />} sx={{ m: 0 }}
                        label={includeAllFilter ? "All" : 'Any'}
                        checked={includeAllFilter}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setIncludeAllFilter(e.target.checked)
                        }}
                      />
                    </InputAdornment>
                  }}
                />

              </Grid>
              <Grid item xs={12} md={6}>
                <TextField placeholder="Separated with comma..." variant="outlined"
                  label="Exclude Words"
                  value={excludeFilterTermText}
                  onChange={(e) => { setExcludeFilterTermText(e.target.value) }}
                  fullWidth
                  sx={{
                    '& input': {
                      backgroundColor: 'white',
                      borderRadius: '4px 0 0 4px'
                    },
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position='end'>

                      <FormControlLabel control={<Switch defaultChecked />} sx={{ m: 0 }}
                        label={excludeAllFilter ? "All" : "Any"}
                        checked={excludeAllFilter}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setExcludeAllFilter(e.target.checked)
                        }}
                      />
                    </InputAdornment>
                  }}
                />
              </Grid>
            </Grid>
            <Grid container mt={2}>
              <Grid item xs={12} md={6} px={2}>
                <Typography color='primary.dark'>Keyword Scores</Typography>
                <CustomSlider
                  getAriaLabel={() => 'Keyword Score Filter'}
                  value={keywordScoreFilter}
                  onChange={(event: Event, newValue: number | number[]) => setKeywordScoreFilter(newValue as number[])}
                  valueLabelDisplay="on"
                  min={keywordScoreFilterRange[0]}
                  max={keywordScoreFilterRange[1]}
                />
              </Grid>
              <Grid item xs={12} md={6} px={2}>
                <Typography color='primary.dark'>KGR Scores</Typography>
                <CustomSlider
                  getAriaLabel={() => 'KGR Score Filter'}
                  value={KGRScoreFilter}
                  onChange={(event: Event, newValue: number | number[]) => setKGRScoreFilter(newValue as number[])}
                  valueLabelDisplay="on"
                  min={KGRScoreFilterRange[0]}
                  max={KGRScoreFilterRange[1]}
                />
              </Grid>
            </Grid>
            <Box display='flex' mt={4} mb={2}>
              <Box mr={2}>
                <Button
                  variant="contained"
                  onClick={() => {
                    setAllFilterOptions({
                      includeFilterTermText,
                      includeAllFilter,
                      excludeFilterTermText,
                      excludeAllFilter,

                      monthlySearchFilter,
                      keywordScoreFilter,
                      KGRScoreFilter,
                    })
                  }}
                  color='secondary'
                  sx={{
                    borderRadius: 10,
                  }}
                >
                  Apply Filters
                </Button>
              </Box>
              <Box textAlign='center'>
                <Button variant="contained" onClick={() => setShowMonthlySearch(!showMonthlySearch)}
                  sx={{
                    borderRadius: 10,
                    background: 'white',
                    color: 'success.dark',
                    '&:hover': {
                      background: '#eee',
                    }
                  }}
                >{!showMonthlySearch ? 'Show Search Trend' : 'Hide Search Trend'}</Button>
              </Box>
            </Box>
          </Box>
        </Box >

      </Container>

      <Box display={hasData ? 'block' : 'none'}>
        <TwoScrollbarDiv>
          <table className="table" id={table_id}>
            <thead style={{ fontSize: "0.7rem", position: 'sticky', background: 'white' }}>
              <tr >
                <th rowSpan={2}>Track</th>
                {COMMON_COLUMNS.map((col, i) => (
                  <th key={i} rowSpan={2}
                    onClick={() => changeSortField(col.value)}
                    className="pointer">
                    {col.label}
                    {sortField == col.value && (
                      <i className={`fa ${sortBy == 'asc' ? 'fa-sort-amount-asc' : 'fa-sort-amount-desc'}`} aria-hidden="true"></i>
                    )}
                  </th>
                ))}
                <th colSpan={6} scope="colgroup" style={{ padding: '0 !important' }}><Box textAlign={'center'}>Monthly Traffic Est. by Rank:</Box></th>
                {showMonthlySearch && <th colSpan={12} scope="colgroup" style={{ padding: '0 !important' }}><Box textAlign={'center'}>Search Trend</Box></th>}
              </tr>
              <tr>
                {MONTHLY_SEARCH_COLUMNS.map((col, i) => (
                  <th key={i}
                    onClick={() => changeSortField(col.value)}
                    className="pointer">
                    {col.label}
                    {sortField == col.value && (
                      <i className={`fa ${sortBy == 'asc' ? 'fa-sort-amount-asc' : 'fa-sort-amount-desc'}`} aria-hidden="true"></i>
                    )}
                  </th>
                ))}
                {showMonthlySearch && SEARCH_TREND_COLUMNS.map((col, i) => (
                  <th key={i}
                    onClick={() => changeSortField(col.value)}
                    className="pointer">
                    {col.label}
                    {sortField == col.value && (
                      <i className={`fa ${sortBy == 'asc' ? 'fa-sort-amount-asc' : 'fa-sort-amount-desc'}`} aria-hidden="true"></i>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {results.map((r, i) => (
                <tr key={i}>
                  <td>
                    {renderTrackUntrackButtons(r)}
                  </td>
                  {columns.map((col, j) => {
                    let justifyContent = 'flex-end'
                    if ( col.align ) {
                      justifyContent = col.align
                    }

                    return (
                      <td
                        key={`${i}_${j}`}
                        style={styleForCell(col.value, r)}
                        className={`${j == 0 ? 'no-wrap' : ''}`}
                      >
                        <Box display='flex' alignItems={'center'} minWidth={r[col.value]?.length > 1 ? '4rem' : '1rem'}
                          justifyContent={justifyContent}
                        >
                          {
                            col.value == 'keyword' &&
                            <ClearIcon
                              sx={{
                                color: '#FEAD62',
                                mr: 1,
                                cursor: 'pointer'
                              }}
                              onClick={() => {
                                if (!removedRows.find(item => item['keyword'] == r['keyword'])) {
                                  let newVals = [...removedRows, r];
                                  setRemovedRows(newVals)
                                }
                              }}
                            />
                          }
                          {printValue(col.value, r[col.value])}
                        </Box>
                      </td>
                    )
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </TwoScrollbarDiv>
      </Box>
      <Dialog open={showDeleteConfirmModal} onClose={() => setShowDeleteConfirmModal(false)}>
        <DialogTitle>
          <Typography>Are you sure delete?</Typography>
        </DialogTitle>
        <DialogContent>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteConfirmModal(false)} color='primary'>No</Button>
          <Button onClick={() => {
            setShowDeleteConfirmModal(false)
            onDeleteAllKeywordData()
          }} color='error' variant="contained">Delete</Button>
        </DialogActions>
      </Dialog>
    </Box >
  );
}