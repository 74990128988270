import { Box, Button, Container, Grid, TableContainer, Typography } from "@mui/material"
import { ColumnDef } from "@tanstack/react-table"
import CustomReactTable from "components/ReactTable"
import { useEffect, useMemo, useState } from "react"
import Alert from '@mui/material/Alert';
import toast from "react-hot-toast"
import { CallApi } from "utils"
import { hideLoadingIndicator, showLoadingIndicator } from "redux/reducers/appstate"
import { useDispatch } from 'react-redux'
import { getVoltageLevelLabel } from "utils/voltage";
import { useParams } from "react-router-dom";
import { CloseButton, Toast } from "react-bootstrap";
import BoltIcon from '@mui/icons-material/Bolt';
import TablePdfCSVDownload from "components/TablePdfCSVDownload";
import { LanguageModel } from "Models/LanguageModel";
import { CannibalizationModal } from "components/KeywordCannibalizationModal";
import $ from "lodash";

export type GoogleConsoleData = {
    keyword: string
    clicks: number
    impressions: number
    ctr: number
    position: number,
    monthlySearch?: number,
    difficultyScore?: number,
    keywordCannibalizationText?: string,
    cannibalizedKeywords?: []
}
type PropTypes = {
    data: {
        google_data: GoogleConsoleData[],
        avgImpressions: number
    },
    language: LanguageModel,
    country: number,
    domain: string,
    setLoading: Function
}
type TableItem = {
    keyword: string
    clicks: number
    impressions: number
    ctr: number
    position: number
}

type GeoData = {
    language: LanguageModel,
    country: number,
}

type GeoDataMapping = {
    search_volume: string,
    keyword_difficulty: string,
}

type GeoDataResult = {
    value: any,
    keyword: string,
}

async function pullKeywordAnalytics(
  { country, language }: GeoData,
  data: GoogleConsoleData[],
  setTableData: Function,
  setLoading: Function,
) {
    const sortedData = $.cloneDeep(data).sort((a, b) => b.impressions - a.impressions)
    const mapping: GeoDataMapping = {
        search_volume: "get_monthly_search",
        keyword_difficulty: "get_keyword_difficulty",
    }
    const results = await Promise.all(
        $.map(mapping, async (url: string, type: string) => {
        const results: GeoDataResult[] = []
        for (const row of sortedData.slice(0, 5).filter(item => !item.keyword.startsWith('site:'))) {
            const { keyword } = row
            const languageCode = language.language_code
            const locationCode = country
            const formdata = {
                keyword,
                language_code: languageCode,
                location_code: locationCode,
            }

            try {
            const response = await CallApi({
                url: `/api/dataforseo/keyword_data/${url}`,
                method: "post",
                data: formdata,
            })
            if ($.isEmpty(response)) continue

            results.push({ value: $.first(response)[type], keyword })
            } catch (error) {}
        }
        return results
        })
    )

    const [monthlySearches, keywordDifficulties] = results.map((result) =>
        $.keyBy($.compact(result), "keyword")
    )

    if ($.size(monthlySearches) || $.size(keywordDifficulties)) {
        setTableData(
            sortedData.map((row: GoogleConsoleData) => {
                const newRow = $.cloneDeep(row)
                const monthlySearch = monthlySearches[row.keyword]
                const difficultyScore = keywordDifficulties[row.keyword]
                if (monthlySearch) {
                    $.merge(newRow, { monthlySearch: monthlySearch.value })
                }
                if (difficultyScore) {
                    $.merge(newRow, { difficultyScore: difficultyScore.value })
                }
                return newRow
            })
        )
    } else setTableData(sortedData)

    setLoading(false)
}

export default function VoltageTable({ data, language, country, domain, setLoading }: PropTypes) {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalData, setModalData] = useState({ rows: [], type: "" })
    const [tableData, setTableData] = useState([])
    const [avgImpression, setAvgImpression] = useState(null)
    const dispatch = useDispatch()

    const params = useParams()
    const { campaignId } = params
    const [rankingKeywords, setRankingKeywords] = useState([])

    useEffect(() => {
        if (data) {
            if (!tableData.length && language) {
              pullKeywordAnalytics(
                { country, language },
                data.google_data,
                setTableData,
                setLoading
              )
            }
            setAvgImpression(data.avgImpressions)
        } else {
            setTableData([])
        }
    }, [data])

    useEffect(() => {
        if (!campaignId) {
            return;
        }
        getRankedKeywords()
    }, [campaignId])
    const getRankedKeywords = () => {
        CallApi({
            method: 'get',
            url: `/api/tracking_keyword/get_keyword_tracking/${campaignId}`
        }).then((response) => {
            if (response.report) {
                setRankingKeywords(response.report.track_keywords || [])
            }
        })
        .catch((error) => {
            console.log('failed to load', error)
        });
    }

    const getMonthlySearch = (data) => {
        if (!language) {
            toast.error('Please choose language')
            return
        }
        const keyword = data.original.keyword
        const language_code = language.language_code
        const location_code = country
        const formdata = {
            keyword,
            language_code,
            location_code
        }
        dispatch(showLoadingIndicator());

        CallApi({
            url: "/api/dataforseo/keyword_data/get_monthly_search",
            method: 'post',
            data: formdata
        }).then((response) => {
            if (response && response[0]) {
                const monthlySearch = response[0].search_volume
                setTableData(prevData => {
                    const $newTableData = prevData.map(t => {
                        if (t.keyword == keyword) {
                            return {
                                ...t,
                                monthlySearch: monthlySearch
                            }
                        } else {
                            return t
                        }
                    })
                    return $newTableData
                })
            }
        }).finally(() => {
            dispatch(hideLoadingIndicator());
        });
    }
    const getkeywordDifficulty = (data) => {
        if (!language) {
            toast.error('Please choose language')
            return
        }
        const keyword = data.original.keyword
        const language_code = language.language_code
        const location_code = country
        const formdata = {
            keyword,
            language_code,
            location_code
        }
        dispatch(showLoadingIndicator());

        CallApi({
            url: "/api/dataforseo/keyword_data/get_keyword_difficulty",
            method: 'post',
            data: formdata
        }).then((response) => {
            if (response && response[0]) {
                const difficultyScore = response[0].keyword_difficulty
                setTableData(prevData => {
                    const $newTableData = prevData.map(t => {
                        if (t.keyword == keyword) {
                            return {
                                ...t,
                                difficultyScore: difficultyScore
                            }
                        } else {
                            return t
                        }
                    })
                    return $newTableData
                })
            }
        }).finally(() => {
            dispatch(hideLoadingIndicator());
        });
    }
    const trackThisKeyword = (item) => {

        if (!language) {
            toast.error('Please choose language')
            return
        }
        const language_code = language.language_code
        const location_code = country
        const formdata = {
            campaignId: campaignId,
            track_keyword: item.keyword,
            language_code,
            location_code,
            domain
        }
        setRankingKeywords([...new Set([...rankingKeywords, item.keyword])])
        toast.success('Success: It will be added in a few minutes later!')

        CallApi({
            url: '/api/tracking_keyword/get_result_and_track_keyword',
            method: 'post',
            data: formdata
        }).then((res) => {
            getRankedKeywords()
        })
        .catch((error) => {
            console.log('failed to load', error)
        });
    }
    const unTrackThisKeyword = (item) => {
        CallApi({
            url: '/api/tracking_keyword/remove-ranking-keyword',
            method: 'post',
            data: {
                campaignId: campaignId,
                rank_keyword: item.keyword,
            }
        }).then((res) => {
            getRankedKeywords()
        })
        .catch((error) => {
            console.log('failed to load', error)
        });
    }
    const renderTrackUntrackButtons = (r) => {

        if (rankingKeywords.includes(r.keyword)) {
            return (
                <Button
                    color="error"
                    onClick={() => unTrackThisKeyword(r)}
                >UnTrack</Button>
            )

        } else {
            return (
                <Button
                    onClick={() => trackThisKeyword(r)}
                >Track</Button>
            )
        }
    }

    const memoizedColumns = useMemo<ColumnDef<GoogleConsoleData>[]>(() => [
        // {
        //     header: '#',
        //     id: 'action',
        //     cell: props => <Button onClick={() => {
        //         onMonitorBackLink(props.row.original)
        //     }} color='primary'>Monitor</Button>,
        //     enableColumnFilter: false
        // },
        {            
            header: 'TRACK',
            id: 'action',
            cell: props => renderTrackUntrackButtons(props.row.original),
            enableColumnFilter: false
        },
        {
            header: 'KEYWORD',
            accessorKey: 'keyword',
            id: 'keyword',
            enableColumnFilter: true
        },
        {
            header: 'MONTHLY SEARCHES',
            accessorKey: 'monthlySearch',
            id: 'monthlySearch',
            cell: props => {
                const item = props.row.original
                if (item.keyword.startsWith("site:")) return "-"
                return item.monthlySearch == undefined ? <Button onClick={() => {
                    getMonthlySearch(props.row)
                }} color='primary'>GET</Button> : item.monthlySearch
            },
            enableColumnFilter: true
        },
        {
            header: 'KEYWORD DIFFICULTY',
            accessorKey: 'difficultyScore',
            id: 'difficultyScore',
            cell: props => {
                const item = props.row.original
                if (item.keyword.startsWith("site:")) return "-"
                return item.difficultyScore == undefined ? <Button onClick={() => {
                    getkeywordDifficulty(props.row)
                }} color='primary'>GET</Button> : item.difficultyScore
            },
            enableColumnFilter: true
        },
        {
            header: 'CLICKS',
            accessorKey: 'clicks',
            id: 'clicks',
            enableColumnFilter: true
        },
        {
            header: 'IMPRESSIONS',
            accessorKey: 'impressions',
            id: 'impressions',
            enableColumnFilter: true
        },
        {
            header: 'CTR',
            accessorKey: 'ctr',
            id: 'ctr',
            cell: props => props.row.original.ctr + '%',
            enableColumnFilter: true
        },
        {
            header: 'POSITION',
            accessorKey: 'position',
            id: 'position',
            enableColumnFilter: true
        },
        {
            header: 'Keyword Cannibalization',
            accessorKey: 'keywordCannibalizationText',
            id: 'cannibalization',
            enableColumnFilter: true,
            cell: props => {
                let keywordCannibalizationBackColor = ''
                let textColor = ''
                if (props.row.original.keywordCannibalizationText == 'low') {
                    keywordCannibalizationBackColor = '#b6d7a8'
                    textColor = '#12814c'
                } else if (props.row.original.keywordCannibalizationText == 'medium') {
                    keywordCannibalizationBackColor = '#f6b26b'
                    textColor = '#eb925b'
                } else if (props.row.original.keywordCannibalizationText == 'high') {
                    keywordCannibalizationBackColor = '#ea9999'
                    textColor = '#eb5b5b'
                }
                let keywordCannibalizationText = props.row.original.keywordCannibalizationText || ''
                if (keywordCannibalizationText) {
                    keywordCannibalizationText =  keywordCannibalizationText.charAt(0).toUpperCase() + keywordCannibalizationText.slice(1);
                }
                return <Typography variant="inherit"
                            color={textColor}
                            onClick={() => {
                                const rows = props.row.original.cannibalizedKeywords
                                if ($.size(rows) > 1) {
                                    setModalData({ rows, type: keywordCannibalizationText })
                                    setIsModalOpen(true)
                                }
                            }}
                            sx={{ cursor: keywordCannibalizationBackColor && "pointer", textAlign: "center" }}
                            style={{
                                backgroundColor: keywordCannibalizationBackColor,
                                padding: '5px',
                            }}>{ keywordCannibalizationText }</Typography>
            },
        }

    ], [language, rankingKeywords, tableData]);
    const downloadColumns = [
        {
            label: 'Keyword',
            value: 'keyword',
        },
        {
            label: 'Monthly Searches',
            value: 'monthlySearch',
        },
        {
            label: 'Keyword Difficulty',
            value: 'difficultyScore',
        },
        {
            label: 'Clicks',
            value: 'clicks',
        },
        {
            label: 'Impressions',
            value: 'impressions',
        },
        {
            label: 'Ctr',
            value: 'ctr',
        },
        {
            label: 'Position',
            value: 'position',
        },
    ]

    const voltageLevel = getVoltageLevelLabel(avgImpression)
    const hasData = tableData.length > 0

    return <>
        <CannibalizationModal data={modalData} isOpen={isModalOpen} setOpen={setIsModalOpen} />
        {voltageLevel && <Container maxWidth="xl">
            <Grid container mt={4} mb={2}>
                <Grid item xs={6} md={4}>
                    <Typography variant="h5" color='primary.dark'>Your Results</Typography>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Box display={'flex'} alignItems='center'>
                        <BoltIcon sx={{ color: '#FD7D00' }} />
                        <Typography variant="h6" mr={1}>Voltage Level: </Typography>
                        <Typography variant="h6" color="#FD7D00">{voltageLevel.from} - {voltageLevel.to}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4} display='flex' justifyContent='flex-end'>
                    <TablePdfCSVDownload
                        table_id="top-level-voltage-table"
                        file_name="toplevelvoltage"
                        columns={downloadColumns}
                        rows={tableData}
                    />
                </Grid>
            </Grid>

        </Container>}
        {hasData && <CustomReactTable
            data={tableData}
            columns={memoizedColumns}
            id="top-level-voltage-table"
        />}
    </>
}