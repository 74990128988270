import { Box, Button, Container, Grid, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import CountrySelect from "components/CountrySelect";
import { GoogleLogin } from "components/GoogleLogin";
import { getSitesList, loadGAPIClient, searchAnalyticsForSearchConsole } from "utils/search-console-helper";
import moment from "moment";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { gapi, loadAuth2, loadClientAuth2 } from 'gapi-script';
import toast from "react-hot-toast";
import Axios from "axios";
import Plot from 'react-plotly.js';

type StateType = {
    [key: string]: string | number
}
type PlotData = {
    x: number[],
    y: number[],
    type: string,
    mode: string,
    marker: any
}
type TrackResultGraphType = {
    clicks: {
        data: PlotData[],
        layout: any
    },
    impressions: {
        data: PlotData[],
        layout: any
    },
}

type PropTypes = {
    isActive: boolean,
    data: any
}
export default function ({ data, isActive }: PropTypes) {
    const [graphData, setGraphData] = useState<TrackResultGraphType>(null)
    const boxRef = useRef(null)
    const [boxWidth, setBoxWidth] = useState(800)

    useEffect(() => {

        if (data) {
            setGraphData(data)
        }
    }, [data])

    useEffect(() => {
        if (boxRef && boxRef.current) {
            setBoxWidth(boxRef.current.offsetWidth);
        }
    }, [boxRef.current, isActive]);
    return <Box >
        <Container maxWidth="xl">
            <Box py={1} ref={boxRef}></Box>
            {graphData && <Grid container>
                <Grid item xs={12} md={12}>
                    <Plot
                        data={graphData.clicks.data}
                        // layout={graphData.clicks.layout}
                        layout={{ ...graphData.clicks.layout, width: boxWidth }}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <Plot
                        data={graphData.impressions.data}
                        layout={{ ...graphData.impressions.layout, width: boxWidth }}
                    />
                </Grid>
            </Grid>}
        </Container>
    </Box>
}