import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import React, { useEffect, useState } from 'react';
import { CSVLink } from "react-csv";
import { useDispatch } from 'react-redux';
import "react-input-range/lib/css/index.css";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button, Checkbox } from "@mui/material";
import axios from "axios";
import { useParams } from 'react-router-dom';
import { hideLoadingIndicator, showLoadingIndicator } from "redux/reducers/appstate";
import { CallApi } from "utils";

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { getSearchVolumeUpdate } from "utils/KeywordResearch";
import moment from 'moment'

export default ({
    table_id,
    file_name,
    columns,
    rows,
}: {
    table_id: string,
    file_name: string,
    columns: { label: string, value: string }[],
    rows: { [key: string]: string, }[],
}) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const timestamp = moment().format('YYYY_M_D_h_mm_ss')
    const downloadTableToPDF = () => {
        let table = document.querySelector(`#${table_id}`);
        let SPAN_SIZE = 60;
        let width = table.clientWidth + SPAN_SIZE * 2;
        let height = width * 0.7;

        let doc = new jsPDF({
            orientation: "landscape",
            unit: "px",
            format: [width, height],
        })

        autoTable(doc, {
            html: `#${table_id}`,
            startY: 90,
            margin: {
                top: 90,
                bottom: 80
            },
            // horizontalPageBreak: true,
            // horizontalPageBreakRepeat: 0,
            styles: {
                fontSize: 15,
                cellWidth: 'auto',
            },
            headStyles: {
                cellWidth: 'auto',
                fillColor: 'white',
                textColor: '#212529'
            },
            columnStyles: {
                0: { cellWidth: 'auto' }
            },
        });
        doc.save(`${file_name}_${timestamp}.pdf`);
    }


    const makeCSVData = () => {
        let results = []
        results.push(columns.map(c => c.label));
        for (let data of rows) {
            let row = []
            for (let col of columns) {
                row.push(data[col.value])
            }
            results.push(row)
        }
        return results
    }

    const handleClickDownloadMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseDownloadMenu = () => {
        setAnchorEl(null)
    }
    let csvData = makeCSVData();
    const openDownloadMenu = Boolean(anchorEl);
    const hasData = rows.length > 0

    return (
        <Box>
            <Button
                aria-controls={openDownloadMenu ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openDownloadMenu ? 'true' : undefined}
                variant="contained"
                disabled={!hasData}
                disableElevation
                onClick={handleClickDownloadMenu}
                endIcon={<KeyboardArrowDownIcon />}
                color='secondary'
                sx={{
                    backgroundColor: 'secondary.light',
                    borderRadius: 10,
                }}
            >
                Download
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openDownloadMenu}
                onClose={handleCloseDownloadMenu}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={handleCloseDownloadMenu} sx={{
                    '& a': {
                        color: 'inherit'
                    }
                }}>
                    <CSVLink
                        data={csvData}
                        filename={`${file_name}_${timestamp}.csv`}
                    >Export CSV</CSVLink>
                </MenuItem>
                <MenuItem onClick={() => {
                    handleCloseDownloadMenu()
                    downloadTableToPDF()
                }}>Export PDF</MenuItem>
            </Menu>
        </Box>
    );
}