type SearchVolumeType = {
    year: number,
    month: number,
    search_volume: number
}
export const getSearchVolumeUpdate = (search_volumes: SearchVolumeType[]) => {
    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    let results: {
        month: number,
        updateRate: number,
    }[] = []
    for (let index = 0; index < months.length; index++) {
        const m = months[index];
        const updateRate = getMonthUpdate(m, search_volumes)
        results.push({
            month: m,
            updateRate: updateRate
        })
    }
    return results
}
const getMonthUpdate = (month: number, search_volumes: SearchVolumeType[]) => {
    if (search_volumes.length < 1) {
        return null
    }
    let month_search_volumes = search_volumes.filter(s => s.month == month)

    if (month_search_volumes.length < 1) {
        return null
    }
    let avg_search_volume = month_search_volumes.map(s => s.search_volume).reduce((a, b) => a + b) / month_search_volumes.length
    let recent_year = month_search_volumes.map(m => m.year).sort().reverse()[0]
    let recent_year_search_volume = month_search_volumes.filter(m => m.year == recent_year)[0].search_volume
    if (recent_year_search_volume < 1) {
        return null
    }
    const result = (avg_search_volume / recent_year_search_volume - 1) * 100
    if (result == Infinity) {
        console.info('=== recent_year ===', JSON.stringify(recent_year, null, 4))
        console.info('=== recent_year_search_volume ===', JSON.stringify(recent_year_search_volume, null, 4))
    }
    return result
}
export const getSplittedKeywords = (keywords) => {
    let splittedKeywords = []
    keywords.split("\n").map((kw) => {
        const keywordLines = kw.trim().split(",").map(k => k.trim());
        splittedKeywords = splittedKeywords.concat(keywordLines)
    });
    return splittedKeywords;
};
export const validateKeywords = (keywords: string[]): {
    result: boolean,
    message: string
} => {
    const MAX_COUNT = 1000
    const MAX_KEYWORD_LETTERS = 80
    const MAX_WORDS_KEYWORD = 10
    
    if (keywords.length > MAX_COUNT) {
        return {
            result: false,
            message: 'Too many keywords, Maximum 1000'
        }
    }
    for (let index = 0; index < keywords.length; index++) {
        const element = keywords[index];
        const letters = element.length
        const words = element.split(' ').length
        if (letters > MAX_KEYWORD_LETTERS) {
            return {
                result: false,
                message: `"${element}" is too long, maximum 80 letters`
            }
        }
        if (words > MAX_WORDS_KEYWORD) {
            return {
                result: false,
                message: `"${element}" is too long, maximum 10 words`
            }
        }
    }
    return {
        result: true,
        message: 'Valid'
    }
}
export const getValidKeywords = (keywords: string[]): string[] => {
    const MAX_COUNT = 1000
    const MAX_KEYWORD_LETTERS = 80
    const MAX_WORDS_KEYWORD = 10
    let validKeywords = keywords.filter(element => {
        const letters = element.length
        const words = element.split(' ').length
        return !(letters > MAX_KEYWORD_LETTERS || words > MAX_WORDS_KEYWORD)
    })
    return validKeywords.slice(0, MAX_COUNT)
}