import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import MulishVariable from 'assets/fonts/Mulish/Mulish-VariableFont_wght.ttf'
import MulishItalic from 'assets/fonts/Mulish/Mulish-Italic-VariableFont_wght.ttf'
import MuliRegular from '../assets/fonts/muli/Muli-Regular.ttf';
import {
    BLUE_COLOR,
    DARK_BLUE_COLOR,
    NIGHT_BLUE_COLOR,

    LIGHT_GRAY_COLOR,
    GRAY_COLOR,
    DARK_GRAY_COLOR,

    ORANGE_COLOR,
    LIGHT_ORANGE_COLOR,
    DARK_ORANGE_COLOR,

    GREEEN_COLOR,
    LIGHT_GREEN_COLOR,
    DARK_GREEN_COLOR
} from './colors'
import typography from './base/typography';
import tabs from './components/tabs/tabs';
import tab from './components/tabs/tab';
import textField from './components/form/textField';

declare module '@mui/material/styles' {
    interface Theme {
        palette: {
            primary: {
                main: string,
                light: string,
                dark: string,
            },
            green: {
                main: string,
                light: string,
                dark: string
            }
        },
        status: {
            danger: string;
        };
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        status?: {
            danger?: string;
        };
    }
}

export const theme = createTheme({
    palette: {
        primary: {
            main: GREEEN_COLOR,
            light: LIGHT_GREEN_COLOR,
            dark: DARK_GREEN_COLOR
        },
        secondary: {
            main: ORANGE_COLOR,
            light: LIGHT_ORANGE_COLOR,
            dark: DARK_ORANGE_COLOR
        },
        success: {
            main: GREEEN_COLOR,
            light: LIGHT_GREEN_COLOR,
            dark: DARK_GREEN_COLOR
        }
    },
    components: {

        MuiCssBaseline: {
            styleOverrides: {
                '@font-face': {
                    fontFamily: 'Mulish',
                    fontStyle: 'normal',
                    fontDisplay: 'swap',
                    src: `local('Mulish'), url(${MuliRegular}) format('truetype')`,
                },
            }
        },
        MuiTabs: { ...tabs },
        MuiTab: { ...tab },
        MuiTextField: { ...textField },

    },
    typography: {
        ...typography
    }
});