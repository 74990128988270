import { Box } from "@mui/material";


export default function ShadowBox(props) {
    return <Box sx={{
        backgroundColor: 'white', p: 2,
        boxShadow: '0px 4px 0px rgba(43, 99, 73, 0.2)',
        borderRadius: '0 0 8px 8px'
    }}>
        {props.children}
    </Box>
}