import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import CustomReactTable from "components/ReactTable"
import Button from "@mui/material/Button"
import DownloadIcon from "@mui/icons-material/Download"
import CloseIcon from "@mui/icons-material/Close"
import { downloadCSV } from "utils"
import $ from "lodash"
import moment from "moment"

const columns = [
    {
        header: "URL",
        id: "url",
        cell: (props: any) => (
            <a href={props.row.original.page} target="_blank" rel="noreferrer">
                <Typography variant="inherit" color="info.light">
                    {props.row.original.page}
                </Typography>
            </a>
        ),
    },
    { header: "Keyword", id: "keyword", accessorKey: "keyword" },
    { header: "Clicks", id: "clicks", accessorKey: "clicks" },
    { header: "Impressions", id: "impressions", accessorKey: "impressions" },
    { header: "CTR", id: "ctr", accessorKey: "ctr" },
    { header: "Avg. position", id: "position", accessorKey: "position" },
]

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 1400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    height: "80vh",
    overflow: "auto",
    p: 4,
}

type ModalData = {
    rows: Object[]
    type: string
}

type PropTypes = {
    data: ModalData
    isOpen: boolean
    setOpen: Function
}

export function CannibalizationModal({ data, isOpen, setOpen }: PropTypes) {
    const handleClose = () => setOpen(false)
    const fileName = `${$.get($.first(data.rows), "keyword")}_${
        data.type
    }_${moment().unix()}.csv`

    return (
        <div>
            <Modal
                open={isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div
                        className="d-flex"
                        style={{ justifyContent: "space-between" }}
                    >
                        <Typography
                            id="modal-modal-title"
                            variant="h4"
                            component="h4"
                        >
                            Keyword cannibalization report
                        </Typography>
                        <div>
                            <Button
                                variant="outlined"
                                onClick={() => downloadCSV(data.rows, fileName)}
                                startIcon={<DownloadIcon />}
                            >
                                Download
                            </Button>
                            <CloseIcon
                                onClick={handleClose}
                                sx={{ cursor: "pointer", ml: 2 }}
                            />
                        </div>
                    </div>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        Keyword cannibalization level is {data.type || ""}.
                        These are all URLs that are being cannibalized, meaing
                        that they all rank for the same keyword. Consider
                        restructuring your website, consolidate your content or
                        create new landing pages.
                    </Typography>
                    {data.rows.length && (
                        <CustomReactTable
                            data={data.rows}
                            columns={columns}
                            id="top-level-voltage-table"
                        />
                    )}
                </Box>
            </Modal>
        </div>
    )
}
