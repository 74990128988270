export const ADMIN_EMAILS = [
    "marija@hvseo.co",
    "maria@pageoptimizer.pro",
    "marijadubretic@gmail.com",
    "zhaoweiqi0408@outlook.com",
    'asadjoiya@gmail.com',
    'asadsmalik91@gmail.com',
]

export const NORMAL_USERS = [
    "jomar@hvseo.co",
    "rakibul@hvseo.co",
    "luka@hvseo.co",
    "admin@hvseo.co",
    "julia@hvseo.co",
    "jessica@hvseo.co",
    "karth@hvseo.co",
    "neelima@hvseo.co",
    "laura@hvseo.co",
]