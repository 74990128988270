import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { Box, DialogActions, FormControl, Grid, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

type OptionType = {
    label: string,
    value: string,
}
export type FilterDataType = {
    optionValue: string,
    pageUrl: string
}
const FILTER_OPTIONS: OptionType[] = [
    {
        label: 'URLs containing',
        value: 'CONTAINS',
    },
    {
        label: 'URLs not containing',
        value: 'NOT_CONTAINS',
    },
    {
        label: 'Exact URL',
        value: 'EQUALS',
    },
    {
        label: "Custom regex (doesn't match)",
        value: 'EXCLUDING_REGEX',
    },
    {
        label: "Custom regex (match)",
        value: 'INCLUDING_REGEX',
    },
]

export interface PageFilterDialogProps {
    filterData: FilterDataType;
    onApply: (data: FilterDataType) => void;
}

function PageFilterDialog(props: PageFilterDialogProps) {
    const { onApply, filterData } = props;

    const [selectedOptionValue, setSelectedOptionValue] = useState(filterData?.optionValue)
    const [pageUrl, setPageUrl] = useState(filterData?.pageUrl)
    useEffect(() => {
        if (filterData) {
            setSelectedOptionValue(filterData.optionValue)
            setPageUrl(filterData.pageUrl)
        } else {
            setSelectedOptionValue(null)
            setPageUrl('')
        }
    }, [filterData])


    const validData = pageUrl?.trim().length > 0 && selectedOptionValue.length > 0
    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <FormControl fullWidth size='small' variant='outlined'>
                        <InputLabel id="filtertype_select" >Filter Type</InputLabel>
                        <Select
                            value={selectedOptionValue}
                            label="Filter Type"
                            labelId='filtertype_select'
                            onChange={(event: SelectChangeEvent) => {
                                onApply({
                                    optionValue: event.target.value as string,
                                    pageUrl: pageUrl
                                })
                            }}
                        >
                            {FILTER_OPTIONS.map((option, key) => (
                                <MenuItem value={option.value} key={key}>{option.label}</MenuItem>
                            ))}

                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField variant='outlined' size='small' label={'Filter Text'} value={pageUrl} fullWidth
                        
                        onChange={(e) => {
                            onApply({
                                optionValue: selectedOptionValue,
                                pageUrl: e.target.value
                            })
                        }}
                    />
                </Grid>
            </Grid>
        </Box>
    );
}

export default function PageDimension({
    onApplyPageFilter
}: {
    onApplyPageFilter: (data: FilterDataType) => void;
}) {
    const [open, setOpen] = useState(false);
    const [filterData, setFilterData] = useState<FilterDataType>(null)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const onApplyFilter = (data: FilterDataType) => {
        setOpen(false);
        setFilterData(data)
        onApplyPageFilter(data)
    };
    const getLabel = () => {
        if (filterData) {
            const option = FILTER_OPTIONS.filter(f => f.value == filterData.optionValue)[0]
            return `${option.label}: ` + filterData.pageUrl
        } else {
            return "New"
        }
    }
    return (
        <Box>
            <Typography variant='h6' color='primary.dark'>
                Refine your results with a Page Filter:
            </Typography>
            <br />
            <PageFilterDialog
                filterData={filterData}
                onApply={(data) => onApplyFilter(data)}
            />
        </Box>
    );
}
