import { Paper, Typography, Grid, Box } from "@mui/material";
import googleBox from "assets/images/google-anlytics-box.svg";
import wwwBox from "assets/images/w3-box.svg";
import orangeBoxIcon from "assets/images/orange-box-icon.svg";

const Scenarios = ({ selectedBox, setSelectedBox }) => {
  const boxData = [
    {
      id: 1,
      image: googleBox,
      title: "Scenario 1",
      description: "I have access to GSC data and can connect it to POP",
    },
    {
      id: 2,
      image: wwwBox,
      title: "Scenario 2",
      description:
        "I don't have access to GSC data, I just have the domain name",
    },
  ];

  return (
    <Box sx={{ padding: 2 }}>
      <Typography
        variant="h6"
        align="left"
        gutterBottom
        sx={{ fontSize: "25px", fontWeight: 800, color: "#014751" }}
      >
        Keyword Gap Research <img src={orangeBoxIcon} alt="orangeBoxIcon" />
      </Typography>
      <Grid container spacing={2}>
        {boxData.map((box) => (
          <Grid item xs={6} key={box.id}>
            <Paper
              variant="outlined"
              sx={{
                padding: 5,
                display: "flex",
                cursor: "pointer",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                borderColor: selectedBox === box.id ? "#FF5700" : "#CCCCCC",
                height: "171.16px",
                borderRadius: "5.39px",
              }}
              onClick={() => setSelectedBox(box.id)}
            >
              <img src={box.image} alt={`Box ${box.id}`} />
              <Typography
                variant="subtitle1"
                align="center"
                gutterBottom
                sx={{ fontSize: "16px", fontWeight: 800, color: "#014751" }}
              >
                {box.title}
              </Typography>
              <Typography
                variant="body2"
                align="center"
                sx={{ fontSize: "14px", fontWeight: 500, color: "#014751" }}
              >
                {box.description}
              </Typography>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Scenarios;
