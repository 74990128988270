import { useEffect, useState } from 'react';

import Axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginAction, logoutAction, } from 'redux/reducers/user';
import { Box, Button, Paper, TextField, Container, Typography, Alert } from '@mui/material';
import { isEmail } from 'validator'

function RegisterPage() {

  const navigate = useNavigate();
  const dispatch = useDispatch();


  const [name, setName] = useState('')
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const [emailSent, setEmailSent] = useState(false)

  useEffect(() => {
    dispatch(logoutAction())
  }, [])

  const onSubmit = async () => {
    if (password.length < 6) {
      alert('Password should be at least 6 letters')
      return
    }
    if (password != passwordConfirm) {
      alert('Confirm password mismatched')
      return
    }
    if (!isEmail(email)) {
      alert('Invalid Email')
      return
    }
    const data = { name, email, password }
    const response = await Axios.post('/api/auth/register', data);
    if (response.status === 200) {
      setEmailSent(true)
    } else {
      alert(response.data.msg)
    }
  }

  return (
    <>
      <div className='welcome-message'>
        👋 Welcome to POP Utils 👋
      </div>
      <Container maxWidth='sm'>
        <Paper >

          {
            emailSent && <Box p={2}>
              <Alert severity="success">Successfully registered! please confirm email.</Alert>
            </Box>
          }
          {!emailSent && <Box p={2}>
            <TextField
              variant='filled'
              label='Name:'
              value={name}
              fullWidth
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              variant='filled'
              label='Email:'
              fullWidth
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant='filled'
              fullWidth
              label='Password:'
              type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TextField
              variant='filled'
              fullWidth
              type='password'
              label='Confirm Password:'
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
            <Box my={6}>
              <Button variant='contained' fullWidth onClick={onSubmit}>Register</Button>
            </Box>
          </Box>}
        </Paper>
      </Container>
    </>
  );
}

export default RegisterPage