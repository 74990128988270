import { useEffect, useState } from 'react';
import EmailField from '../common/EmailField';
import PasswordField from '../common/PasswordField';

import Axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fetchUserThunk, loginAction, logoutAction, } from 'redux/reducers/user';
import { Box, Button, Container, Grid, Paper, TextField, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';

function LoginPage() {

  const navigate = useNavigate();
  const dispatch = useDispatch();



  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMsg, setErrorMsg] = useState(null)

  useEffect(() => {
    dispatch(logoutAction())
  }, [])

  async function handleLogin() {
    const data = { email, password }
    const response = await Axios.post('/api/auth/login', data);
    if (response?.status === 200) {
      dispatch(loginAction(response.data));
      navigate('/')
    } else {
      setErrorMsg(`There was an error logging you in. Please check that your password is correct. If you haven’t created an account yet, please register. If you have created an account but haven’t confirmed your email, please look for a registration confirmation email in your inbox and click the confirmation link.`)
    }
  }

  return (
    <>
      <Container maxWidth='sm'>
        <Paper >
          <Box p={3}>
            <Box my={3}>
              <Typography variant='h4'>
                👋 Welcome to POP Utils 👋
              </Typography>
            </Box>
            {
              errorMsg && (<Box my={2}>
                <Alert severity="error">{errorMsg}</Alert>
              </Box>
              )
            }
            <Box>
              <Grid container spacing={3}>
                <Grid item md={12}>
                  <TextField
                    fullWidth
                    label='Email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item md={12}>
                  <TextField
                    fullWidth
                    label='Password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type='password'
                  />
                </Grid>
                <Grid item md={12}>
                  <Button onClick={handleLogin} color='primary' variant='contained' sx={{ mr: 2 }}>Login</Button>

                  <Button href='/register'>Register</Button>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Paper>
      </Container>
    </>
  );
}

export default LoginPage