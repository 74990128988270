import { Box, Button, CircularProgress, Container, TextField, Typography } from '@mui/material'
import ShadowBox from 'components/ShadowBox'
import React, { useEffect, useState } from 'react'
import { CallApi, checkDomainMatch } from 'utils';

const defaultQuestions = `Question1: Is there any address in content?
Question2: What is the copyright year in this content?
Question3: Does this content have cookie policy?
Question4: Based on standard English grammar rules, is there any error in the following content? Answer with Yes or No.
Question5: Common helpful or beneficial content purposes include (but are not limited to):
 ● To share information about a topic;
 ● To share a personal experience, perspective, or feelings on a topic;
 ● To share pictures, videos, or other forms of media;
 ● To demonstrate a personal talent or skill;
 ● To express an opinion or point of view;
 ● To entertain;
 ● To offer products or services;
 ● To allow users to post questions for other users to answer;
 ● To allow users to share files or to download software. 
Can you tell if this content is helpful or not? Answer with Yes or No.
Question6: Based on those informations:
If you are having trouble deciding whether a topic is YMYL, consider the following questions:
a. Would a careful person seek out experts or highly trusted sources to prevent harm ? Could even minor inaccuracies cause harm? If yes, then the topic is likely YMYL.
b. Is the specific topic one that most people would be content with only casually consulting their friends about? If
 yes, the topic is likely not YMYL. 
Can you tell if this content is YMYL content or not?
Answer each question one by one.
`
const defaultUrls = 'https://hvseo.co'

export default function OpenAITest() {
  const [questions, setQuestions] = useState(defaultQuestions)
  const [urls, setUrls] = useState(defaultUrls)
  const [html, setHtml] = useState('')
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState('')
  const [totalTokens, setTotalTokens] = useState(0)
  const [apiCalls, setApiCalls] = useState(0)

  useEffect(() => {
    loadDefaultData()
  }, [])

  const loadDefaultData = () => {
    setQuestions(defaultQuestions)
    setUrls(defaultUrls)
  }
  const urlsArray = urls.split('\n').filter(item => item.trim().length > 0);
  const readyForSubmit = !loading && urlsArray.length > 0 && html

  const submit = () => {
    setLoading(true)
    CallApi({
      method: 'post',
      url: '/api/openai/new_test',
      data: {
        questions,
        html
      }
    }).then((res) => {
      setResult(res.result)
      setTotalTokens(res.totalTokens)
      setApiCalls(res.apiCalls)
    })
    .catch((error) => {
      console.log('failed to load', error)
    })
    .finally(() => {
      setLoading(false)
    })
  }

  return <Container maxWidth="xl">
    <ShadowBox>
      <Typography variant='h4'>OpenAI Integration Test Interface</Typography>
      <Box my={2}>
        <Box>
          <TextField
            value={questions}
            onChange={(e) => setQuestions(e.target.value)}
            rows={10}
            multiline
            fullWidth
            label="You dont't have to add some new lines at the end of this box.">
          </TextField>
        </Box>
      </Box>

      <Box my={2}>
        <Box>
          <TextField
            value={html}
            onChange={(e) => setHtml(e.target.value)}
            rows={10}
            multiline
            fullWidth
            label="html source code here.">

          </TextField>
        </Box>
      </Box>
      <Box my={2}>
        <Box display='flex'>
          <Button disabled={!readyForSubmit} onClick={submit} color='info' variant='contained'>
            Submit 
            {loading && <CircularProgress size={20} sx={{
              marginLeft: 1,
              color: 'white'
            }} />}
          </Button>
          <Button onClick={loadDefaultData} color='info' >Load Default</Button>
        </Box>
      </Box>

      <Box my={2}>
        <Typography variant='h4'>Results</Typography>
        {result && <Box>
          <Box>
            Total Tokens: {totalTokens} <br />
            API Calls: {apiCalls} <br />
          </Box>
          <Box>
            <Box>
              <p>OpenAI Output</p>
              <Button ></Button>
            </Box>
            <Box>
              Preview:
              <pre>
                {result}
              </pre>
              Raw Data:
              <Box>
                {result}
              </Box>
            </Box>
          </Box>
        </Box>}
      </Box>
    </ShadowBox>
  </Container>
}

