import { Tab, Tabs, Button, Typography, Box } from "@mui/material";
import watchIcon from "assets/images/watch-icon.svg";
import arrowDownIcon from "assets/images/arrow-down-icon.svg";
import TabBadge from "./TabBadge";

function abbreviateNumber(number) {
  if (number >= 1e6) {
    return (number / 1e6).toFixed(1) + "M";
  } else if (number >= 1e3) {
    return (number / 1e3).toFixed(1) + "K";
  } else {
    return number.toLocaleString();
  }
}

function TableTabs({ tabStats, tab, setTab }) {
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Typography
          variant="subtitle1"
          sx={{ fontSize: "22px", fontWeight: 800, color: "#014751" }}
        >
          Keyword Gap Overview
        </Typography>
        <Button
          startIcon={<img src={watchIcon} alt="Download" />}
          variant="outlined"
          sx={{
            textTransform: "none",
            ml: 2,
            borderColor: "#014751",
            color: "#014751",
            backgroundColor: "white",
            fontWeight: 500,
            fontSize: "14px",
            height: "33px",
            borderRadius: "5px",
          }}
          endIcon={<img src={arrowDownIcon} alt="Arow Down" />}
        >
          Recent searches
        </Button>
      </Box>
      <Box
        sx={{
          width: "100%",
          mb: 5,
          mt: 2,
          borderBottom: "1px solid #CCCCCC",
        }}
      >
        <Tabs
          value={tab}
          onChange={(_, newVal) => setTab(newVal)}
          aria-label="basic tabs example"
          TabIndicatorProps={{
            style: { backgroundColor: "#FF5700" },
          }}
        >
          <Tab
            label={
              <TabBadge
                label="Shared"
                count={abbreviateNumber(tabStats.shared || 0)}
              />
            }
          />
          <Tab
            label={
              <TabBadge
                label="Missing"
                count={abbreviateNumber(tabStats.missing || 0)}
              />
            }
          />
          <Tab
            label={
              <TabBadge
                label="Untapped"
                count={abbreviateNumber(tabStats.untapped || 0)}
              />
            }
          />
          <Tab
            label={
              <TabBadge
                label="All keywords"
                count={abbreviateNumber(tabStats.all || 0)}
              />
            }
          />
        </Tabs>
      </Box>
    </>
  );
}

export default TableTabs;
