import { createSlice } from '@reduxjs/toolkit';
import Axios from 'axios';
import _ from 'lodash'
import { CallApi } from 'utils';

export interface UserType {
  token: string,
  user: any,
  googleUser: any
}

const initialState: UserType = {
  token: null,
  user: null,
  googleUser: null
}
export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginAction: (state, action) => {
      Axios.defaults.headers.common['Authorization'] = `Bearer ${action.payload.token}`;
      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user,
      };
    },
    fetchUserAction: (state, action) => {
      return {
        ...state,
        user: action.payload,
      };
    },
    logoutAction: (state) => {
      return {
        ...state,
        token: null,
        user: null
      }
    },
    updateGoogleUser: (state, action) => {
      return {
        ...state,
        googleUser: action.payload
      }
    }
  },
});

export const {
  loginAction,
  logoutAction,
  fetchUserAction,
  updateGoogleUser
} = slice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
// export const incrementAsync = amount => dispatch => {
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount));
//   }, 1000);
// };

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

export default slice.reducer;

export const fetchUserThunk = () => async dispatch => {
  // redux thunk sees that the action returned
  // a function and it will call it passing
  // in a dispatch
  CallApi({
    url: "/api/auth/user",
    method: 'get'
  }).then((response) => {
    dispatch(fetchUserAction(response));
  })

};

