import * as echarts from 'echarts/core';
import ReactECharts from 'echarts-for-react';
import { Box } from '@mui/material';
// import { Canvas, Edge, Label } from "reaflow";

// register theme object
echarts.registerTheme('my_theme', {
  backgroundColor: 'white'
});

const graphOptions = {
  tooltip: {
    show: false,
  },
  animationDurationUpdate: 1500,
  animationEasingUpdate: 'quinticInOut',
  series: [
    {
      type: 'graph',
      layout: 'none',
      symbol: 'circle',
      symbolSize: 20,
      roam: true,
      label: {
        show: true,
        backgroundColor: 'white',
        padding: 10,
        position: 'left',
        borderRadius: 10,
        shadowBlur: 2,
        overflow: 'break',
        width: 200
      },
      edgeSymbol: ['none', 'arrow'],
      edgeSymbolSize: [4, 10],
      edgeLabel: {
        fontSize: 12
      },
      data: [],
      links: [],
      lineStyle: {
        opacity: 0.9,
        width: 2,
        curveness: 0
      },
    }
  ]
};

export default (props) => {

  const nodes = props.nodes || []
  const edges = props.edges || []

  const echartsOptions = JSON.parse(JSON.stringify(graphOptions))
  echartsOptions.series[0].data = nodes;
  echartsOptions.series[0].links = edges;

  return (<Box sx={{backgroundColor: 'white'}}>
    <ReactECharts
      option={echartsOptions}
      theme='my_theme'
      ref={props.chartRef}
      style={{ width: '100%', height: '100vh' }}
    />
  </Box>
  )
}