
import VOLTAGE_LEVEL from './VoltageLevel.json'

export const getVoltageLevelLabel = (avgImpressions: number): {
    from: number,
    to: number,
    label: string
} => {
    if (!avgImpressions) return null
    const avg = avgImpressions
    const filteredVoltageLevel = VOLTAGE_LEVEL.filter(v => {
        if (v.from <= avg && avg < v.to) {
            return true
        } else {
            return false
        }
    })[0]
    return filteredVoltageLevel
}