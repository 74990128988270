import { Alert } from 'react-bootstrap'
import _ from 'lodash'
import { Box, Button, Typography } from '@mui/material'

export interface ProgressObjType {
  msg: string,
  value: number
}
export default function ({
  progressObj,
  onPause
}: {
  progressObj: ProgressObjType,
  onPause: () => void
}) {
  const message = progressObj?.msg || ''
  const percentage = Math.min(100, progressObj?.value || 0)
  if (!progressObj) {
    return null
  }
  return (
    <Box sx={{
      backgroundColor: 'rgba(234, 239, 237, 1)',
      borderRadius: '4px',
    }}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <Typography color='success.main' sx={{
          p: 1
        }}>
          {message}
        </Typography>
        <Button
          onClick={onPause}
          variant="contained"
          size='small'
          sx={{
            mr: 1
          }}
        >
          Pause
        </Button>
      </Box>

      <Box sx={{
        width: percentage + '%',
        height: '4px',
        backgroundColor: 'success.main'
      }}>
      </Box>
    </Box>
  )
}