
import axios from "axios";
import { useEffect, useState } from "react";


import { Box } from '@mui/material';
import { CallApi } from 'utils';
import KeywordsResearchResult from 'views/Pages/Campaign/tabs/KeywordsResearch/KeywordsResearchResult';


type PropTypes = {
  isActive: boolean,
  campaignId: string
}
export default function ({ isActive, campaignId }: PropTypes) {

  const [research_id, setResearch_id] = useState(0)

  const [currentReport, setCurrentReport] = useState(null);
  const [researchHistory, setResearchHistory] = useState([])

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (campaignId) {
      loadData()
    }
  }, [campaignId, research_id])
  const loadData = () => {
    loadSupportResearchHistoryForCampaign()
    loadSupportingKeywordResearchResult()
  }
  const loadSupportResearchHistoryForCampaign = () => {
    axios.get(`/api/support_keyword/history/campaign/${campaignId}`).then((res) => {
      setResearchHistory(res.data)
    })
  }
  const loadSupportingKeywordResearchResult = () => {
    CallApi({
      url: `/api/support_keyword/get_keyword_result/${campaignId}/${research_id}`,
      method: 'get'
    })
      .then((response) => {
        if (response) {
          setCurrentReport(response)
        }
      })
      .catch((error) => {
        console.log('failed to load', error)
      });
  }


  const onDeleteAllKeywordData = () => {
    if (currentReport) {
      axios
        .delete(`/api/support_keyword/delete_all_keywords_data/${campaignId}`)
        .then((res) => {
          loadSupportingKeywordResearchResult()
        })
        .catch((error) => {
          console.log('failed to load', error)
        });
    }
  }
  const onChangeResearchId = (research_id: number) => {
    setResearch_id(research_id)
  }

  return (
    <Box>
      {currentReport && (
        <KeywordsResearchResult
          onChangeResearchId={onChangeResearchId}
          campaignId={Number(campaignId)}
          report={currentReport}
          calculating={isLoading}
          researchHistory={researchHistory}
          id='supporting_keyword_research'
          onDeleteAllKeywordData={onDeleteAllKeywordData}
        />
      )}
    </Box>
  )
}
