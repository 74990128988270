import { useState, useEffect, useMemo } from "react";
import { useDispatch } from 'react-redux';
import * as React from 'react';
import { Form, Modal, Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTable, usePagination, useSortBy } from "react-table";
import styled from "styled-components";

import {
  showLoadingIndicator, hideLoadingIndicator
} from 'redux/reducers/appstate'
import Axios from "axios";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { CallApi } from "utils";
import CustomReactTable from "components/ReactTable";
import { ColumnDef } from "@tanstack/react-table";
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import HistoryTab from "./HistoryComponents/HistoryTab";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const loadUsers = async () => {
  try {
    return await Axios.get('/api/user/all-users')
  } catch (e) {
    throw e;
  }
}



type PropTypes = {
  isActive: boolean
}
export default function ({ isActive }: PropTypes) {

  const dispatch = useDispatch();
  const [reportHistory, setReportHistory] = useState([])
  const [selectedCampaign, setSelectedCampaign] = useState(null)
  const [showcampaignModal, setShowcampaignModal] = useState(false)
  const onClickReport = (campaign) => {
    setSelectedCampaign(campaign);
    setShowcampaignModal(true)
  }
  const memoizedColumns = useMemo<ColumnDef<any>[]>(() => [
    {
      header: 'ID',
      accessorKey: 'id',
      enableColumnFilter: false
    },
    {
      header: 'Email',
      accessorKey: 'email',
      enableColumnFilter: false
    },
    {
      header: 'Created At',
      accessorKey: 'created_at',
      enableColumnFilter: false
    },
    {
      header: 'IP',
      accessorKey: 'login_ip',
      enableColumnFilter: false
    },
    {
      header: 'Action',
      accessorKey: 'no',
      cell: ({ row: { original } }) => (<Box>
        <Button variant="contained" onClick={() => onClickReport(original)} sx={{ mr: 1 }}>Report</Button>
      </Box>),
      enableColumnFilter: false
    },


  ], [])

  useEffect(() => {
    CallApi({
      url: '/api/admin/report_history'
    }).then((res) => {
      const hist = res.campaigns.map(r => {
        return {
          id: r.campaign.id,
          campaign_name: r.campaign.campaign_name,
          created_at: r.campaign.created_at,
          email: r.user.email,
          ip: r.user.ip
        }
      })
      setReportHistory(hist)
    })
  }, []);




  return (
    <Box>

      <Container maxWidth="xl">
        <Box sx={{
          backgroundColor: 'white', p: 2,
          boxShadow: '0px 4px 0px rgba(81, 72, 251, 0.2);',
          borderRadius: '0 0 8px 8px'
        }}>
          <Grid container spacing={2} my={2}>
            <Grid item xs={12} md={8}>
              <Typography variant="h5" color='primary.dark'>Report History</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
            </Grid>
          </Grid>

          <CustomReactTable
            data={reportHistory}
            columns={memoizedColumns}
            id="top-level-voltage-table-site-only"
          />
        </Box>
      </Container>
      <Dialog
        fullScreen
        open={showcampaignModal}
        onClose={() => setShowcampaignModal(false)}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setShowcampaignModal(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {selectedCampaign?.campaign_name}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box>
          <HistoryTab campaignId={selectedCampaign?.id} />
        </Box>
      </Dialog>
    </Box>
  )
}

