import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationsData from '../utils/locations.json'
import { useSelector, useDispatch } from "react-redux";
import { LocationModel } from 'Models/LocationModel';
import { Divider } from '@mui/material';


export default function CountrySelect({
    value, onChange, isMulti, placeholder, fullWidth
}: {
    value: number,
    onChange: (v: number) => void,
    isMulti: boolean,
    placeholder?: string,
    fullWidth?: boolean
}) {
    const locations = LocationsData.locations
    const v = locations.filter(l => l.location_code == value)
    return (
        <Autocomplete
            sx={{ minWidth: 300 }}
            options={locations}
            autoHighlight

            getOptionLabel={(option: LocationModel) => option.location_name}
            renderOption={(props, option: LocationModel) => (<>
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.location_name}
                </Box>
                {option.isDivider ? <Divider /> : null}
            </>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={placeholder || "Choose a country"}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                    size='small'
                />
            )}
            multiple={isMulti}
            value={locations.filter(l => l.location_code == value)[0]}
            onChange={(event: any, newValue: LocationModel) => {
                onChange(newValue.location_code);
            }}
            fullWidth={fullWidth}
        />
    );
}

