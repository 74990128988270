import { Box, Button, Container } from "@mui/material";
import Axios from "axios";
import toast from "react-hot-toast";
import keywordLang from 'assets/loclang/keywords_data_language.json'
import keywordLoc from 'assets/loclang/keywords_data_location.json'
import serpLang from 'assets/loclang/serp_google_language.json'
import serpLoc from 'assets/loclang/serp_google_location.json'
import dataforseoLocLang from 'assets/loclang/dataforseo_lang_loc.json'

export default function () {

    const onTestBacklinkEmail = () => {
        Axios.get('/api/backlink/check_for_backlink_update').then(() => {
            toast.success("Success")
        }).catch(() => {
            toast.error('Failed')
        })
    }
    const onTestTrackKeyword = () => {
        Axios.get('/api/tracking_keyword/check_for_track_result_update').then(() => {
            toast.success("Success")
        }).catch(() => {
            toast.error('Failed')
        });
    }
    const onGetCommonLangLoc = () => {
        // get common languages
        let commonLanguages = []
        for (let index = 0; index < keywordLang.length; index++) {
            const element = keywordLang[index];
            const existSerp = serpLang.find(i => i.language_code == element.language_code)
            const existDFS = dataforseoLocLang.find(i => i.language_code == element.language_code)
            existSerp && existDFS && commonLanguages.push(element)
        }
        // get common locations
        let commonLocations = []
        for (let index = 0; index < keywordLoc.length; index++) {
            const element = keywordLoc[index];
            const existSerp = serpLoc.find(i => i.location_code == element.location_code)
            const existDFS = dataforseoLocLang.find(i => i.location_code == element.location_code)
            existSerp && existDFS && commonLocations.push(element)
        }
        console.info('=== commonLanguages ===', JSON.stringify(commonLanguages, null, 4))
        console.info('=== commonLocations ===', JSON.stringify(commonLocations, null, 4))
        
    }
    return (<Box>

        <Container maxWidth="xl">
            <Box sx={{
                backgroundColor: 'white', p: 2,
                boxShadow: '0px 4px 0px rgba(81, 72, 251, 0.2);',
                borderRadius: '0 0 8px 8px'
            }}>
                <Box mb={2}>
                    <Button variant='contained' onClick={onTestBacklinkEmail}>Test Backlink Monitor</Button>
                </Box>
                <Box mb={2}>
                    <Button variant='contained' onClick={onTestTrackKeyword}>Test Track Keyword</Button>
                </Box>
                <Box mb={2}>
                    <Button variant='contained' onClick={onGetCommonLangLoc}>Get Common Language, Location</Button>
                </Box>
            </Box>
        </Container>
    </Box>)
}