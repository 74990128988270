import { Box, Container } from "@mui/material";
import { useEffect, useState } from "react";
import { Nav } from "react-bootstrap";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';

import Users from './Users';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { HideBox } from "components/HideBox";
import Test from "./Test";
import History from "./History";


const TAB_LIST = [
  {
    label: 'Users',
    value: 'users',
    component: Users
  },
  {
    label: 'History',
    value: 'history',
    component: History
  },
  {
    label: 'Test',
    value: 'test',
    component: Test
  },
]

export default function AdminDashboard() {

  const [currentTab, setCurrentTab] = useState<string>(TAB_LIST[0].value);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const tab = searchParams.get('tab')
    if (tab) {
      setCurrentTab(tab)
    }
  }, [searchParams])
  
  const handleChange = (event, newValue) => {
    navigate("?tab=" + newValue)
  };
  const renderTabHeader = () => {
    return <Tabs value={currentTab} onChange={handleChange} aria-label="basic tabs example">
      {
        TAB_LIST.map((t, key) => {
          return <Tab label={t.label} {...a11yProps(t.value)} value={t.value} />
        })
      }
    </Tabs>
  }

  const renderTabContents = () => {
    return TAB_LIST.map((t, key) => {
      return <HideBox hide={currentTab != t.value}> <t.component isActive={currentTab == t.value} /></HideBox>
    })
  }
  return (
    <Box>
      <Container maxWidth="xl">
        <Typography variant="h2">Admin Dashboard</Typography>
        <Box sx={{
          borderBottom: 1, borderColor: 'divider',
          backgroundColor: 'white',
          borderRadius: '8px 8px 0 0',
          mt: 2
        }}>

          {renderTabHeader()}

        </Box>
      </Container>

      <Box>
        {renderTabContents()}
      </Box>
    </Box>
  )
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}