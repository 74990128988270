export const BLUE_COLOR = '#5148FB'
export const DARK_BLUE_COLOR = '#002266'
export const NIGHT_BLUE_COLOR = '#013866'

export const LIGHT_GRAY_COLOR = '#f7f7f8'
export const GRAY_COLOR = '#838383'
export const DARK_GRAY_COLOR = '#4A4B4B'

export const ORANGE_COLOR = '#fead62'
export const LIGHT_ORANGE_COLOR = '#ffeddd'
export const DARK_ORANGE_COLOR = '#B38A18'     

export const GREEEN_COLOR = '#2B6349'
export const LIGHT_GREEN_COLOR = '#95B1A4'
export const DARK_GREEN_COLOR = '#0d2218'

export const NAVBAR_COLOR = '#2d2d2d'

export const BOX_SHADOW = '0px 4px 0px rgba(43, 99, 73, 0.2)'