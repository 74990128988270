import React, { Component } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Nav, Navbar } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useIsAuthenticated } from '../../utils';
import { useSelector } from 'react-redux'
import logo from 'assets/images/POP@4x 1.png';
import { styled, useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import AdbIcon from '@mui/icons-material/Adb';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DailyQuotaRemaining from './DailyQuotaRemaining';
import { NAVBAR_COLOR } from 'theme/colors';
import {RootState} from "../../redux/store";
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const Header = () => {
  const user = useSelector((state: RootState) => state.user)
  const isAuthenticated = useIsAuthenticated()
  const is_admin = user.user?.is_admin
  const navigate = useNavigate()

  const theme = useTheme()

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);


  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const renderNavLinks = () => {
    if (isAuthenticated) {
      return (
        <>
          <Nav>
            <Nav.Link as={NavLink} to='/' >Home</Nav.Link>
            {user.user?.is_admin && <Nav.Link as={NavLink} to='/admin'>Admin</Nav.Link>}
            <Nav.Link as={NavLink} to='/profile'>Profile</Nav.Link>
            <Nav.Link as={NavLink} to='/logout'>Logout</Nav.Link>
          </Nav>
          <Navbar.Collapse className="justify-content-end">
            {/* <Nav.Link as={NavLink} to='/old-menus'>Old Menu</Nav.Link> */}
          </Navbar.Collapse>
        </>
      );
    }
    return (
      <Nav>
        <Nav.Link as={NavLink} to='/login'>Login</Nav.Link>
      </Nav>
    );
  }
  const onGoAdmin = () => {
    navigate('/admin')
    handleCloseNavMenu()
  }
  return (
    <AppBar position="fixed" sx={{ backgroundColor: NAVBAR_COLOR }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img src={logo} height={'36px'} />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {is_admin && <MenuItem onClick={onGoAdmin}>
                <Typography textAlign="center">Admin</Typography>
              </MenuItem>}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            <img src={logo} height={'36px'} />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { display: 'flex', xs: 'none', md: 'flex', alignSelf: 'flex-end' }, justifyContent: 'center' }}>
            {/* {isAuthenticated && <DailyQuotaRemaining />} */}
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'flex', } }}>
            {is_admin && <Button
              onClick={onGoAdmin}
              sx={{ mx: 4, color: 'white', display: 'block' }}
            >
              Admin
            </Button>}
          </Box>

          {isAuthenticated && <Box sx={{ flexGrow: 0 }}>
            <Button variant="text" onClick={handleOpenUserMenu} sx={{ p: 0, color: 'white' }} endIcon={<KeyboardArrowDownIcon />}>My Account</Button>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={() => {
                navigate('/profile')
                handleCloseUserMenu()
              }}>
                <Typography textAlign="center">Profile</Typography>
              </MenuItem>
              <MenuItem onClick={() => {
                navigate('/login')
                handleCloseUserMenu()
              }}>
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            </Menu>
          </Box>}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default (Header);
