import { Box, Button, Typography } from "@mui/material"
import { useMemo, useState } from "react";
import { BackLinkModel } from "Models/BacklinkModel";
import ReactTable from "components/ReactTable";
import {
    Column,
    Table,
    PaginationState,
    useReactTable,
    getCoreRowModel,
    getFilteredRowModel,
    getPaginationRowModel,
    ColumnDef,
    OnChangeFn,
    flexRender,
} from '@tanstack/react-table'

export const BacklinksTable = (props) => {
    const { backLinks, onMonitorBackLink }: { backLinks: BackLinkModel[], onMonitorBackLink: (link: BackLinkModel) => void } = props

    const memoizedColumns = useMemo<ColumnDef<BackLinkModel>[]>(() => [
        // {
        //     header: 'Monitor',
        //     id: 'action',
        //     cell: props => <Button onClick={() => {
        //         onMonitorBackLink(props.row.original)
        //     }} color='primary'>Monitor</Button>,
        //     enableColumnFilter: false
        // },
        {
            header: 'Backlink URL',
            accessorKey: 'back_link',
            cell: info => <a href={info.getValue() as string} target='_blank'>{info.getValue() as string}</a>,
            id: 'back_link',
            enableColumnFilter: false
        },
        {
            header: 'Link URL',
            accessorKey: 'anchor_url',
            cell: info => <a href={info.getValue() as string} target='_blank'>{info.getValue() as string}</a>,
            id: 'anchor_url',
            enableColumnFilter: false
        },
        {
            header: 'Anchor Text',
            accessorKey: 'anchor_txt',
            id: 'anchor_txt',
            enableColumnFilter: false
        },
        {
            header: 'NoFollow/DoFollow',
            accessorKey: 'do_follow',
            id: 'do_follow',
            cell: info => info.getValue() == 1 ? 'DoFollow' : 'NoFollow',
            enableColumnFilter: false
        },
        {
            header: 'First Time Seen',
            accessorKey: 'first_seen_at',
            id: 'first_seen_at',
            enableColumnFilter: false
        },
        {
            header: 'Is Lost',
            accessorKey: 'is_lost',
            id: 'is_lost',
            cell: info => info.getValue() == 1 ? 'YES' : 'NO',
            enableColumnFilter: false
        },
    ], []);


    return <Box>
        <ReactTable
            data={backLinks}
            columns={memoizedColumns}
            id="backlink-result-table"
        />
    </Box>
}