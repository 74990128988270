import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import {
    Modal,
    Form,
    Button,
} from 'react-bootstrap';


import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import KeywordsResearch from "views/Pages/Campaign/tabs/KeywordsResearchTab"
import SupportingKeywordResearch from 'views/Pages/Campaign/tabs/SupportingKeywordResearchTab';
import KeywordTracking from 'views/Pages/Campaign/tabs/KeywordTrackingTab';
import SILO from 'views/Pages/Campaign/tabs/SILOTab';
import BackLinksTab from 'views/Pages/Campaign/tabs/BackLinksTab';
import TrackPredictionTab from 'views/Pages/Campaign/tabs/ContentAuditTab';
import { loadSiteThunk } from 'redux/reducers/formdata';
import TopLevelPage from 'views/Pages/Campaign/tabs/TopLevelPage';
import { ADMIN_EMAILS } from 'Models/Constants';
import { addCampaignEnquiryHistory } from 'redux/reducers/appdata';
import BreadCrumbs from 'components/BreadCrumbs';
import { CallApi } from 'utils';
import { Container } from '@mui/system';
import { PackageType } from 'Models/Package';
import KeywordResearchTabContent from 'views/admin/HistoryComponents/KeywordResearchTabContent';
import SupportingKeywordResearchTabContent from 'views/admin/HistoryComponents/SupportingKeywordResearchTabContent';
import { HideBox } from 'components/HideBox';
const TAB_LIST = [
    {
        label: 'Keyword Research ',
        value: 'get_data_for_keyword',
        component: KeywordResearchTabContent
    },
    {
        label: 'Supporting Keyword Research',
        value: 'supporting_keyword_research',
        component: SupportingKeywordResearchTabContent,
    },
]
export default function ({
    campaignId
}: {
    campaignId: string
}) {
    const [currentTab, setCurrentTab] = useState<string>(TAB_LIST[0].value);


    const handleChange = (event, newValue) => {
        setCurrentTab(newValue)
    };
    const renderTabHeader = () => {
        return <Tabs value={currentTab} onChange={handleChange} aria-label="basic tabs example" >
            {
                TAB_LIST.map((t, key) => {
                    return <Tab label={t.label} {...a11yProps(t.value)} value={t.value} />
                })
            }
        </Tabs>
    }
    const renderTabContents = () => {
        if (!campaignId) return null
        return TAB_LIST.map((t, key) => {
            return <HideBox hide={currentTab != t.value}> <t.component isActive={currentTab == t.value} campaignId={campaignId} /></HideBox>
        })
    }
    return <Box>
        <Container maxWidth="xl">
            <Box sx={{
                borderBottom: 1, borderColor: 'divider',
                backgroundColor: 'white',
                borderRadius: '8px 8px 0 0',
                mt: 2,
                pt: 1
            }}>
                {renderTabHeader()}
            </Box>
        </Container>
        <Box>
            {renderTabContents()}
        </Box>
    </Box>
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        sx: {
            fontSize: '12px'
        }
    };
}