import { Box } from "@mui/material";
import { useEffect, useRef, useState } from "react";

export default function (props) {
    const boxRef = useRef(null)
    const [boxWidth, setBoxWidth] = useState(800)

    useEffect(() => {
        if (boxRef && boxRef.current) {
            setBoxWidth(boxRef.current.offsetWidth);
        }
    });

    const handleMasterScroll = (e) => {
        let slave = e.target.parentNode.querySelector(".scroll_slave");
        let master = e.target;
        let newSlavePosition = master.scrollLeft 
        slave.scroll({
            left: newSlavePosition
        });
    };

    const handleSlaveScroll = (e) => {
        let slave = e.target;
        let master = e.target.parentNode.querySelector(".scroll_master");
        let newMasterPosition = slave.scrollLeft
        master.scroll({
            left: newMasterPosition
        });
    };
    return <Box sx={{}}>

        <Box sx={{ overflowX: 'auto', }} onScroll={handleMasterScroll} className="scroll_master">
            <Box sx={{ height: '1px', width: boxWidth, background: 'transparent' }}>
            </Box>
        </Box>
        <Box sx={{ overflowX: 'auto' }} onScroll={handleSlaveScroll} className="scroll_slave">
            <Box ref={boxRef} sx={{ width: 'fit-content', minWidth: '100%' }}>
                {props.children}
            </Box>
        </Box>
    </Box>
}