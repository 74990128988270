import { Box, Typography, Button, Grid, Container } from "@mui/material"
import { ColumnDef } from "@tanstack/react-table"
import CustomReactTable from "components/ReactTable"
import { useEffect, useMemo, useState } from "react"
import { getVoltageLevelLabel } from "utils/voltage"
import Alert from '@mui/material/Alert';
import { useParams } from "react-router-dom"
import { CallApi } from "utils"
import toast from "react-hot-toast"
import BoltIcon from '@mui/icons-material/Bolt';
import TablePdfCSVDownload from "components/TablePdfCSVDownload"
import { LanguageModel } from "Models/LanguageModel"
import { CannibalizationModal } from "components/KeywordCannibalizationModal";
import $ from "lodash"

export type GoogleConsoleData = {
    page: string
    keyword: string
    clicks: number
    impressions: number
    ctr: number
    position: number
    keywordCannibalizationText?: string
    cannibalizedKeywords?: []
}
type PropTypes = {
    data: GoogleConsoleData[],
    language: LanguageModel,
    country: number,
    domain: string
}

export default function VoltageTable({ data, language, country, domain }: PropTypes) {

    const params = useParams()
    const { campaignId } = params
    const [rankingKeywords, setRankingKeywords] = useState([])

    const [tableData, setTableData] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [modalData, setModalData] = useState({ rows: [], type: "" })
    const [avgImpression, setAvgImpression] = useState(0)
    const [voltageImpression, setVoltageImpression] = useState(0)
    useEffect(() => {
        if (data) {
            setTableData(data)
            const $avg = data.reduce((a, b) => a + b.impressions / data.length, 0)
            setAvgImpression($avg)

            const allImpressions = data.map(g => g.impressions)
            const maxImp = Math.max(...allImpressions)
            const minImp = Math.min(...allImpressions)

            const $voltage_avg = (maxImp + minImp) / 2;
            setVoltageImpression($voltage_avg)

        } else {
            setTableData([])
        }
    }, [data])

    useEffect(() => {
        if (!campaignId) {
            return;
        }
        getRankedKeywords()
    }, [campaignId])
    const getRankedKeywords = () => {
        CallApi({
            method: 'get',
            url: `/api/tracking_keyword/get_keyword_tracking/${campaignId}`
        }).then((response) => {
            if (response.report) {
                setRankingKeywords(response.report.track_keywords || [])
            }
        })
        .catch((error) => {
            console.log('failed to load', error)
        });
    }

    const trackThisKeyword = (item) => {
        if (!language) {
            toast.error('Please choose language')
            return
        }
        const language_code = language.language_code
        const location_code = country
        const formdata = {
            campaignId: campaignId,
            track_keyword: item.keys[0],
            language_code,
            location_code,
            domain
        }
        setRankingKeywords([...new Set([...rankingKeywords, item.keyword])])
        toast.success('Success: It will be added in a few minutes later!')

        CallApi({
            url: '/api/tracking_keyword/get_result_and_track_keyword',
            method: 'post',
            data: formdata
        }).then((res) => {
            getRankedKeywords()
        })
        .catch((error) => {
            console.log('failed to load', error)
        });
    }
    const unTrackThisKeyword = (item) => {
        CallApi({
            url: '/api/tracking_keyword/remove-ranking-keyword',
            method: 'post',
            data: {
                campaignId: campaignId,
                rank_keyword: item.keyword,
            }
        }).then((res) => {
            getRankedKeywords()
        })
        .catch((error) => {
            console.log('failed to load', error)
        });
    }
    const renderTrackUntrackButtons = (r) => {

        if (rankingKeywords.includes(r.keyword)) {
            return (
                <Button
                    color="error"
                    onClick={() => unTrackThisKeyword(r)}
                >UnTrack</Button>
            )

        } else {
            return (
                <Button
                    onClick={() => trackThisKeyword(r)}
                >Track</Button>
            )
        }
    }
    const memoizedColumns = useMemo<ColumnDef<GoogleConsoleData>[]>(() => [
        // {
        //     header: '#',
        //     id: 'action',
        //     cell: props => <Button onClick={() => {
        //         onMonitorBackLink(props.row.original)
        //     }} color='primary'>Monitor</Button>,
        //     enableColumnFilter: false
        // },

        {
            header: 'TRACK',
            id: 'action',
            cell: props => renderTrackUntrackButtons(props.row.original),
            enableColumnFilter: false
        },
        {
            header: 'URL',
            accessorKey: 'page',
            id: 'page',
            enableColumnFilter: true,
            cell: props => {
                let color = 'black'
                if (props.row.original.impressions >= avgImpression || props.row.original.position >= 5 && props.row.original.position <= 30) {
                    color = 'info.light'
                }
                return <a href={props.row.original.page} target='_blank'><Typography variant="inherit" color={color}>{props.row.original.page}</Typography></a>
            },
        },
        {
            header: 'KEYWORD',
            accessorKey: 'keyword',
            id: 'keyword',
            cell: props => {
                let color = 'black'
                if (props.row.original.impressions >= avgImpression || props.row.original.position >= 5 && props.row.original.position <= 30) {
                    color = 'info.light'
                }
                return <Typography variant="inherit" color={color}>{props.row.original.keyword}</Typography>
            },
            enableColumnFilter: true
        },
        {
            header: 'CLICKS',
            accessorKey: 'clicks',
            id: 'clicks',
            enableColumnFilter: true
        },
        {
            header: 'IMPRESSIONS',
            accessorKey: 'impressions',

            id: 'impressions',
            enableColumnFilter: true
        },
        {
            header: 'CTR',
            accessorKey: 'ctr',
            cell: props => props.row.original.ctr + '%',
            id: 'ctr',
            enableColumnFilter: true
        },
        {
            header: 'AVG. POSITION',
            accessorKey: 'position',
            id: 'position',
            enableColumnFilter: true
        },
        {
            header: 'Keyword Cannibalization',
            accessorKey: 'keywordCannibalizationText',
            id: 'cannibalization',
            enableColumnFilter: true,
            cell: props => {
                let keywordCannibalizationBackColor = ''
                let textColor = ''
                if (props.row.original.keywordCannibalizationText == 'low') {
                    keywordCannibalizationBackColor = '#b6d7a8'
                    textColor = '#12814c'
                } else if (props.row.original.keywordCannibalizationText == 'medium') {
                    keywordCannibalizationBackColor = '#f6b26b'
                    textColor = '#eb925b'
                } else if (props.row.original.keywordCannibalizationText == 'high') {
                    keywordCannibalizationBackColor = '#ea9999'
                    textColor = '#eb5b5b'
                }
                let keywordCannibalizationText = props.row.original.keywordCannibalizationText || ''
                if (keywordCannibalizationText) {
                    keywordCannibalizationText =  keywordCannibalizationText.charAt(0).toUpperCase() + keywordCannibalizationText.slice(1);
                }
                return <Typography variant="inherit"
                            color={textColor}
                            onClick={() => {
                                const rows = props.row.original.cannibalizedKeywords
                                if ($.size(rows) > 1) {
                                    setModalData({ rows, type: keywordCannibalizationText })
                                    setIsModalOpen(true)
                                }
                            }}
                            sx={{ cursor: keywordCannibalizationBackColor && "pointer", textAlign: "center" }}
                            style={{
                                backgroundColor: keywordCannibalizationBackColor,
                                padding: '5px',
                            }}>{ keywordCannibalizationText }</Typography>
            },
        }

    ], [language, rankingKeywords, tableData]);
    const downloadColumns = [
        {
            label: 'Url',
            value: 'page',
        },
        {
            label: 'Keyword',
            value: 'keyword',
        },
        {
            label: 'Clicks',
            value: 'clicks',
        },
        {
            label: 'Impressions',
            value: 'impressions',
        },
        {
            label: 'Ctr',
            value: 'ctr',
        },
        {
            label: 'Position',
            value: 'position',
        },
    ]
    const voltageLevel = getVoltageLevelLabel(voltageImpression)
    const hasData = tableData.length > 0

    return <>
        <CannibalizationModal data={modalData} isOpen={isModalOpen} setOpen={setIsModalOpen} />
        {voltageLevel && <Container maxWidth="xl">
            <Grid container mt={4} mb={2}>
                <Grid item xs={6} md={4}>
                    <Typography variant="h5" color='primary.dark'>Your Results</Typography>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Box display={'flex'} alignItems='center'>
                        <BoltIcon sx={{ color: '#FD7D00' }} />
                        <Typography variant="h6" mr={1}>Voltage Level: </Typography>
                        <Typography variant="h6" color="#FD7D00">{voltageLevel.from} - {voltageLevel.to}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4} display='flex' justifyContent='flex-end'>
                    <TablePdfCSVDownload
                        table_id="top-level-voltage-table-site-only"
                        file_name="toplevelvoltageSiteOnly"
                        columns={downloadColumns}
                        rows={tableData}
                    />
                </Grid>
            </Grid>

        </Container>}
        {hasData && <CustomReactTable
            data={tableData}
            columns={memoizedColumns}
            id="top-level-voltage-table-site-only"
        />}
    </>
}