import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { useSelector, useDispatch } from "react-redux";
import { LanguageModel } from 'Models/LanguageModel';
import LanguageData from '../utils/languages.json'
import { Divider } from '@mui/material';

export default function LanguageSelect({
    value, onChange, isMulti, placeholder, fullWidth
}: {
    value: LanguageModel,
    onChange: (v: LanguageModel) => void,
    isMulti: boolean,
    placeholder?: string,
    fullWidth?: boolean
}) {
    const langs = LanguageData
    return (
        <Autocomplete
            id="language-select-demo"
            sx={{ minWidth: 300 }}
            options={langs}
            autoHighlight
            getOptionLabel={(option: LanguageModel) => option.language_name}
            renderOption={(props, option: LanguageModel) => (
                <>
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        {option.language_name}
                    </Box>
                    {option.isDivider ? <Divider /> : null}
                </>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={placeholder || "Choose a language"}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                    size='small'
                />
            )}
            multiple={isMulti}
            value={value}
            onChange={(event: any, newValue: LanguageModel) => {
                onChange(newValue);
            }}
            fullWidth={fullWidth}
        />
    );
}
