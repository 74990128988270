import styled from "styled-components";
import ReactLoading from "react-loading";

const Wrapper = styled.div`
  position: fixed;
  background: #484848a3;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
`

const FullScreenLoading = () => {
  return (
    <Wrapper>
      <ReactLoading type={'spin'} color="#fff" />
    </Wrapper>
  )
}


export default FullScreenLoading;