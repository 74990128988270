import { DARK_GREEN_COLOR, GREEEN_COLOR, LIGHT_ORANGE_COLOR, ORANGE_COLOR } from "theme/colors";

export default {
    styleOverrides: {
        root: {
            color: DARK_GREEN_COLOR,
            '&.Mui-selected': {
                color: DARK_GREEN_COLOR,
                backgroundColor: LIGHT_ORANGE_COLOR,
                borderTopLeftRadius: 6,
                borderTopRightRadius: 6
            }
        },
    }
}