import axios from "axios";
import FeatherIcon from 'feather-icons-react';
import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import validator from 'validator';

import { useParams } from "react-router-dom";
import ProgressBar from "components/ProgressBar/ProgressBar";
import { CallApi, checkDomainMatch } from 'utils';
import { Box, Button, Checkbox, Container, FormControlLabel, Grid, InputAdornment, Switch, TextField, Typography } from '@mui/material';
import KeywordsResearchResult from 'views/Pages/Campaign/tabs/KeywordsResearch/KeywordsResearchResult';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CountrySelect from 'components/CountrySelect';
import LanguageSelect from 'components/LanguageSelect';
import { getValidKeywords } from 'utils/KeywordResearch';
import langs from 'utils/languages.json'
import ShadowBox from 'components/ShadowBox';
import {RootState} from "../../../../../redux/store";

let intervalTaskId = null;
const TIME_INTERVAL = 3000
// functions

function getCompetitorsList(competitorsStr) {
  return (competitorsStr.split('\n') || []).reduce((prevVal, c) => {
    if (validator.isURL(c.trim())) {
      prevVal.push(c.trim())
    }
    return prevVal
  }, [])
}

type STEP = null | 'LOADING_KEYWORD' | 'LOADING_RESULT_FOR_KEYWORD'

export default function KeywordOnlyComponent(props) {

  const user = useSelector((state: RootState) => state.user.user)
  const dispatch = useDispatch();
  const params = useParams()
  const { siteId, campaignId } = useParams()

  const formdata = useSelector((state: RootState) => state.formdata)
  const siteData = formdata.site


  const progressObjRef = useRef(null);


  const [extractedKeywords, setExtractedKeywords] = useState([]);
  const [research_id, setResearch_id] = useState(0)

  const [currentReport, setCurrentReport] = useState(null);
  const [researchHistory, setResearchHistory] = useState([])

  const [progressObj, setProgressObj] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [domain, setDomain] = useState("");
  const [location, setLocation] = useState(siteData?.location_code);
  const [language, setLanguage] = useState(null);
  const [competitors, setCompetitors] = useState('')
  const [topics, setTopics] = useState('')
  const [checkOnlyCompetitors, setCheckOnlyCompetitors] = useState(false)

  const [keywordExtractTaskId, setKeywordExtractTaskId] = useState(null)
  const [resultTaskId, setResultTaskId] = useState(null)

  const [currentStep, setCurrentStep] = useState<STEP>(null)
  // keywords filter options
  const [includeFilterTermText, setIncludeFilterTermText] = useState('');
  const [includeAllFilter, setIncludeAllFilter] = useState(false)
  const [excludeFilterTermText, setExcludeFilterTermText] = useState('');
  const [excludeAllFilter, setExcludeAllFilter] = useState(false)
  const [includeQuestionKeyword, setIncludeQuestionKeyword] = useState(false)
  const question_prefixs = 'who|what|where|when|why|how|was|were|did|do|does|is|are'.split('|')

  //


  const filteredKeywords = extractedKeywords.filter(item => { // Apply include filters
    const filterTerms = includeFilterTermText.split(',').map(term => term.trim()).filter(term => term !== '');
    if (filterTerms.length == 0) return true;

    if (!includeAllFilter) {
      for (const term of filterTerms) {
        if (item['keyword'].includes(term)) {
          return true;
        }
      }
      return false;
    } else {
      for (const term of filterTerms) {
        if (!item['keyword'].includes(term)) {
          return false;
        }
      }
      return true;
    }
    return true;
  })
    .filter(item => {  // Apply exclude filters
      const filterTerms = excludeFilterTermText.split(',').map(term => term.trim()).filter(term => term !== '');
      if (filterTerms.length == 0) return true;

      if (!excludeAllFilter) {
        for (const term of filterTerms) {
          if (item['keyword'].includes(term)) {
            return false;
          }
        }
        return true;
      } else {
        for (const term of filterTerms) {
          if (!item['keyword'].includes(term)) {
            return true;
          }
        }
        return false;
      }
      return true;
    })
    .filter(item => {
      if (includeQuestionKeyword) {
        for (let index = 0; index < question_prefixs.length; index++) {
          const element = question_prefixs[index];
          if (item['keyword'].startsWith(element)) {
            return true
          }
        }
        return false
      } else {
        return true
      }
    })
  useEffect(() => {
    if (campaignId) {
      loadData()
      updateLanguageLocation(null)
    }
  }, [campaignId, research_id])
  const loadData = () => {
    clear();
    loadSupportResearchHistoryForCampaign()
    loadSupportingKeywordResearchResult()
  }

  const loadSupportResearchHistoryForCampaign = () => {
    axios.get(`/api/top_level_support_keyword/history/campaign/${campaignId}`).then((res) => {
      setResearchHistory(res.data)
    })
    .catch((error) => {
      console.log('failed to load', error)
    });
  }
  const updateLanguageLocation = (report) => {
    if (siteData?.default_loc_lang_enabled) {
      setLocation(report?.location_code || siteData.location_code)
      const lang = langs.find(l => l.language_code == (report?.languageCode || siteData.language_code))
      if (lang) {
        setLanguage(lang)
      }
      setDomain(report?.domain || siteData?.base_url)

    } else {
      setDomain(report?.domain || siteData?.base_url)
      setLocation(report?.location_code)
      const lang = langs.find(l => l.language_code == report?.languageCode)
      if (lang) {
        setLanguage(lang)
      }
    }
  }
  const loadSupportingKeywordResearchResult = () => {
    CallApi({
      url: `/api/top_level_support_keyword/get_keyword_result/${campaignId}/${research_id}`,
      method: 'get'
    })
      .then((response) => {
        if (response) {
          setCurrentReport(response)
          setTopics(response.target_topics ? response.target_topics.join(',') : '');
          setCompetitors(response.competitor_urls ? response.competitor_urls.join('\n') : '');
          setCheckOnlyCompetitors(response.check_only_competitors == 1)
          updateLanguageLocation(response)
        } else {
          updateLanguageLocation(null)
        }
      })
      .catch((error) => {
        console.log('failed to load', error)
      });
  }


  const topicsCount = topics.split(',').filter(item => item.trim() !== '').length;
  const competitorsCount = getCompetitorsList(competitors).length

  const startSearchKeywords = async () => {

    setExtractedKeywords([]);

    let topics_arr = topics.split(',').map(item => item.trim()).slice(0, 10)

    let competitors_arr = getCompetitorsList(competitors).slice(0, 20)

    setCurrentStep('LOADING_KEYWORD')
    try {
      setIsLoading(true);
      CallApi({
        url: '/api/top_level_support_keyword/get-keywords-from-topic',
        method: 'POST',
        data: {
          campaignId: campaignId,
          topics: topics_arr,
          location_code: location,
          language_code: language.language_code,
          checkOnlyCompetitors: checkOnlyCompetitors,
          competitors: competitors_arr,
        }
      }).then((response) => {
        setKeywordExtractTaskId(response.taskId);
      }).catch((e) => {
        toast.error('Failed to create task to get a keywords')
      }).finally(() => {
      })
    } catch (e) {
      console.log(e)
    }
  }

  function handleSearchKeywords(evt) {
    if (siteId && !checkDomainMatch(domain, siteData.base_url)) {
      toast.error("Domain not matched")
      return
    }
    if (evt) {
      evt.preventDefault();
    }

    // if (
    //   !user?.is_admin &&
    //   user?.dailyKeywordLimit <= user?.used_keywords_count_today
    // ) {
    //   toast.error("You exceed the today's usage.");
    //   return;
    // }
    const splittedKeywords = filteredKeywords.map(f => f.keyword);

    // let remainLimit =
    //   user?.dailyKeywordLimit - user?.used_keywords_count_today || 0;
    // if (!user.is_admin && splittedKeywords.length > remainLimit) {
    //   toast.error(
    //     "You have inputted too many keywords than your remained limit"
    //   );
    //   return;
    // }

    const validKeywords = getValidKeywords(splittedKeywords)
    
    setCurrentStep('LOADING_RESULT_FOR_KEYWORD')
    setIsLoading(true);

    let topics_arr = topics.split(',').map(item => item.trim()).slice(0, 10)
    let competitors_arr = getCompetitorsList(competitors).slice(0, 20)
    const formdata = {
      siteId: siteId,
      campaignId: campaignId,
      keywords: validKeywords,
      location_code: location,
      language_code: language.language_code,
      domain: domain,
      target_topics: topics_arr,
      competitor_urls: competitors_arr
    }
    CallApi({
      url: '/api/top_level_support_keyword/keyword-research-support',
      method: 'post',
      data: formdata
    })
      .then((response) => {
        setResultTaskId(response.taskId)
      }).finally(() => {
        setIsLoading(false)
      });
  }
  useEffect(() => {
    if (keywordExtractTaskId) {
      createKeywordExtractTaskMonitor();
    }
    if (resultTaskId) {
      createKeywordResultTaskMonitor();
    }
  }, [keywordExtractTaskId, resultTaskId]);
  function clear() {
    console.log('clearing task interval because task is finished with success/failure', intervalTaskId)
    if (intervalTaskId) {
      clearInterval(intervalTaskId);
      intervalTaskId = null;
    }
    setKeywordExtractTaskId(null)
    setResultTaskId(null)
    setProgressObj(null);
    setIsLoading(false)
    progressObjRef.current = {};

  }
  const createKeywordResultTaskMonitor = () => {
    console.log('creating task monitor to get update')
    intervalTaskId = setInterval(() => {
      axios.get(`/api/get-task-status/${resultTaskId}`).then((response) => {

        if (response && response.status === 200) {
          if (response.data?.status === "PROGRESS") {
            let value = (progressObjRef.current?.value || 0) + 1;
            if (value < response.data.value) value = response.data.value;
            if (value > 100) value = 100;
            let newVal = {
              value: value,
              msg: `${response.data.msg} While you wait, you can leave this screen.` // response.data.msg
            };
            progressObjRef.current = newVal;
            setProgressObj(newVal);
          } else if (response.data?.status === "SUCCESS") {
            loadData()
            toast.success("Completed keyword research!!");
          } else if (response.data?.status === "FAILURE") {
            toast.error("Failed!!");
            clear();
          }
        } else {
          clear();
        }
      });
      return () => {
        clear();
      };
    }, TIME_INTERVAL);
  }
  const createKeywordExtractTaskMonitor = () => {
    console.log('creating task monitor to get update')
    intervalTaskId = setInterval(() => {
      CallApi({
        method: 'get',
        url: `/api/get-task-status/${keywordExtractTaskId}`
      }).then((response) => {
        if (response?.status === "PROGRESS") {
          let value = (progressObjRef.current?.value || 0) + 1;
          if (value < response.value) value = response.value;
          if (value > 100) value = 100;
          let newVal = {
            value: value,
            msg: `${response.msg} While you wait, you can leave this screen.` // response.msg
          };
          progressObjRef.current = newVal;
          setProgressObj(newVal);
        } else if (response?.status === "SUCCESS") {
          clear();
          setExtractedKeywords(response.result.keywords)

          toast.success("Completed keyword research!!");
        } else if (response?.status === "FAILURE") {

          toast.error("Failed!!");
          clear();
        }
      }).catch(() => {
        clear();
      })
    }, TIME_INTERVAL);
  }

  const canStartAnalysis = () => {
    if (!location) {
      return false;
    }
    if (!language) {
      return false;
    }
    if (checkOnlyCompetitors && !competitors.trim()) {
      return false;
    }

    return true;
  };
  const renderKeywordsResult = () => {
    if (!extractedKeywords || extractedKeywords.length == 0) {
      return <></>
    }

    return (
      <Box>
        <Container maxWidth="xl">
          <Box mb={2} mt={6}>
            <Grid container>
              <Grid item xs={12} md={6}>
                <Typography variant='h5' color='primary.dark'>{filteredKeywords.length} Keywords Found</Typography>
              </Grid>
              <Grid item xs={12} md={6} justifyContent='flex-end' display="flex">
                <Button variant='contained' sx={{
                  '& a': {
                    color: 'white'
                  }
                }}>
                  <CSVLink
                    data={keywordsCSVData}
                    filename={`Keywords_${(new Date()).toLocaleDateString()}.csv`}
                  >Export Keywords (CSV)</CSVLink>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>

        <Box sx={{
          backgroundColor: '#2B63490D'
        }}>
          <Container maxWidth="xl">
            <Box py={4}>
              <Typography variant="h5" color='primary.dark'>Select Keywords to Measure</Typography>
              <Typography color='primary.dark' my={2}>
                Select the keywords or topics below that you want to gather keyword score data about, and hit next to get the data.
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextField placeholder="Please input filter keywords separated by comma" variant="outlined"
                    label="Include Words"
                    value={includeFilterTermText}
                    onChange={(e) => { setIncludeFilterTermText(e.target.value) }}
                    fullWidth
                    sx={{
                      '& input': {
                        backgroundColor: 'white',
                        borderRadius: '4px 0 0 4px'
                      },
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position='end'>

                        <FormControlLabel control={<Switch defaultChecked
                          checked={includeAllFilter}
                          onChange={(e) => {
                            setIncludeAllFilter(e.target.checked)
                          }} />} sx={{ m: 0 }}
                          label={includeAllFilter ? "All" : "Any"}
                        />
                      </InputAdornment>
                    }}
                  />

                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField placeholder="Please input filter keywords separated by comma" variant="outlined"
                    label="Exclude Words"
                    value={excludeFilterTermText}
                    onChange={(e) => { setExcludeFilterTermText(e.target.value) }}
                    fullWidth
                    sx={{
                      '& input': {
                        backgroundColor: 'white',
                        borderRadius: '4px 0 0 4px'
                      },
                    }}
                    InputProps={{
                      endAdornment: <InputAdornment position='end'>

                        <FormControlLabel control={<Switch defaultChecked
                          checked={excludeAllFilter}
                          onChange={(e) => {
                            setExcludeAllFilter(e.target.checked)
                          }} />} sx={{ m: 0 }}
                          label={excludeAllFilter ? "All" : "Any"}
                        />
                      </InputAdornment>
                    }}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel control={<Switch onChange={(e) => {
                    setIncludeQuestionKeyword(e.target.checked)
                  }}
                    checked={includeQuestionKeyword}
                  />} label="Question Keywords"
                  />
                </Grid>
              </Grid>
              <Button
                size='large'
                disabled={!canStartAnalysis()}
                onClick={handleSearchKeywords}
                variant="contained"
                endIcon={<ArrowForwardIcon />}
              >
                Calculation for Keywords
              </Button>
            </Box>
          </Container>
        </Box>
        <Container maxWidth="xl" >
          <Box py={2}>
            {
              filteredKeywords.length > 0 && filteredKeywords.map((item, idx) => {
                return <div key={idx}>
                  <Box
                    sx={{
                      backgroundColor: '#2B63490D',
                      margin: '5px',
                      padding: '3px 7px',
                      borderRadius: 24,
                      border: '1px solid transparent',
                      '&:hover': {
                        border: '1px solid #2B63490D'
                      },
                      display: 'inline-flex',
                      '&> :nth-child(1)': {
                        flexGrow: 1
                      }
                    }}
                  >
                    <div className="icon-btn">
                      <FeatherIcon icon="x-circle" size={22}
                        onClick={() => {
                          setExtractedKeywords(extractedKeywords.filter(arg => arg.keyword != item.keyword))
                        }}
                      />
                    </div>
                    <span className="mx-3">
                      {item.keyword}
                    </span>
                    <span>
                      ({item.source})
                    </span>
                  </Box>
                </div>
              })
            }
          </Box>
        </Container >
      </Box >
    )
  }

  const handlePauseTask = () => {
    setIsLoading(false);
    if (keywordExtractTaskId) {
      axios.get('/api/revoke_task/' + keywordExtractTaskId).then((res) => {
        clear();
      })
    }
    if (resultTaskId) {
      axios.get('/api/revoke_task/' + resultTaskId).then((res) => {
        clear();
      })
    }
  }

  const makeKeywordsCSVData = () => {
    let results = []
    results.push(['Keyword']);
    for (let data of filteredKeywords) {
      results.push([data['keyword']])
    }
    return results;
  }

  const onDeleteAllKeywordData = () => {
    if (currentReport) {
      axios
        .delete(`/api/top_level_support_keyword/delete_all_keywords_data/${campaignId}`)
        .then((res) => {
          loadSupportingKeywordResearchResult()
        })
    }
  }

  const onChangeResearchId = (research_id: number) => {
    setResearch_id(research_id)
  }
  const keywordsCSVData = makeKeywordsCSVData();

  return (
    <Box>
      <Container maxWidth="xl">
        <ShadowBox
        >
          <Box my={2}>
            <TextField
              label={"Target Keyword/Topic" + `(${topicsCount} / 10 )`}
              placeholder="Please input the target topics, you can add multiple topics by comma"
              rows={3}
              multiline
              value={topics}
              onChange={(e) => setTopics(e.target.value)}
              fullWidth
            ></TextField>
          </Box>

          <Box>
            <TextField
              label={"Competitor Urls" + `(${competitorsCount} / 20)`}
              placeholder="You can add upto 20 competitors."
              rows={3}
              multiline
              value={competitors}
              onChange={(e) => setCompetitors(e.target.value)}
              fullWidth
            ></TextField>

          </Box>
          <Grid container spacing={2} my={2}>
            <Grid item xs={12} md={4}>
              <CountrySelect
                isMulti={false}
                value={location}
                onChange={(loc) => setLocation(loc)}
                placeholder='Target Country'
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <LanguageSelect
                value={language}
                onChange={(item) => {
                  setLanguage(item);
                }}
                isMulti={false}
                fullWidth={true}
              />
            </Grid>
            <Grid item xs={12} md={4}>

              <TextField
                size='small'
                value={domain}
                label="Domain"
                onChange={(e) => {
                  setDomain(e.target.value);
                }}
                fullWidth
              />
            </Grid>
          </Grid>
          <FormControlLabel control={<Switch checked={checkOnlyCompetitors} onChange={(e) => setCheckOnlyCompetitors(e.target.checked)}
          />} label='Check this if you want only analyze competitors' />

          <Box my={4} >
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={4}>

                <Button
                  size='large'
                  disabled={isLoading}
                  onClick={startSearchKeywords}
                  variant="contained"
                  endIcon={<ArrowForwardIcon />}
                  color='secondary'
                  sx={{
                    borderRadius: 10,
                  }}
                >
                  Search Keywords
                </Button>
              </Grid>
              <Grid item xs={12} md={8}>
                <ProgressBar
                  progressObj={progressObj}
                  onPause={handlePauseTask}
                />
              </Grid>
            </Grid>
          </Box>
        </ShadowBox>
      </Container>
      {
        (currentStep == 'LOADING_KEYWORD') && renderKeywordsResult()
      }
      {currentReport && currentStep != 'LOADING_KEYWORD' && (
        <KeywordsResearchResult
          onChangeResearchId={onChangeResearchId}
          report={currentReport}
          campaignId={Number(campaignId)}
          researchHistory={researchHistory}
          calculating={isLoading}
          onDeleteAllKeywordData={onDeleteAllKeywordData}
          id='supporting_keyword_research'
        />
      )}
    </Box>
  )
}
