import { createSlice } from '@reduxjs/toolkit';
import Axios from 'axios';
import _ from 'lodash'
import { SiteModel } from 'Models/SiteModel';

export interface FormDataType {
  site: SiteModel,
}

const initialState: FormDataType = {
  site: null,
}
export const slice = createSlice({
  name: 'formdata',
  initialState,
  reducers: {
    testAction: (state, action) => {
      state = initialState
    },
    saveSiteAction: (state, action) => {
      state.site = action.payload
    },
  },
});

export const {
  testAction,
  saveSiteAction,
} = slice.actions;

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
// export const incrementAsync = amount => dispatch => {
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount));
//   }, 1000);
// };

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

export default slice.reducer;


export const loadSiteThunk = (site_id) => dispatch => {
  Axios.get('/api/sites/get/' + site_id)
    .then(response => {
      if (response && response.status === 200) {
        dispatch(saveSiteAction(response.data));
      }
    }).finally(() => {
    })
};