import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import { blue } from '@mui/material/colors';
import { Box, DialogActions, FormControl, Grid, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import moment from 'moment';

export type DateRangeType = {
    value: string,
    start_date?: string,
    end_date?: string,
}
type OptionType = {
    label: string,
    value: string,
}
export const DATE_RANGE_OPTIONS: OptionType[] = [
    {
        label: 'Last 7 days',
        value: 'last_7_days',
    },
    {
        label: 'Last 28 days',
        value: 'last_28_days',
    },
    {
        label: "Last 3 months",
        value: 'last_3_months',
    },
    {
        label: "Last 6 months",
        value: 'last_6_months',
    },
    {
        label: "Last 12 months",
        value: 'last_12_months',
    },
    {
        label: "Last 16 months",
        value: 'last_16_months',
    },
    {
        label: "Custom",
        value: 'custom',
    },
]

export const getOptionDate = (option: DateRangeType): {
    startDate: string,
    endDate: string
} => {
    let endDate = ''
    let startDate = ''
    switch (option.value) {
        case 'last_7_days':
            endDate = moment().format('YYYY-MM-DD')
            startDate = moment().add(-7, 'days').format('YYYY-MM-DD')
            return {
                startDate,
                endDate
            }
            break;
        case 'last_28_days':
            endDate = moment().format('YYYY-MM-DD')
            startDate = moment().add(-28, 'days').format('YYYY-MM-DD')
            return {
                startDate,
                endDate
            }
            break;
        case 'last_3_months':
            endDate = moment().format('YYYY-MM-DD')
            startDate = moment().add(-3, 'months').format('YYYY-MM-DD')
            return {
                startDate,
                endDate
            }
            break;
        case 'last_6_months':
            endDate = moment().format('YYYY-MM-DD')
            startDate = moment().add(-6, 'months').format('YYYY-MM-DD')
            return {
                startDate,
                endDate
            }
            break;
        case 'last_12_months':
            endDate = moment().format('YYYY-MM-DD')
            startDate = moment().add(-12, 'months').format('YYYY-MM-DD')
            return {
                startDate,
                endDate
            }
            break;
        case 'last_16_months':
            endDate = moment().format('YYYY-MM-DD')
            startDate = moment().add(-16, 'months').format('YYYY-MM-DD')
            return {
                startDate,
                endDate
            }
            break;
        case 'custom':
            endDate = option.start_date
            startDate = option.end_date
            return {
                startDate,
                endDate
            }
            break;

        default:
            return null
            break;
    }
}
export default function DateRageSelect({
    onApplyDateRange,
    dateFilterData
}: {
    onApplyDateRange: (data: DateRangeType) => void;
    dateFilterData: DateRangeType

}) {
    const [open, setOpen] = useState(false);
    const [filterData, setFilterData] = useState<DateRangeType>(dateFilterData)

    const handleClickOpen = () => {
        setOpen(true);
    };

    const onApplyFilter = (data: DateRangeType) => {
        setOpen(false);
        setFilterData(data)
        onApplyDateRange(data)
    };
    const getLabel = () => {
        if (filterData) {
            if (filterData.value == 'custom') {
                return `${filterData.start_date} ~ ${filterData.end_date}`
            } else {
                const option = DATE_RANGE_OPTIONS.filter(d => d.value == filterData.value)[0]
                return option.label
            }
        } else {
            return "New"
        }
    }
    return (
        <Box>
            <Typography variant='h6' color='primary.dark'>
                Refine your results with a Page Filter:
            </Typography>

            <br />
            <DateRangeDialog
                filterData={filterData}
                onApply={(data) => onApplyFilter(data)}
            />
        </Box>
    );
}


export interface DateRangeDialogProps {
    filterData: DateRangeType;
    onApply: (data: DateRangeType) => void;
}

function DateRangeDialog(props: DateRangeDialogProps) {
    const { onApply, filterData } = props;

    const [selectedOptionValue, setSelectedOptionValue] = useState<string>(filterData?.value)
    const [startDate, setStartDate] = useState(filterData?.start_date)
    const [endDate, setEndDate] = useState(filterData?.end_date)

    useEffect(() => {
        if (filterData) {
            setSelectedOptionValue(filterData.value)
            setStartDate(filterData.start_date)
            setEndDate(filterData.end_date)
        } else {
            setSelectedOptionValue(null)
            setStartDate(null)
            setEndDate(null)
        }
    }, [filterData])

    const isValidToApply = () => {
        if (selectedOptionValue && selectedOptionValue != 'custom') {
            return true
        }
        if (selectedOptionValue == 'custom') {
            if (!startDate || !endDate) {
                return false
            }
            if (moment(startDate).valueOf() >= moment(endDate).valueOf()) {
                return false
            }
            return true
        }
        return false
    }
    useEffect(() => {
        if (isValidToApply()) {
            onApply({
                value: selectedOptionValue,
                start_date: startDate,
                end_date: endDate
            })
        }
    }, [selectedOptionValue, startDate, endDate])

    return (
        <Box>
            <Grid container>
                <Grid item xs={12} md={4}>
                    <FormControl size='small' fullWidth>
                        <InputLabel id="demo-simple-select-label">Filter Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedOptionValue}
                            label="Filter Type"
                            onChange={(event: SelectChangeEvent) => {
                                setSelectedOptionValue(event.target.value)
                            }}
                        >
                            {DATE_RANGE_OPTIONS.map((option, key) => (
                                <MenuItem value={option.value} key={key}>{option.label}</MenuItem>
                            ))}

                        </Select>
                    </FormControl>
                    {selectedOptionValue == 'custom' && <Box mt={2} display='flex'>
                        <TextField
                            id="date_start"
                            size='small'
                            label="Start Date"
                            value={startDate}
                            type="date"
                            sx={{ width: 220, marginRight: 2 }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => {
                                setStartDate(e.target.value)
                            }}
                        />
                        <TextField
                            id="date_end"
                            size='small'
                            label="End Date"
                            value={endDate}
                            type="date"
                            sx={{ width: 220 }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={(e) => {
                                setEndDate(e.target.value)
                            }}
                        />
                    </Box>}
                </Grid>
            </Grid>

        </Box>
    );
}