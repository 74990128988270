import { Box } from "@mui/material";
import { SiteModel } from "Models/SiteModel";
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { RootState } from "redux/store";
import KeywordOnlyComponent from "./TopLevelPageComponents/KeywordOnlyComponent";
import PageVolatageComponent from "./TopLevelPageComponents/PageVoltageComponent";
import SiteOnlyVoltageComponent from "./TopLevelPageComponents/SiteOnlyVoltageComponent";
import { TopLevelPageHeader } from "./TopLevelPageComponents/TopLevelPageHeader";

const SITE_URL_EXIST = {
    value: 'SITE_URL_EXIST',
    description: 'We have established site and target page (url) in mind'
}
const SITE_ONLY_EXIST = {
    value: 'SITE_ONLY_EXIST',
    description: 'We don’t have target page in mind but established site'
}
const KEYWORD_ONLY_EXIST = {
    value: 'KEYWORD_ONLY_EXIST',
    description: 'We have target keyword in mind and we don’t have established site'
}
type PropTypes = {
    isActive: boolean
}
export default function ({ isActive }: PropTypes) {
    const siteData: SiteModel = useSelector((state: RootState) => state.formdata.site)
    const [options, setOptions] = useState([])
    const [showOptionModal, setShowOptionModal] = useState(false);
    const [currentOption, setCurrentOption] = useState(null);


    useEffect(() => {
        if (siteData) {
            if (siteData.level == 'domain') {
                setOptions([SITE_ONLY_EXIST, KEYWORD_ONLY_EXIST])
            } else {
                setOptions([SITE_URL_EXIST, SITE_ONLY_EXIST, KEYWORD_ONLY_EXIST])
            }
        }
    }, [siteData])

    useEffect(() => {
        if (isActive && options.length > 0 && !currentOption) {
            setShowOptionModal(true)
        }

    }, [options, isActive])
    const renderComponentByOption = () => {
        if (currentOption == 'SITE_URL_EXIST') {
            return <PageVolatageComponent />
        }
        if (currentOption == 'SITE_ONLY_EXIST') {
            return <SiteOnlyVoltageComponent />
        }
        if (currentOption == 'KEYWORD_ONLY_EXIST') {
            return <KeywordOnlyComponent />
        }
        return null
    }
    return <Box>
        <TopLevelPageHeader
            options={options}
            onChangeOption={(op) => setCurrentOption(op)}
        />
        {
            renderComponentByOption()
        }
    </Box>
}