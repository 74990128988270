import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const parseContent = (content: string) => {
  const matches =
    content
      .slice(0)
      .match(/<li>(.*?)<\/li>/g)
      ?.map((match) => match.replace(/<\/?li>/g, "")) || [];
  return matches.filter((match) => match && match !== "<br>");
};

const BrandedKeywords = ({ editorContent, setEditorContent }) => {
  const modules = { toolbar: [[{ list: "ordered" }]] };

  return (
    <ReactQuill
      theme="snow"
      style={{
        border: "2px solid #3B8D67",
        borderRadius: "5px",
        marginTop: 12,
        fontSize: "16px",
        color: "#014751",
        backgroundColor: "white",
      }}
      modules={modules}
      formats={["bold", "italic", "underline", "strike", "list"]}
      placeholder="Type your content here..."
      value={editorContent}
      onChange={(content) => {
        const result = parseContent(content);
        if (result.length <= 10) setEditorContent(content);
      }}
    />
  );
};

export default BrandedKeywords;
