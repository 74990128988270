import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from "react-redux";
import SearchForm from "views/Pages/Campaign/tabs/ContentAuditComponents/SearchForm";
import CAResultGraph from "views/Pages/Campaign/tabs/ContentAuditComponents/CAResultGraph";
import CAResultTable from "views/Pages/Campaign/tabs/ContentAuditComponents/CAResultTable";
import { CallApi } from "utils";
import { useParams } from 'react-router-dom';
import {RootState} from "../../../../redux/store";

type PropTypes = {
    isActive: boolean
}
export interface TableDataType {
    keyword_count: number
    keywords: string[]
    backlinks: number
    internal_links: number
    url: string
    clicks: number
    impressions: number
    ctr: number
    position: number
    lastCrawlTime: string
}
export interface TableDataWithScoreType {
    keyword_count: number
    keywords: string[]
    backlinks: number
    internal_links: number
    url: string
    clicks: number
    impressions: number
    ctr: number
    position: number
    lastCrawlTime: string
    total_score?: number
    key_relation_score?: number
}

export interface BenchmarkDataType {
    keyword_count: number
    clicks: number
    impressions: number
}

export default function ({ isActive }: PropTypes) {
    const formdata = useSelector((state: RootState) => state.formdata)
    const siteData = formdata.site
    const params = useParams()
    const { campaignId } = params

    const [country, setCountry] = useState(siteData?.default_loc_lang_enabled ? siteData?.location_code : null)
    const [chartData, setChartData] = useState(null)
    const [resultTableData, setResultTableData] = useState<TableDataWithScoreType[]>([])
    const [tableData, setTableData] = useState<TableDataType[]>([])
    const [benchmarkData, setBenchmarkData] = useState<BenchmarkDataType>()

    const [enabledNLP, setEnabledNLP] = useState(false)
    useEffect(() => {
        if (campaignId) {
            CallApi({
                url: '/api/content_audit/get_audit/' + campaignId,
            }).then((res) => {
                setChartData(res.chart_data)
                setResultTableData(res.table_data)
            })
            .catch((error) => {
                console.log('failed to load', error)
            });
            // const url = "https://pageoptimizer.pro/free-on-page-seo-checker/"
            // const keywords = [
            //     "on page optimizer",
            //     "page optimizer pro login",
            //     "page optimizer pro pricing",
            // ]
            // CallApi({
            //     url: '/api/content_audit/relative_of_keywords_in_url',
            //     data: {
            //         url,
            //         keywords
            //     },
            //     method: 'post'
            // }).then((res) => {
            //     console.info('=== res ===', JSON.stringify(res, null, 4))

            // }).catch((e) => {
            //     console.info('=== e ===', JSON.stringify(e, null, 4))

            // })
        }
    }, [campaignId])
    useEffect(() => {
        if (campaignId && (chartData || resultTableData.length > 0)) {
            CallApi({
                url: '/api/content_audit/update_audit/' + campaignId,
                method: 'post',
                data: {
                    chart_data: chartData,
                    table_data: resultTableData
                }
            }).then((res) => {
                console.info('Updated content audit successfully')
            })
            .catch((error) => {
                console.log('failed to load', error)
            });
        }
    }, [resultTableData, chartData, campaignId])

    useEffect(() => {
        if (tableData?.length > 0 && benchmarkData) {
            console.info('=== benchmarkData ===', JSON.stringify(benchmarkData, null, 4))
            // calculate benchmark for internal, external links
            const backlinks = tableData.map(d => d.backlinks).sort((a, b) => { return b - a })
            const internalLinks = tableData.map(d => d.internal_links).sort((a, b) => { return b - a })
            const topBacklinks = backlinks.slice(0, Math.ceil(backlinks.length / 10))
            const topInternalLinks = internalLinks.slice(0, Math.ceil(internalLinks.length / 10))
            const topBacklinkAverage = topBacklinks.reduce((a, b) => a + b, 0) / topBacklinks.length;
            const topInternalAverage = topInternalLinks.reduce((a, b) => a + b, 0) / topInternalLinks.length;

            // calculate score
            const result: TableDataWithScoreType[] = tableData.map((item, key) => {
                const backlinks_score = topBacklinkAverage > 0 ? (item.backlinks / topBacklinkAverage) * 7 * 0.5 : 0
                const internal_links_score = topInternalAverage > 0 ? (item.internal_links / topInternalAverage) * 5 * 0.5 : 0

                const position_score = Math.max(0, ((101 - item.position) / 100) * 13)
                const ctr_score = (item.ctr / 100) * 20 * 1.5

                const keyword_score = benchmarkData.keyword_count > 0 ? (item.keyword_count / benchmarkData.keyword_count) * 15 : 0
                const clicks_score = benchmarkData.clicks > 0 ? (item.clicks / benchmarkData.clicks) * 20 * 1.5 : 0
                const impressions_score = benchmarkData.impressions > 0 ? (item.impressions / benchmarkData.impressions) * 20 * 1.5 : 0

                const total_score = backlinks_score +
                    internal_links_score +
                    keyword_score +
                    position_score +
                    ctr_score +
                    clicks_score +
                    impressions_score
                return {
                    ...item,
                    total_score: Math.min(total_score, 100)
                }

            })
            if (enabledNLP) {

                // get keywords relationships
                const low_score_results = result.filter(r => r.total_score < 50);
                const key_relation_promises = low_score_results.map((d, key) => {
                    return new Promise((resolve, reject) => {
                        CallApi({
                            url: '/api/content_audit/relative_of_keywords_in_url',
                            data: {
                                url: d.url,
                                keywords: d.keywords
                            },
                            method: 'post'
                        }).then((res) => {
                            resolve(res)

                        }).catch((e) => {
                            resolve(0)
                        })
                    })
                })
                Promise.all(key_relation_promises).then((res) => {
                    for (let index = 0; index < res.length; index++) {
                        const key_relation_score = Number(res[index]);
                        const result_index = result.findIndex(r => r.url == low_score_results[index].url)
                        result[result_index].key_relation_score = key_relation_score
                    }

                    setResultTableData(result)
                })
            } else {
                setResultTableData(result)
            }
        }
    }, [tableData, benchmarkData])

    return <Box>
        <SearchForm
            onChangeNLPEnabled={(b) => setEnabledNLP(b)}
            onGraphData={(v) => setChartData(v)}
            onTableData={(v) => setTableData(v)}
            onBenchmarkData={(v) => setBenchmarkData(v)}
            default_country={country} />
        <CAResultGraph data={chartData} isActive={isActive} />
        <CAResultTable data={resultTableData} enabledNLP={enabledNLP} />
    </Box>
}