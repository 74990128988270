import { useEffect, useState } from 'react';

import Axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { loginAction, logoutAction, } from 'redux/reducers/user';
import { Box, Button, Paper, TextField, Container, Typography, Alert } from '@mui/material';
import { isEmail } from 'validator'

function ConfirmEmail() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams()
  const [confirmProgress, setConfirmProgress] = useState<'pending' | 'completed' | 'error'>('pending')

  useEffect(() => {
    if (params.token) {
      confirmToken(params.token)
    }
  }, [params.token])

  const confirmToken = async (token) => {

    const response = await Axios.get('/api/auth/confirm_email/'+token);
    if (response.status === 200) {
      setConfirmProgress('completed')
    } else {
      setConfirmProgress('error')
    }
  }

  return (
    <>
      <div className='welcome-message'>
        👋 Welcome to POP Utils 👋
      </div>
      <Container maxWidth='sm'>
        {
          confirmProgress == 'completed' && <Box p={2}>
            <Alert severity="success">Successfully confirmed email. Please login now.</Alert>
            <Button onClick={() => navigate('/login')}>Go to login</Button>
          </Box>
        }
        {
          confirmProgress == 'pending' && <Box p={2}>
            <Alert severity="info">Processing...</Alert>
          </Box>
        }
        {
          confirmProgress == 'error' && <Box p={2}>
            <Alert severity="error">Confirm email failed!</Alert>
          </Box>
        }

      </Container>
    </>
  );
}

export default ConfirmEmail