import { useState, useEffect, useMemo } from "react";
import { useDispatch } from 'react-redux';

import { Form, Modal, Alert } from "react-bootstrap";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useTable, usePagination, useSortBy } from "react-table";
import styled from "styled-components";
import CustomReactTable from "components/ReactTable";

import {
  showLoadingIndicator, hideLoadingIndicator
} from 'redux/reducers/appstate'
import Axios from "axios";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { CallApi } from "utils";
import { ColumnDef } from "@tanstack/react-table";


export const loadUsers = async () => {
  try {
    return await Axios.get('/api/user/all-users')
  } catch (e) {
    throw e;
  }
}


export const createUser = async (data) => {
  try {
    return await Axios.post('/api/auth/create-user', data)
  } catch (e) {
    throw e;
  }
}

type PropTypes = {
  isActive: boolean
}
export default function ({ isActive }: PropTypes) {

  const dispatch = useDispatch();
  const [users, setUsers] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [showNewUserModal, setShowNewUserModal] = useState(false);

  const showEditModal = (user) => {
    console.log(user);
    setEditingUser(user);
    setShowNewUserModal(true);
  }
  const onDelete = (user) => {
    if (window.confirm()) {
      CallApi({
        url: 'api/user/delete/' + user.id
      }).then((res) => {
        loadUsersFunc()
      })
      .catch((error) => {
        console.log('failed to load', error)
      });
    }
  }
  const memoizedColumns = useMemo<ColumnDef<any>[]>(() => [
    {
      header: 'ID',
      accessorKey: 'id',
      enableColumnFilter: false
    },
    {
      header: 'Email',
      accessorKey: 'email',
    },
    {
      header: 'Is Admin',
      accessorKey: 'is_admin',
      Cell: ({ cell: { value } }) => value == 1 ? 'YES' : 'NO',
      enableColumnFilter: false
    },
    {
      header: 'Created At',
      accessorKey: 'created_at',
      enableColumnFilter: false
    },
    {
      header: 'Action',
      accessorKey: 'no',
      enableColumnFilter: false,
      cell: ({ row: { original } }) => (<Box>
        <Button variant="contained" onClick={() => showEditModal(original)} sx={{ mr: 1 }}>Edit</Button>
        <Button variant="contained" onClick={() => onDelete(original)} color='error'>Delete</Button>
      </Box>)
    },


  ], [])


  const loadUsersFunc = () => {
    dispatch(showLoadingIndicator());
    loadUsers()
      .then(response => {
        if (response && response.status == 200) {
          setUsers(response.data.users)
        }
      })
      .finally(() => {
        dispatch(hideLoadingIndicator());
      })
  }

  useEffect(() => {
    loadUsersFunc();
  }, []);



  return (
    <Box>

      <Container maxWidth="xl">
        <Box sx={{
          backgroundColor: 'white', p: 2,
          boxShadow: '0px 4px 0px rgba(81, 72, 251, 0.2);',
          borderRadius: '0 0 8px 8px'
        }}>
          <Grid container spacing={2} my={2}>
            <Grid item xs={12} md={8}>
              <Typography variant="h5" color='primary.dark'>User Management</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Button variant="contained" onClick={() => {
                setEditingUser(null);
                setShowNewUserModal(true);
              }} >New User</Button>
            </Grid>
          </Grid>

          <CustomReactTable
            data={users}
            columns={memoizedColumns}
            id='user-table'
          />
          <EditUserModal
            show={showNewUserModal}
            setShow={setShowNewUserModal}
            user={editingUser}
            callback={loadUsersFunc}
          />


        </Box>
      </Container>
    </Box>
  )
}


function EditUserModal({
  user,
  show, setShow, callback }) {

  const dispatch = useDispatch();

  useEffect(() => {
    if (user) {
      setValue('email', user?.email || '')
      setValue('is_admin', user?.is_admin ? true : false)
    } else {
      reset();
    }
  }, [user])

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      is_admin: false,
    }
  });

  const onSubmit = (data) => {
    dispatch(showLoadingIndicator());

    let body = {
      ...data
    }
    if (user) {
      body['userId'] = user.id
    }

    createUser(body)
      .then(response => {
        console.log('response', response)
        if (response && response.status == 200) {
          reset();
          setShow(false);
          console.log('testing', user);
          toast.success(user ? 'Successfully Updated user' : 'Successfully Created new user.')
          callback();
        } else {
          toast.error(response.data.msg);
        }
      })
      .finally(() => {
        dispatch(hideLoadingIndicator());
      })
  };

  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>
          {user ? 'Edit User' : 'New User'}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3" controlId="emailGroup">
            <Form.Label>Email address</Form.Label>
            <Form.Control type="email" placeholder="Please input email" {...register('email', { required: true })} disabled={user} />
            {errors.email && <p className="form-field-error">Please input valid email</p>}
          </Form.Group>

          {!user && (
            <Form.Group className="mb-3" controlId="passwordGroup">
              <Form.Label>Password</Form.Label>
              <Alert variant="info">By default, password is 123456789. user can change it.</Alert>
            </Form.Group>
          )}

          <Form.Group className="mb-3" controlId="isadminGroup">
            <Form.Label>Admin</Form.Label>
            <Form.Check type="checkbox" label="Is Admin ?" {...register('is_admin')} />
          </Form.Group>

          <Button type="submit" variant="contained">{user ? 'Update User' : 'New User'}</Button>
        </Form>
      </Modal.Body>

      <Modal.Footer>
      </Modal.Footer>
    </Modal>
  )
}