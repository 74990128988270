import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Axios from 'axios';
import toast from 'react-hot-toast';
import { CSVLink, CSVDownload } from "react-csv";
import { generateTableToPDF } from 'utils/pdf-generation';
import { useParams } from 'react-router-dom';
import { TrackResultType } from 'Models/KeywordResearchModel';
import FeatherIcon from 'feather-icons-react';
import { hideLoadingIndicator, showLoadingIndicator } from 'redux/reducers/appstate';
import { CallApi } from 'utils';
import { Box, Button, Container, Grid, Typography, Alert, FormGroup, FormControlLabel, Checkbox, Switch } from '@mui/material';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ShadowBox from 'components/ShadowBox';


type PropTypes = {
  isActive: boolean
}
export default function ({ isActive }: PropTypes) {
  const dispatch = useDispatch();


  const params = useParams()
  const { campaignId } = params

  const [report, setReport] = useState(null)
  const [sendNotification, setSendNotification] = useState(false)

  const trackKeyRows: TrackResultType[] = report?.track_result || []
  const oldTrackKeyRows: TrackResultType[] = report?.old_track_result || []

  // UI variables
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  useEffect(() => {
    if (!campaignId) {
      return;
    }
    getTrackData()
  }, [isActive, campaignId])
  const getTrackData = () => {
    CallApi({
      method: 'get',
      url: `/api/tracking_keyword/get_keyword_tracking/${campaignId}`
    }).then((response) => {
      if (response) {
        setReport(response)
        setSendNotification(response?.send_email_notification)
      }
    })
    .catch((error) => {
      console.log('failed to load', error)
    });
  }

  const getRankDiff = (item: TrackResultType) => {
    const oldResult$ = oldTrackKeyRows.filter(r => r.keyword == item.keyword)[0]
    if (oldResult$) {
      const oldRank = typeof (oldResult$.currentRankGoogle) == 'number' ? oldResult$.currentRankGoogle : 0;
      const newRank = typeof (item.currentRankGoogle) == 'number' ? item.currentRankGoogle : 0;
      return oldRank - newRank
    } else {
      return 'None'
    }

  }


  const startTrackAction = () => {
    Axios.post('/api/tracking_keyword/start-tracking-keywords', {
      campaignId: campaignId,
    })
      .then(response => {
        if (response && response.status == 200) {
          toast.success('Done for trakcing setting.')
        }
      })
      .catch(() => {
        toast.error('failed to start tracking.')
      })

  }


  const getOldRank = (item: TrackResultType) => {
    const oldResult$ = oldTrackKeyRows.filter(r => r.keyword == item.keyword)[0]
    if (oldResult$) {
      return oldResult$.currentRankGoogle
    } else {
      return 'None'
    }
  }
  const csvData = (() => {
    const headers = ['Tracked Keyword', 'Old Rank', 'New Rank', 'Change', 'Monthly Volume(Low-High)', 'Current Ranking URL']
    let data = []
    data.push(headers)

    trackKeyRows.map(r => {
      data.push([
        r.keyword,
        getOldRank(r),
        r.currentRankGoogle,
        getRankDiff(r),
        r.broadMatchMonthlySearches_LowEstimate - r.broadMatchMonthlySearches_HighEstimate,
        r.currentRankingUrl,
      ]);
    })
    return data;
  })();


  const downloadAsPDF = () => {
    generateTableToPDF('#result-table-tracking', 'KeywordTracking');
  }

  const removeTrackKeyword = (keyword) => {

    dispatch(showLoadingIndicator())
    CallApi({
      method: 'post',
      url: '/api/tracking_keyword/remove-track-keyword',
      data: {
        campaignId: campaignId,
        track_keyword: keyword,
      }
    }).then((res) => {
      getTrackData()
    }).finally(() => {
      dispatch(hideLoadingIndicator())
    })
  }
  const handleClickDownloadMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseDownloadMenu = () => {
    setAnchorEl(null)
  }
  const updateEmailNotification = (notifiy: boolean) => {
    CallApi({
      method: 'post',
      url: '/api/tracking_keyword/email_notification',
      data: {
        campaignId: campaignId,
        send_email_notification: notifiy ? 1 : 0
      }
    }).then(() => {
      setSendNotification(notifiy)
    })
    .catch((error) => {
      console.log('failed to load', error)
    });
  }
  const openDownloadMenu = Boolean(anchorEl);
  return (
    <Container maxWidth="xl" sx={{ mb: 6 }}>
      <ShadowBox>
        <Grid container>
          <Grid item xs={12} md={8}>
            <Typography variant="h5" color='primary.dark'>Keep Track of Keyword Rank Changes</Typography>
          </Grid>
          <Grid item xs={12} md={4} textAlign='right' display='flex' alignItems='center' justifyContent={'flex-end'}>
            <FormGroup>
              <FormControlLabel
                control={<Switch onChange={(e) => updateEmailNotification(e.target.checked)}
                  checked={sendNotification} />}
                label="Email me updates" />
            </FormGroup>
            {trackKeyRows.length > 0 && <Box >

              <Button
                aria-controls={openDownloadMenu ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openDownloadMenu ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClickDownloadMenu}
                endIcon={<KeyboardArrowDownIcon />}
              >
                Download
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openDownloadMenu}
                onClose={handleCloseDownloadMenu}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={handleCloseDownloadMenu} sx={{
                  '& a': {
                    color: 'inherit'
                  }
                }}>
                  <CSVLink
                    data={csvData}
                    filename={`KeywordTracking_${new Date()}.csv`}
                  >Download CSV</CSVLink>
                </MenuItem>
                <MenuItem onClick={() => {
                  downloadAsPDF()
                }}>Download as PDF</MenuItem>
              </Menu>
            </Box>}

          </Grid>
        </Grid>
        <Box mt={2} mb={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Alert severity='info' icon={false}>
                <Typography variant='caption' fontWeight={'700'}>Old Rank Tracking Date: </Typography>
                <Typography fontWeight={'700'}>{report?.oldRankDate}</Typography>
              </Alert>
            </Grid>
            <Grid item xs={12} md={4}>
              <Alert severity='success' icon={false}>
                <Typography variant='caption' fontWeight={'700'}>New Rank Tracking Date:</Typography>
                <Typography fontWeight={'700'}> {report?.newRankDate}</Typography>
              </Alert>
            </Grid>
            <Grid item xs={12} md={4}>
              <Alert severity='info' icon={false}>
                <Typography variant='caption' fontWeight={'700'}>Next update of Rank Tracking Date:</Typography>
                <Typography fontWeight={'700'}>{report?.nextRankCheckDate}</Typography>
              </Alert>
            </Grid>
          </Grid>
        </Box>
        <Box overflow={'auto'}>
          <table className="table" id="result-table-tracking">
            <thead>
              <tr>
                <th>#</th>
                <th><Typography variant='caption' fontWeight={700}>Tracked Keyword</Typography></th>
                <th><Typography variant='caption' fontWeight={700}>Old Rank</Typography></th>
                <th><Typography variant='caption' fontWeight={700}>New Rank</Typography></th>
                <th><Typography variant='caption' fontWeight={700}>Change</Typography></th>
                <th>
                  <Box>
                    <Typography variant='caption' fontWeight={700}>Monthly Volume</Typography>
                  </Box>
                  <Box>
                    <Typography variant='caption' fontWeight={700}> Low - High</Typography>
                  </Box>

                </th>
                <th><Typography variant='caption' fontWeight={700}>Current Ranking URL</Typography></th>
              </tr>
            </thead>
            <tbody>
              {
                trackKeyRows.map((item, idx) => {
                  const rankDiff = getRankDiff(item)
                  let rankColor = 'primary.dark'
                  let rankTdClass = ''
                  if (rankDiff > 0) {
                    rankColor = '#12814C'
                    rankTdClass = 'success'
                  }
                  if (rankDiff < 0) {
                    rankColor = '#EB5757'
                    rankTdClass = 'danger'
                  }
                  return (
                    <tr key={idx}>
                      <td>
                        <FeatherIcon icon="x-circle" size={22}
                          className="icon-btn mr-2"
                          onClick={() => {
                            removeTrackKeyword(item.keyword)
                          }}
                        />
                      </td>
                      <td>{item.keyword}</td>
                      <td>
                        {getOldRank(item)}
                      </td>
                      <td>{item.currentRankGoogle}</td>
                      <td className={rankTdClass}>
                        <Typography color={rankColor} fontWeight='700'>
                          {rankDiff == 0 ? '-' : rankDiff}
                        </Typography>
                      </td>
                      <td>{item.broadMatchMonthlySearches_LowEstimate} - {item.broadMatchMonthlySearches_HighEstimate}</td>
                      <td><a href={item.currentRankingUrl} target='_blank'>{item.currentRankingUrl}</a></td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </Box>
        <Box my={2}>
          {!report?.nextRankCheckDate &&
            <Button
              variant='contained'
              onClick={startTrackAction}>Start Tracking Weekly</Button>
          }
        </Box>
      </ShadowBox>
    </Container>
  )
}