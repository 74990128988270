import { Box, Button, Checkbox, Container, FormControlLabel, FormGroup, Grid, Switch, TextField, Typography, useEventCallback } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from 'react'
import Autocomplete from '@mui/material/Autocomplete';
import CountrySelect from "components/CountrySelect";
import { GoogleLogin } from "components/GoogleLogin";
import { getCountryCode_ISO3, getSitesList, loadGAPIClient, searchAnalyticsForSearchConsole } from "utils/search-console-helper";
import moment from "moment";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { gapi, loadAuth2, loadClientAuth2 } from 'gapi-script';
import toast from "react-hot-toast";
import Axios from "axios";
import { useSelector } from 'react-redux'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import {
    showLoadingIndicator, hideLoadingIndicator
} from 'redux/reducers/appstate'
import PageDimension, { FilterDataType } from "./PageDimension";
import { CallApi, checkDomainMatch, getDomain } from "utils";
import ProgressBar, { ProgressObjType } from "components/ProgressBar/ProgressBar";
import { RootState } from "redux/store";
import { EventEmitter } from "utils/Event";
import ShadowBox from "components/ShadowBox";
import DomainSelector from "components/DomainSelector";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
type SearchFormType = {
    onGraphData: (data: any) => void,
    default_country: string
}
var g_data_date = []
var g_data_page_28days = [] // PAGE
var g_data_keyword_page_28days = [] // PAGE, QUERY
var g_data_6m = [] // PAGE
var g_data_keyword_6m = [] // KEYWORD DATE

let intervalTaskId = null;

export default function <SearchFormType>({ default_country, onGraphData, onTableData, onBenchmarkData, onChangeNLPEnabled,
}) {
    const formdata = useSelector((state: RootState) => state.formdata)
    const siteData = formdata.site

    const googleUser_ = useSelector((state: RootState) => state.user.googleUser)
    const [googleUser, setGoogleUser] = useState(googleUser_)
    const [country, setCountry] = useState(default_country || 'United States')
    const [domainOrUrl, setDomainOrUrl] = useState('')
    const [brandedTerms, setBrandedTerms] = useState('')

    // progress
    const [progressObj, setProgressObj] = useState<ProgressObjType>()
    const progressObjRef = useRef<number>(0)

    const isChartDataReady = useRef<boolean>(false)

    const isReadyPageData28D = useRef<boolean>(false)
    const isReadyKeywordPageData28D = useRef<boolean>(false)
    const isReadyDateData6M = useRef<boolean>(false)
    const isReadyDateQueryData6M = useRef<boolean>(false)

    const is28DayReady = useRef<boolean>(false)
    const is6MReady = useRef<boolean>(false)
    const isPaused = useRef<boolean>(false)
    // filtering
    const [pageFilterData, setPageFilterData] = useState<FilterDataType>(null)

    const [enabledCountry, setEnabledCountry] = useState(true)
    const [enabledBrand, setEnabledBrand] = useState(false)
    const [enabledNLP, setEnabledNLP] = useState(false)

    const [pageLimit, setPageLimit] = useState(null)
    const [searchParams] = useSearchParams();
    useEffect(() => {
        EventEmitter.subscribe('GOOGLE_USER_UPDATE', (gu) => {
            setGoogleUser(gu)
        })
    }, [])
    useEffect(() => {
        const pages = searchParams.get('pages')
        if (pages) {
            setPageLimit(Number(pages))
        }
    }, [searchParams])

    const getPageFilterList = () => {
        let filterList = []
        if (enabledCountry) {
            filterList.push({
                "dimension": "COUNTRY",
                "expression": getCountryCode_ISO3(country)
            })
        }
        if (enabledBrand) {
            brandedTerms.split(',').map((keyword) => {
                filterList.push({
                    "dimension": "QUERY",
                    "operator": "NOT_EQUALS",
                    "expression": keyword
                })
            })
        }

        if (pageFilterData) {
            filterList.push({
                "dimension": "PAGE",
                "operator": pageFilterData.optionValue,
                "expression": pageFilterData.pageUrl
            })
        }
        return filterList
    }
    const getConsoleDataByDate = (startRow = 0) => {
        if (isPaused.current) return
        const rowLimit = 25000
        // const endDate = "2022-07-27"
        // const startDate = "2022-07-27"
        const endDate = moment().format('YYYY-MM-DD')
        const startDate = moment().add(-32, 'months').format('YYYY-MM-DD')

        gapi.client.webmasters.searchanalytics.query({
            "siteUrl": domainOrUrl,
            "resource": {
                "startDate": startDate,
                "endDate": endDate,
                "dimensionFilterGroups": [
                    {
                        "filters": getPageFilterList()
                    }
                ],
                "dimensions": [
                    "DATE"
                ],
                "rowLimit": rowLimit,
                "startRow": startRow,
            }
        })
            .then(function (response) {
                if (response.status == 200) {
                    g_data_date = [...g_data_date, ...response.result?.rows]
                    if (response.result?.rows.length == rowLimit) {
                        getConsoleDataByDate(startRow + rowLimit + 1)
                    } else {
                        getPredictData()
                    }
                } else {
                    toast.error('Not found history')
                }
            },
                function (err) {
                    console.error("Execute error", err);
                    toast.error('Failed to get analystic data')
                });
    }
    const getDataPage28Days = (startRow = 0) => {
        if (isPaused.current) return

        const rowLimit = 25000
        // const endDate = "2022-07-27"
        // const startDate = "2022-07-27"
        const endDate = moment().format('YYYY-MM-DD')
        const startDate = moment().add(-29, 'days').format('YYYY-MM-DD')

        gapi.client.webmasters.searchanalytics.query({
            "siteUrl": domainOrUrl,
            "resource": {
                "startDate": startDate,
                "endDate": endDate,
                "dimensionFilterGroups": [
                    {
                        "filters": getPageFilterList()
                    }
                ],
                "dimensions": [
                    "PAGE"
                ],
                "rowLimit": rowLimit,
                "startRow": startRow,
            }
        })
            .then(function (response) {
                if (response.status == 200) {
                    g_data_page_28days = [...g_data_page_28days, ...response.result?.rows]
                    if (response.result?.rows.length == rowLimit) {
                        getDataPage28Days(startRow + rowLimit + 1)
                    } else {
                        increaseProgress(10)
                        isReadyPageData28D.current = (true)
                    }
                } else {
                    toast.error('Not found history')
                }
            },
                function (err) {
                    console.error("Execute error", err);
                    toast.error('Failed to get analystic data')
                });
    }
    const getDataKeywordPage28days = (startRow = 0) => {
        if (isPaused.current) return

        const rowLimit = 25000
        // const endDate = "2022-07-27"
        // const startDate = "2022-07-27"
        const endDate = moment().format('YYYY-MM-DD')
        const startDate = moment().add(-29, 'days').format('YYYY-MM-DD')

        gapi.client.webmasters.searchanalytics.query({
            "siteUrl": domainOrUrl,
            "resource": {
                "startDate": startDate,
                "endDate": endDate,
                "dimensionFilterGroups": [
                    {
                        "filters": getPageFilterList()
                    }
                ],
                "dimensions": [
                    "PAGE", "QUERY"
                ],
                "rowLimit": rowLimit,
                "startRow": startRow,
            }
        })
            .then(function (response) {
                if (response.status == 200) {
                    g_data_keyword_page_28days = [...g_data_keyword_page_28days, ...response.result?.rows]
                    if (response.result?.rows.length == rowLimit) {
                        getDataKeywordPage28days(startRow + rowLimit + 1)

                    } else {
                        increaseProgress(10)
                        isReadyKeywordPageData28D.current = (true)
                    }
                } else {
                    toast.error('Not found history')
                }
            },
                function (err) {
                    console.error("Execute error", err);
                    toast.error('Failed to get analystic data')
                });
    }

    const getInternalLinkTask = async (urls): Promise<any> => {
        const internal_link_task = await CallApi({
            url: 'api/content_audit/get_internal_link',
            data: {
                urls: urls
            },
            method: 'post'
        })
        const taskId = internal_link_task.taskId
        return new Promise((resolve, reject) => {
            let intervalTaskId = setInterval(() => {
                Axios.get(`/api/get-task-status/${taskId}`).then((response) => {

                    if (isPaused.current) {
                        clearInterval(intervalTaskId)
                    }
    
                    if (response && response.status === 200) {
                        console.info('=== response.data.status ===', JSON.stringify(response.data?.status, null, 4))
                        if (response.data?.status === "PROGRESS") {
                        } else if (response.data?.status === "SUCCESS") {
                        clearInterval(intervalTaskId)
                        resolve(response.data.result)
                        } else if (response.data?.status === "FAILURE") {
                            clearInterval(intervalTaskId)
                            reject([])
                        }
                    } else {
                        clearInterval(intervalTaskId)
                        reject([])
                    }
                });
              }, 5000);
        })
    }

    const getTableData = async (google_page_keyword_data) => {
        let urls = []
        let result = pageLimit ? google_page_keyword_data.slice(0, pageLimit) : google_page_keyword_data
        urls = result.map((item) => item.url)
        // get crawl time logic

        // let start = 0
        // const batch_size = 100
        // let end = batch_size
        // let batchResult = []

        // do {
        //     const promisesToCall = result.slice(start, end)

        //     const inspectPromises = promisesToCall.map((item, key) => {
        //         return new Promise((resolve, reject) => {
        //             gapi.client.searchconsole.urlInspection.index.inspect({
        //                 "siteUrl": domainOrUrl,
        //                 "inspectionUrl": item.url,
        //                 "languageCode": getCountryCode_ISO3(country)
        //             })
        //                 .then(function (response) {
        //                     if (response.status == 200) {
        //                         const lastCrawlTime = response.result?.inspectionResult?.indexStatusResult?.lastCrawlTime
        //                         resolve({
        //                             ...item,
        //                             lastCrawlTime
        //                         })
        //                     } else {
        //                         resolve({
        //                             ...item,
        //                             lastCrawlTime: null
        //                         })
        //                     }
        //                 },
        //                     function (err) {
        //                         console.error("Execute error", err);
        //                         resolve({
        //                             ...item,
        //                             lastCrawlTime: null
        //                         })
        //                     });
        //         })
        //     })
        //     const res = await Promise.all(inspectPromises)
        //     await sleep(1 * 1000);
        //     batchResult = [...batchResult, ...res]
        //     start = end
        //     end = start + batch_size
        // } while (start < result.length)

        // get internal links
        const internal_link_res = await getInternalLinkTask(urls)
        
        increaseProgress(10)

        result = result.map((item) => {
            const exist = internal_link_res.find(i => i.url == item.url)
            return {
                internal_links: exist ? exist.count : 0,
                ...item
            }
        })

        // get backlinks
        const backlink_res = await CallApi({
            url: 'api/content_audit/get_back_links',
            data: {
                urls: urls
            },
            method: 'post'
        })
        result = result.map((item) => {
            const exist = backlink_res.find(i => i.target == item.url)
            return {
                ...item,
                backlinks: exist ? exist.backlinks : 0,
            }
        })
        is28DayReady.current = (true)
        onTableData(result)
    }

    const getDataDate6Months = (startRow = 0) => {
        if (isPaused.current) return
        const rowLimit = 25000
        // const endDate = "2022-07-27"
        // const startDate = "2022-07-27"
        const endDate = moment().format('YYYY-MM-DD')
        const startDate = moment().add(-6, 'months').format('YYYY-MM-DD')

        gapi.client.webmasters.searchanalytics.query({
            "siteUrl": domainOrUrl,
            "resource": {
                "startDate": startDate,
                "endDate": endDate,
                "dimensionFilterGroups": [
                    {
                        "filters": getPageFilterList()
                    }
                ],
                "dimensions": [

                ],
                "rowLimit": rowLimit,
                "startRow": startRow,
            }
        })
            .then(function (response) {
                if (response.status == 200) {
                    g_data_6m = [...g_data_6m, ...response.result?.rows]
                    if (response.result?.rows.length == rowLimit) {
                        getDataDate6Months(startRow + rowLimit + 1)

                    } else {
                        increaseProgress(10)
                        isReadyDateData6M.current = (true)
                    }
                } else {
                    toast.error('Not found history')
                }
            },
                function (err) {
                    console.error("Execute error", err);
                    toast.error('Failed to get analystic data')
                });
    }
    const getDataKeywordDate6Months = (startRow = 0) => {
        if (isPaused.current) return
        const rowLimit = 25000
        // const endDate = "2022-07-27"
        // const startDate = "2022-07-27"
        const endDate = moment().format('YYYY-MM-DD')
        const startDate = moment().add(-6, 'months').format('YYYY-MM-DD')

        gapi.client.webmasters.searchanalytics.query({
            "siteUrl": domainOrUrl,
            "resource": {
                "startDate": startDate,
                "endDate": endDate,
                "dimensionFilterGroups": [
                    {
                        "filters": getPageFilterList()
                    }
                ],
                "dimensions": [
                    "QUERY"
                ],
                "rowLimit": rowLimit,
                "startRow": startRow,
            }
        })
            .then(function (response) {
                if (response.status == 200) {
                    g_data_keyword_6m = [...g_data_keyword_6m, ...response.result?.rows]
                    if (response.result?.rows.length == rowLimit) {
                        getDataKeywordDate6Months(startRow + rowLimit + 1)

                    } else {
                        increaseProgress(10)
                        isReadyDateQueryData6M.current = (true)
                    }
                } else {
                    toast.error('Not found history')
                }
            },
                function (err) {
                    console.error("Execute error", err);
                    toast.error('Failed to get analystic data')
                });
    }
    const mixGoogleDataOf6Months = () => {
        setTimeout(() => {
            if (isReadyDateQueryData6M.current && isReadyDateData6M.current) {
                const averageData = {
                    clicks: 0.75 * g_data_6m[0].clicks / 6,
                    impressions: 0.75 * g_data_6m[0].impressions / 6,
                    keyword_count: 0.75 * g_data_keyword_6m.length / 6,
                }

                is6MReady.current = (true)
                onBenchmarkData(averageData)
            } else {
                mixGoogleDataOf6Months()
            }
        }, 1000);
    }
    const mixGoogleDataOf28days = () => {

        setTimeout(() => {
            if (isReadyKeywordPageData28D.current && isReadyPageData28D.current) {
                const keyword_data = getKeywordsCounts(g_data_keyword_page_28days)

                let result = g_data_page_28days.map((item) => {
                    const { keys, ...rest } = item
                    const url_key_count = keyword_data.find(d => d.url == keys[0])
                    if (url_key_count) {
                        return {
                            ...rest,
                            ...url_key_count
                        }
                    } else {
                        return {
                            ...rest,
                            url: keys[0],
                            keyword_count: 0,
                            keywords: []
                        }
                    }
                })
                getTableData(result)
            } else {
                mixGoogleDataOf28days()
            }
        }, 1000);

    }
    const getKeywordsCounts = (data) => {
        let resultObj = {}
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            const url = element.keys[0]
            const keyword = element.keys[1]
            if (resultObj[url]) { // url exist
                resultObj[url].keyword_count = resultObj[url].keyword_count + 1
                resultObj[url].keywords = [...resultObj[url].keywords, keyword]
            } else {
                resultObj[url] = {
                    keyword_count: 1,
                    keywords: [keyword]
                }
            }
        }
        let result = []
        Object.keys(resultObj).map(key => {
            const item = {
                url: key,
                ...resultObj[key],
            }
            result.push(item)
        })
        return result
    }

    const getPredictData = () => {
        Axios.post('api/content_audit/get_predict_for_analytics', { data: g_data_date }).then((res) => {
            if (res.status == 200) {
                const data = res.data
                onGraphData(data)
            }
        }).catch((err) => {
            console.info('=== error predict ===', JSON.stringify(err, null, 4))
            toast.error('Error predict!')
        }).finally(() => {
            increaseProgress(10)
            isChartDataReady.current = true
        })
    }
    useEffect(() => {
        if (isChartDataReady.current && is28DayReady.current && is6MReady.current) {
            toast.success('Success!')
            handlePauseTask()
        }
    }, [isChartDataReady.current, is28DayReady.current, is6MReady.current])

    const onStart = () => {
        isPaused.current = false
        g_data_date = []
        g_data_page_28days = [] // PAGE
        g_data_keyword_page_28days = [] // PAGE, QUERY
        g_data_6m = [] // PAGE
        g_data_keyword_6m = [] // PAGE

        isReadyPageData28D.current = (false)
        isReadyKeywordPageData28D.current = (false)
        isReadyDateData6M.current = (false)
        isReadyDateQueryData6M.current = (false)
        is28DayReady.current = (false)
        is6MReady.current = (false)

        progressObjRef.current = 0
        increaseProgressRegularly()

        getConsoleDataByDate()
        getDataPage28Days()
        getDataKeywordPage28days()
        getDataDate6Months()
        getDataKeywordDate6Months()
        mixGoogleDataOf6Months()
        mixGoogleDataOf28days()
    }
    const increaseProgressRegularly = () => {
        setTimeout(() => {
            increaseProgress(0.1)
            if (!isPaused.current) {
                increaseProgressRegularly()
            }
        }, 3000);
    }
    const increaseProgress = (n) => {
        progressObjRef.current = Math.min(100, progressObjRef.current + n)
        setProgressObj({
            msg: 'Processing...',
            value: progressObjRef.current
        })
    }
    const canStart = googleUser && domainOrUrl

    const onApplyPageFilter = (data: FilterDataType) => {
        setPageFilterData(data)
    }
    const handlePauseTask = () => {
        isPaused.current = true
        setProgressObj(undefined);
        if (intervalTaskId) {
            clearInterval(intervalTaskId);
            intervalTaskId = null;
        }
        progressObjRef.current = 0;
    }
    return <Box>
        <Container maxWidth="xl">
            <ShadowBox>
                <Typography variant="h5" color='primary.dark'>
                    Generate Traffic Predictions
                </Typography>

                <Typography>
                    Generate Traffic Predictions is a tool that assists in obtaining estimates for clicks and impressions on your website. It is user-friendly and requires minimal setup. To use it, you need to connect your account to Google Search Console, which provides access to your domain data. After the connection is established, you can effortlessly filter out branded traffic and receive estimates solely for general keywords and performance. Furthermore, you can filter and receive estimates for your preferred location.
                </Typography>

                <Box sx={{
                    display: 'flex',
                    background: '#6ae38d33',
                    borderRadius: '4px',
                    padding: 1,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    my: 3
                }}>
                    <GoogleLogin />
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <DomainSelector
                            value={domainOrUrl}
                            onChangeDomain={(new_domain) => setDomainOrUrl(new_domain)}
                            base_domain={siteData?.base_url}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CountrySelect isMulti={false}
                            value={country}
                            placeholder="Country"
                            onChange={(value) => setCountry(value)} />
                        <FormGroup>
                            <FormControlLabel control={<Switch checked={enabledCountry}
                                onChange={(e) => {
                                    setEnabledCountry(e.target.checked)
                                }}
                            />}
                                label={enabledCountry ? "Disable" : "Enable"} />
                        </FormGroup>

                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField id='term' label='Excluded Brand Terms' variant="outlined" size='small'

                            fullWidth
                            onChange={(e) => setBrandedTerms(e.target.value)}
                            value={brandedTerms} />
                        <FormGroup>
                            <FormControlLabel control={<Switch checked={enabledBrand} onChange={(e) => setEnabledBrand(e.target.checked)} />}
                                label={enabledBrand ? "Disable" : "Enable"} />
                            <FormControlLabel control={<Switch checked={enabledNLP} onChange={(e) => {
                                onChangeNLPEnabled(e.target.checked)
                                setEnabledNLP(e.target.checked)
                            }} />}
                                label={"NLP analysis"} />
                        </FormGroup>
                    </Grid>
                </Grid>
                <hr />
                {siteData?.level != 'domain' && <Box>
                    <PageDimension onApplyPageFilter={(data: FilterDataType) => onApplyPageFilter(data)} />
                </Box>}
                <Grid container my={4}>
                    <Grid item xs={12} md={2}>
                        <Button variant="contained" disabled={!canStart} onClick={onStart}
                            color='secondary'
                            sx={{
                                borderRadius: 10,
                            }}
                        >
                            Start
                            <ArrowForwardIcon />
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <ProgressBar
                            progressObj={!isPaused.current && progressObj}
                            onPause={handlePauseTask}
                        />
                    </Grid>
                </Grid>
            </ShadowBox>
        </Container >
    </Box >
}