import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import {
  Modal,
  Form,
  Button,
} from 'react-bootstrap';


import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import KeywordsResearch from "views/Pages/Campaign/tabs/KeywordsResearchTab"
import GapAnalysis from "views/Pages/Campaign/tabs/GapAnalysis"
import SupportingKeywordResearch from 'views/Pages/Campaign/tabs/SupportingKeywordResearchTab';
import KeywordTracking from 'views/Pages/Campaign/tabs/KeywordTrackingTab';
import SILO from 'views/Pages/Campaign/tabs/SILOTab';
import BackLinksTab from 'views/Pages/Campaign/tabs/BackLinksTab';
import TrackPredictionTab from 'views/Pages/Campaign/tabs/ContentAuditTab';
import { loadSiteThunk } from 'redux/reducers/formdata';
import TopLevelPage from 'views/Pages/Campaign/tabs/TopLevelPage';
import { ADMIN_EMAILS } from 'Models/Constants';
import { addCampaignEnquiryHistory } from 'redux/reducers/appdata';
import BreadCrumbs from 'components/BreadCrumbs';
import { CallApi } from 'utils';
import { Container } from '@mui/system';
import { PackageType } from 'Models/Package';
import {RootState} from "../../../redux/store";

type HideBoxType = {
  hide: boolean,
  children: React.ReactNode
}
const HideBox = (props: HideBoxType) => {
  const { hide, children } = props;
  return (<Box sx={{ display: hide ? 'none' : 'block' }}>
    {children}
  </Box>)
}
const TAB_LIST = [
  {
    label: 'Keyword Insight',
    value: 'get_data_for_keyword',
    component: KeywordsResearch
  },
  {
    label: 'Keyword Wizard',
    value: 'top_level_page',
    component: TopLevelPage,
  },
  {
    label: 'Supporting Keyword Research',
    value: 'supporting_keyword_research',
    component: SupportingKeywordResearch,
  },
  {
    label: 'Keyword Tracking',
    value: 'keyword_tracking',
    component: KeywordTracking,
  },
  {
    label: 'SILO',
    value: 'silo',
    component: SILO,
  },
  {
    label: 'Monitor Backlinks',
    value: 'backlinks_monitoring',
    component: BackLinksTab,
  },
  {
    label: 'Content Audit',
    value: 'traffic_prediction',
    component: TrackPredictionTab,
  },
  {
    label: 'Gap Analysis',
    value: 'gap_analysis',
    component: GapAnalysis,
  },
]
const marjia_emails = [
  'marija@hvseo.co',
  'maria@pageoptimizer.pro',
  'zhaoweiqi0408@outlook.com',
  'asadjoiya@gmail.com',
  'asadsmalik91@gmail.com',
]
export default function (props) {

  const dispatch = useDispatch();
  const { siteId, campaignId } = useParams();
  const navigate = useNavigate();
  const userState = useSelector((state: RootState) => state.user)
  const userPackage: PackageType = userState.user?.package
  const userPackageTabs = userPackage?.tabs
  const siteData = useSelector((state: RootState) => state.formdata.site)

  const [campaignData, setCampaignData] = useState(null)
  const [tabListByRoles, setTabListByRoles] = useState([])
  const [currentTab, setCurrentTab] = useState<string>(TAB_LIST[0].value);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(loadSiteThunk(siteId))
  }, [siteId])
  useEffect(() => {
    if (campaignId) {

      CallApi({
        url: 'api/keyword_research/get_basic/' + campaignId,
        method: 'get',
      }).then((res) => {
        setCampaignData(res.report)
      })
      .catch((error) => {
        console.log('failed to load', error)
      })
      
    }
  }, [campaignId])
  useEffect(() => {
    const tab = searchParams.get('tab')
    if (tab) {
      setCurrentTab(tab)
    }
  }, [searchParams])
  useEffect(() => {
    const tabObj = TAB_LIST.filter(t => t.value == currentTab)[0]
    dispatch(addCampaignEnquiryHistory({
      campaign_id: campaignId,
      tab_label: tabObj.label,
      tab_value: tabObj.value,
    }))

  }, [currentTab])

  useEffect(() => {
    if (userState?.user) {
      const userEmail = userState?.user.email
      if (ADMIN_EMAILS.includes(userEmail)) {
        if (marjia_emails.includes(userEmail)) {
          setTabListByRoles(TAB_LIST)
        } else {
          const testTabs = ['backlinks_monitoring', 'traffic_prediction', 'openai']
          setTabListByRoles(
            TAB_LIST.filter(t => !testTabs.includes(t.value))
          )
        }
      } else {
        const normalUserTabValues = ['get_data_for_keyword', 'supporting_keyword_research']
        setTabListByRoles(
          TAB_LIST.filter(t => normalUserTabValues.includes(t.value) && userPackageTabs.includes(t.value))
        )
      }
    }
  }, [userState])


  const handleChange = (event, newValue) => {
    navigate("?tab=" + newValue)
  };
  const renderTabHeader = () => {
    return <Tabs value={currentTab} onChange={handleChange} scrollButtons="auto" variant="scrollable">
      {
        tabListByRoles.map((t, key) => {
          return <Tab label={t.label} {...a11yProps(t.value)} value={t.value} />
        })
      }
    </Tabs>
  }
  const renderTabContents = () => {
    return tabListByRoles.map((t, key) => {
      return <HideBox hide={currentTab != t.value}> <t.component isActive={currentTab == t.value} /></HideBox>
    })
  }
  const getTabLabel = () => {
    const tab = TAB_LIST.find(t => t.value == currentTab)
    return tab.label
  }
  return (
    <Box>
      <Container maxWidth="xl">
        <BreadCrumbs
          breads={[{
            label: siteData?.name,
            url: `/site/${siteData?.id}`
          },
          {
            label: getTabLabel(),
          }
          ]}
        />
        <Typography variant='h4' sx={{
          color: 'primary.dark',
        }}
          my={1}
        >
          {campaignData?.campaign_name}
        </Typography>

        <Box sx={{
          borderBottom: 1, borderColor: 'divider',
          backgroundColor: 'white',
          borderRadius: '8px 8px 0 0',
          mt: 2,
          px: 1,
          pt: 1
        }}>
          {renderTabHeader()}
        </Box>
      </Container>
      <Box>
        {renderTabContents()}
      </Box>
    </Box>
  )
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    sx: {
      fontSize: '12px'
    }
  };
}