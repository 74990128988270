import { useMemo, useState } from "react";
import axios from "axios";
import { Container, Box } from "@mui/material";
import ShadowBox from "components/ShadowBox";
import Scenarios from "./GapAnalysisComponents/Scenarios";
import MainInputs from "./GapAnalysisComponents/MainInputs";
import ResultTable from "./GapAnalysisComponents/ResultTable";
import FullScreenLoading from "../../../../views/FullScreenLoading";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { toast } from "react-hot-toast";
import TableTabs from "./GapAnalysisComponents/TableTabs";
import { orderBy } from "lodash";

const KEYWORD_TABS = ["shared", "missing", "untapped", "all"];

const pollResults = (taskId, setLoading, setResults) => {
  const intervalId = setInterval(async () => {
    try {
      const resp = await axios.get(`/api/get-task-status/${taskId}`);
      if (resp.data?.status === "FAILURE") {
        toast.error("Internal Server Error");
        throw new Error("Task failed");
      }
      if (resp.data?.status === "SUCCESS") {
        const results = resp.data.result;
        for (const kw_tab of KEYWORD_TABS) {
          results[kw_tab] = orderBy(
            results[kw_tab],
            ["monthly_searches"],
            ["desc"]
          );
        }
        setResults(results);
        throw new Error("Task ended");
      }
    } catch (error) {
      if (intervalId) clearInterval(intervalId);
      setLoading(false);
    }
  }, 3000);
};

const loadAnalytics = async (setLoading, setResults, params = {}) => {
  try {
    setLoading(true);
    const resp = await axios.post("/api/keyword-gap-analysis", params);
    const { task_id: taskId } = resp.data;

    pollResults(taskId, setLoading, setResults);
  } catch (e) {
    toast.error(e.message || "Internal Server Error");
    setLoading(false);
  }
};

function GapAnalysis() {
  const [curScenario, setCurScenario] = useState(1);
  const [tableData, setTableData] = useState(
    KEYWORD_TABS.reduce((obj, key) => ({ ...obj, [key]: [] }), {})
  );
  const [loading, setLoading] = useState(false);
  const [competitors, setCompetitors] = useState([]);
  const [tab, setTab] = useState(0);
  const siteData = useSelector((state: RootState) => state.formdata.site);
  const tabStats = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(tableData).map(([key, list]) => [
          key,
          list?.["length"] || 0,
        ])
      ),
    [tableData]
  );

  const onSubmit = (data) => {
    setCompetitors(
      [siteData.base_url].concat(data.competitors_sites.map((row) => row.url))
    );
    const apiPayload = { ...data, location_code: String(data.location_code) };
    if (!apiPayload.target_site) apiPayload.target_site = siteData.base_url;
    loadAnalytics(setLoading, setTableData, apiPayload);
  };

  return (
    <Box>
      {loading ? <FullScreenLoading /> : null}
      <Container maxWidth="xl">
        <ShadowBox>
          <Scenarios
            selectedBox={curScenario}
            setSelectedBox={setCurScenario}
          />
          <MainInputs curScenario={curScenario} onSubmit={onSubmit} />
          <TableTabs tab={tab} setTab={setTab} tabStats={tabStats} />
          <ResultTable
            tab={tab}
            competitors={competitors}
            rows={tableData[KEYWORD_TABS[tab]] || []}
          />
        </ShadowBox>
      </Container>
    </Box>
  );
}

export default GapAnalysis;
