import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  Modal,
  Form,
  InputGroup,
} from 'react-bootstrap';
import axios from 'axios';
import {
  showLoadingIndicator, hideLoadingIndicator
} from 'redux/reducers/appstate'

import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from 'react-hot-toast';
import validator from 'validator';
import { SiteModel } from 'Models/SiteModel';
import { checkDomainMatch } from 'utils';
import { addCampaignHistory, CAMPAIGN_HISTORY_ITEM } from 'redux/reducers/appdata';
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import BreadCrumbs from 'components/BreadCrumbs';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { KeywordResearchModel } from 'Models/KeywordResearchModel';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import moment from 'moment'
import { RootState } from 'redux/store';
import { BOX_SHADOW } from 'theme/colors';

const OPTIONS = {
  OPTION1: 'OPTION1',
  OPTION2: 'OPTION2',
}


export default function () {
  const { siteId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // used data
  const [reports, setReports] = useState([])
  const [site, setSite] = useState<SiteModel>(null)
  const campaignEnquiryHistory = useSelector((state: RootState) => state.appdata.campaignEnquiryHistory) || []

  // form data
  const [campaignName, setCampaignName] = useState('');
  const [targetUrl, setTargeturl] = useState('');
  const [notBuiltPage, setNotBuiltPage] = useState(false)

  // ui 
  const [anchorElMenu, setAnchorElMenu] = useState<null | HTMLElement>(null);
  const [showCreateCampaignModal, setShowCreatCampaignModal] = useState(false);

  // delete 
  const [showDelDlg, setShowDelDlg] = useState(false)
  const [reportIdToDelete, setReportIdToDelete] = useState()
  useEffect(() => {
    dispatch(showLoadingIndicator())
    axios.get('/api/sites/get/' + siteId)
      .then(response => {
        if (response && response.status === 200) {
          setSite(response.data);
        }
      }).finally(() => {
        dispatch(hideLoadingIndicator())
      })
    loadCampaigns()
  }, [])
  useEffect(() => {
    if (site && reports.length > 0 && site.level == 'domain') {
      navigate(`/site/${siteId}/campaign/${reports[0].id}`)
    }
  }, [site, reports])

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElMenu(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorElMenu(null);
    setReportIdToDelete(undefined)
  };
  const loadCampaigns = () => {

    axios.post('/api/sites/get-campaigns', {
      siteId: siteId,
    })
      .then(response => {
        if (response && response.status === 200) {
          setReports(response.data.reports);
        }
      }).finally(() => {
        dispatch(hideLoadingIndicator())
      })
  }

  const viewReport = (report) => {
    const campaignVisitHistory: CAMPAIGN_HISTORY_ITEM = {
      project_id: Number(siteId),
      campaign_id: report.id,
      campaign_name: report.campaign_name
    }
    dispatch(addCampaignHistory(campaignVisitHistory))
    navigate(`/site/${siteId}/campaign/${report.id}`)

  }

  const removeCampaign = () => {
    axios.post('/api/sites/remove-campaign', {
      campaignId: reportIdToDelete,
    })
      .then(response => {
        if (response && response.status === 200) {
          loadCampaigns()
        }
      }).finally(() => {
        setAnchorElMenu(null);
        setShowDelDlg(false)
      })
  }

  const onCreateCampaign = () => {
    const data = {
      campaign_name: campaignName,
      target_url: targetUrl,
      is_built_page: !notBuiltPage,
      site_id: siteId
    }
    if (!checkDomainMatch(targetUrl, site.base_url)) {
      toast.error('Invalid target url')
      return
    }
    setShowCreatCampaignModal(false)

    axios.post('/api/campaign/create', data).then(res => {
      navigate(`/site/${siteId}/campaign/${res.data.report.id}`)
    })
  }
  const createCampaignModal = () => {
    return (
      <Modal
        show={showCreateCampaignModal}
        onHide={() => setShowCreatCampaignModal(false)}
        backdrop="static"
        keyboard={false}
        className="options-modal"
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>
            {campaignName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Campaign Name</Form.Label>

            <Form.Control type="text" placeholder='Please input the name' value={campaignName} onChange={(e) => setCampaignName(e.target.value)}></Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="basic-url">Target url</Form.Label>
            <InputGroup >
              <Form.Control type="text" aria-describedby="basic-addon3" id="basic-url"
                value={targetUrl} onChange={(e) => setTargeturl(e.target.value)}></Form.Control>
            </InputGroup>
          </Form.Group>
          <Form.Group controlId="formBasicCheckbox">
            <Form.Check type="checkbox" label='I haven’t built this page yet' checked={notBuiltPage} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNotBuiltPage(e.target.checked)} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" className="main-button" onClick={onCreateCampaign}>Let's Go</Button>
          <Button onClick={() => {
            setShowCreatCampaignModal(false)
          }}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    )
  }
  const renderEnquiryHistory = (campaign) => {
    const hist = campaignEnquiryHistory.filter(ch => ch.campaign_id == campaign.id).slice(0, 3)
    return <Box pb={3}>
      {
        hist.map((h, key) => {
          const isOdd = key % 2 == 0
          return <Box sx={{
            display: 'flex',
            alignItems: 'center',
            backgroundColor: isOdd ? '#5148fb0d' : 'white',
            mb: 1,
            px: 2
          }} key={key}>
            <Typography sx={{
              flexGrow: 1,
            }}>
              {h.tab_label}
            </Typography>
            <Box sx={{
              backgroundColor: 'primary.main',
              width: 32,
              height: 32,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer'
            }}
              onClick={() => {
                navigate(`/site/${siteId}/campaign/${h.campaign_id}?tab=${h.tab_value}`)
              }}
            >
              <ArrowForwardIcon sx={{ color: 'white' }} />
            </Box>
          </Box>
        })
      }

    </Box>
  }
  return (
    <Container maxWidth="xl">
      <BreadCrumbs
        breads={[]}
      />
      <Box sx={{
        display: {
          xs: 'block',
          md: 'flex'
        }
      }} mt={2}>
        {site && (
          <Typography variant={'h4'} color='primary.dark' mr={4}>
            {site.name}
          </Typography>
        )}
        <Box textAlign={'right'}>
          <Button onClick={() => setShowCreatCampaignModal(true)} variant="contained"
            color="secondary"
            sx={{ color: 'white' }}
          >Create Campaign</Button>
        </Box>
      </Box>

      <Grid container mt={0} spacing={4}>
        {reports.map((report, index) => {
          return (
            <Grid item xs={12} md={3} key={index}>
              <Box sx={{
                borderRadius: '8px',
                backgroundColor: 'white',
                boxShadow: BOX_SHADOW
              }}>
                <Box sx={{
                  display: 'flex', p: 2,
                  pr: 0,
                  borderBottomColor: '#5148fb33', borderBottomColorOpacity: 0.2, borderBottomStyle: 'solid', borderWidth: 1
                }}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Typography variant='h6' sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => viewReport(report)}
                    >{report.campaign_name}</Typography>
                    <Typography variant='caption' sx={{ color: 'gray' }}>ID: {report.id}  Created: {moment(report.created_at).format('ll')}</Typography>
                  </Box>
                  <Box>
                    <IconButton aria-label="delete" onClick={(e) => {
                      handleOpenMenu(e)
                      setReportIdToDelete(report.id)
                    }}>
                      <MoreVertIcon color='primary' />
                    </IconButton>
                  </Box>
                </Box>
                <Box >
                  <Box sx={{ px: 2, mt: 2 }}>
                    <Typography variant='caption' sx={{ color: 'gray', }}
                    >RECENT ENQUIRIES</Typography>
                  </Box>
                  {renderEnquiryHistory(report)}
                </Box>
              </Box>
            </Grid>
          )
        })}
      </Grid>

      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchorElMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElMenu)}
        onClose={handleCloseMenu}
      >
        <MenuItem onClick={() => setShowDelDlg(true)}>
          <Typography textAlign="center">Delete</Typography>
        </MenuItem>
      </Menu>

      <Dialog open={showDelDlg} onClose={() => setShowDelDlg(false)}>
        <DialogTitle>
          Do you want to delete report?
        </DialogTitle>
        <DialogContent>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDelDlg(false)} >Cancel</Button>
          <Button onClick={() => removeCampaign()} variant='contained'>Confirm</Button>
        </DialogActions>
      </Dialog>
      {createCampaignModal()}
    </Container>
  )
}